import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';
import { EmptyList } from '../../components/EmptyList';
import { TableSkeleton } from '../../components/TableSkeleton';
import { DeleteDialog } from '../../components/DeleteDialog';
import { Layout } from '../../components/Layout';
import { SearchField } from '../../components/SearchField';
import { ErrorMsg } from '../../components/ErrorMsg';
import { ImageField } from '../../components/ImageField';

import { useClimate, useProduct } from '../../contexts';
import {
  handleErrorMessage,
  initField,
  SUPORTED_CSV_FIELD_TYPES,
} from '../../utils';
import {
  Climate,
  SelectOption,
  FileFieldType,
  UploadClimateProductHistoryPayload,
} from '../../types';

export const ClimateList = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    climateApi,
    climates,
    setClimates,
    climateLoading,
    setClimateError,
    uploadClimateProductHistory,
    uploadClimateError,
  } = useClimate();
  const { productsOptions, productLoading, getProductsOptions } = useProduct();

  const [page] = useState<number>(1); // eslint-disable-line
  const [pageCount, setPageCount] = useState<number>(0);
  const [_, setPageSize] = useState<number>(10); // eslint-disable-line

  const climatesCount = climates?.length ?? -1;

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const [climateDataExcelOpen, setClimateDataExcelOpen] =
    useState<boolean>(false);
  const [productId, setProductId] = useState<SelectOption>(null); // eslint-disable-next-line
  const [linkClimateId, setLinkClimateId] = useState<string>();

  const [HistoricoProduto, setHistoricoProduto] = useState(
    initField<FileFieldType>({
      name: 'HistoricoProduto',
      value: null,
      label: 'HistoricoProduto',
    })
  );

  const getClimates = async () => {
    if (loading === false) {
      try {
        setLoading(true);
        const data = await climateApi.getPagedClimates(page);
        setPageSize(data.items.length);
        setPageCount(
          data.totalItems < data.items.length
            ? 1
            : Math.ceil(data.totalItems / data.items.length)
        );
        setClimates(data.items);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePageChange = async (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setLoading(true);
      const data = await climateApi.getPagedClimates(page);
      setClimates(data.items);
    } catch (e: unknown) {
      console.error(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClimates();
    getProductsOptions();
  }, []);

  const handleDelete = async () => {
    await climateApi.deleteClimate(deleteId as string);
    setClimates(
      (climates ?? []).filter(
        (climate: Climate) => climate.dadoClimaticoId !== deleteId
      )
    );
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  return (
    <Layout>
      <Typography variant="h4" component="h2" color="textSecondary">
        Cadastro de Condições Atuais de Umidade do Solo
      </Typography>
      <Box
        sx={{
          marginTop: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
        }}
      >
        <Button
          variant="contained"
          color="primary"
          component={Link}
          href="/climates/create/"
        >
          <Typography
            variant="button"
            color="white"
            style={{
              display: 'flex',
            }}
          >
            <AddIcon />
            Cadastrar Condição Atual de Umidade do Solo
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setClimateDataExcelOpen(true);
          }}
        >
          <Typography
            variant="button"
            color="white"
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            <CloudUploadIcon />
            Atualizar Condição Atual de Umidade do Solo de Produto
          </Typography>
        </Button>
      </Box>

      <div style={{ marginTop: theme.spacing(2) }}>
        {climatesCount < 0 && (
          <TableSkeleton
            columns={['Nome', 'Email', 'Telefone', 'UF', 'CNPJ', 'Ações']}
          />
        )}
        {climatesCount === 0 && (
          <EmptyList
            to="/climate/create/"
            label="Cadastrar Condição Atual de Umidade do Solo"
          />
        )}
        {climatesCount > 0 && (
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(2),
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableHeadCell component="th" scope="row">
                      Produto
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Estado
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Cidade
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Data
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Valor
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Ações
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {climates?.map((row) => (
                    <TableRow key={row.dadoClimaticoId}>
                      <TableCell component="th" scope="row">
                        {`${row.cidadeNome} - ${row.estadoSigla}`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.estadoNome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cidadeNome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.data}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.valor}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            gap: theme.spacing(1),
                            justifyContent: 'center',
                          }}
                        >
                          <IconButton
                            sx={{
                              background: green[100],
                              color: theme.palette.primary.main,
                            }}
                            aria-label="Editar"
                            LinkComponent={Link}
                            href={`/climates/edit/${row.dadoClimaticoId}/`}
                          >
                            <EditIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                          <IconButton
                            sx={{ background: red[100] }}
                            color="secondary"
                            aria-label="Excluir"
                            onClick={() => {
                              setDeleteOpen(true);
                              setDeleteId(row.dadoClimaticoId);
                              setDeleteName(row.dadoClimaticoId);
                            }}
                          >
                            <CloseIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Pagination
                count={pageCount}
                disabled={loading}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          </Box>
        )}
      </div>
      <DeleteDialog
        open={deleteOpen}
        name={deleteName}
        setOpen={setDeleteOpen}
        handleDelete={handleDelete}
      />
      <Dialog
        open={climateDataExcelOpen}
        onClose={(event, reason) => {
          if (reason && reason == 'backdropClick' && climateLoading) return;
          setClimateDataExcelOpen(false);
        }}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <Box marginTop={2}>
          <ErrorMsg error={uploadClimateError} />
        </Box>
        <DialogTitle id="delete-dialog-title">
          Atualizar Dados Climáticos de Produto
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              display: 'flex',
              marginBottom: theme.spacing(2),
            }}
          >
            Qual produto deseja atualizar?
          </Typography>
          <SearchField
            extraAttrs={{ value: productId as SelectOption }}
            options={productsOptions}
            label="Selecione o Produto"
            loading={productLoading}
            handleChange={(value: SelectOption) => {
              setProductId(value);
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <ImageField
              setField={(field: FileFieldType) => {
                setHistoricoProduto(field);
                setClimateError(undefined);
              }}
              field={HistoricoProduto}
              allowedFileTypes={SUPORTED_CSV_FIELD_TYPES}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={climateLoading}
            color="inherit"
            onClick={() => {
              setClimateDataExcelOpen(false);
              setClimateError(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            loading={climateLoading}
            onClick={async () => {
              const payload = {
                HistoricoProduto: HistoricoProduto.value,
                ProdutoId: productId?.value ?? '',
              } as UploadClimateProductHistoryPayload;
              const data = await uploadClimateProductHistory(payload);
              if (typeof data === 'object') {
                await getClimates();
                setProductId(null);
                setClimateDataExcelOpen(false);
              }
            }}
            color="primary"
            autoFocus
            variant="outlined"
            disabled={productId ? false : true}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
