import React, { createContext, useState, useContext } from 'react';
import { green } from '@mui/material/colors';

import ProductHistoryService, {
  ProductHistoryApi,
} from '../services/ProductHistoryService';

import {
  ProductHistory,
  HistoryChartDataState,
  UploadProductHistoryPayload,
} from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './AuthProvider';

export type ProductHistoryContextType = {
  productHistoryApi: ProductHistoryApi;
  productsHistories: Array<ProductHistory> | undefined;
  setProductsHistories: React.Dispatch<
    React.SetStateAction<Array<ProductHistory> | undefined>
  >;

  productHistory: ProductHistory | undefined;
  setProductHistory: React.Dispatch<
    React.SetStateAction<ProductHistory | undefined>
  >;

  productHistoryError: string | undefined;
  setProductHistoryError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;

  productHistoryLoading: boolean;
  setProductHistoryLoading: React.Dispatch<React.SetStateAction<boolean>>;

  getProductsHistories(): Promise<void>;
  getProductHistoryById(productHistoryId: string): Promise<void>;
  getProductHistoryByProductId(productId: string): Promise<void>;
  getSubProductHistoriesBySubProductId(
    subProductId: string
  ): Promise<HistoryChartDataState | null>;

  uploadProductHistory(
    payload: UploadProductHistoryPayload
  ): Promise<null | string>;

  uploadProductHistoryError: string | undefined;
  setUploadProductHistoryError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;

  productHistoryByProduct: ProductHistory[] | undefined;
  setProductHistoryByProduct: React.Dispatch<
    React.SetStateAction<ProductHistory[] | undefined>
  >;

  subProductHistory: HistoryChartDataState | undefined;
  setSubProductHistory: React.Dispatch<
    React.SetStateAction<HistoryChartDataState | undefined>
  >;
};

export const ProductHistoryContext = createContext<ProductHistoryContextType>(
  {} as unknown as ProductHistoryContextType
);

export const ProductHistoryProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [productsHistories, setProductsHistories] =
    useState<Array<ProductHistory>>();
  const [productHistory, setProductHistory] = useState<ProductHistory>();
  const [productHistoryByProduct, setProductHistoryByProduct] =
    useState<ProductHistory[]>();
  const [productHistoryLoading, setProductHistoryLoading] =
    useState<boolean>(false);
  const [productHistoryError, setProductHistoryError] = useState<string>();
  const [uploadProductHistoryError, setUploadProductHistoryError] =
    useState<string>();

  const [subProductHistory, setSubProductHistory] =
    useState<HistoryChartDataState>();

  const { token } = useAuth();

  const productHistoryApi = new ProductHistoryService({
    basePath: '/v1/historicoproduto',
    contentType: 'multipart/form-data',
    token,
  });

  const getProductsHistories = async () => {
    if (
      (productsHistories?.length ?? -1) < 0 &&
      productHistoryLoading === false
    ) {
      try {
        setProductHistoryLoading(true);
        const data = await productHistoryApi.getProductsHistories();
        setProductsHistories(data);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setProductHistoryLoading(false);
      }
    }
  };

  const getProductHistoryById = async (productHistoryId: string) => {
    setProductHistoryLoading(true);
    setProductHistoryError(undefined);
    try {
      const data = await productHistoryApi.getProductHistoryById(
        productHistoryId
      );
      setProductHistory(data);
    } catch (e: unknown) {
      setProductHistoryError(handleErrorMessage(e));
    } finally {
      setProductHistoryLoading(false);
    }
  };

  const getProductHistoryByProductId = async (productId: string) => {
    setProductHistoryLoading(true);
    setProductHistoryError(undefined);
    try {
      const data = await productHistoryApi.getProductHistoryByProductId(
        productId
      );
      setProductHistoryByProduct(data);
    } catch (e: unknown) {
      setProductHistoryError(handleErrorMessage(e));
    } finally {
      setProductHistoryLoading(false);
    }
  };

  const getSubProductHistoriesBySubProductId = async (subProductId: string) => {
    let subProductHistories: ProductHistory[] = [];
    try {
      subProductHistories =
        await productHistoryApi.getSubProductHistoryBySubProductId(
          subProductId
        );
    } catch (e: unknown) {
      console.log(e);
    }
    if (subProductHistories.length > 0) {
      const labels = subProductHistories.map((item) => item.ano);
      const data: HistoryChartDataState = {
        labels,
        datasets: [
          {
            data: subProductHistories.map((item) => parseFloat(item.valor)),
            label: (subProductHistories[0] ?? { cidadeNome: '' }).cidadeNome,
            backgroundColor: green[100],
          },
        ],
      };
      if (subProductHistories.length > 0) {
        setSubProductHistory(data);
        return data;
      }
    }
    return null;
  };

  const uploadProductHistory = async (payload: UploadProductHistoryPayload) => {
    setProductHistoryLoading(true);
    setUploadProductHistoryError(undefined);
    try {
      await productHistoryApi.uploadProductHistory(payload);
    } catch (e: unknown) {
      setUploadProductHistoryError(handleErrorMessage(e));
      setProductHistoryLoading(false);
      return handleErrorMessage(e);
    } finally {
      setProductHistoryLoading(false);
    }
    return null;
  };

  return (
    <ProductHistoryContext.Provider
      value={
        {
          productHistoryApi,
          productsHistories,
          setProductsHistories,

          productHistory,
          setProductHistory,

          productHistoryLoading,
          setProductHistoryLoading,

          productHistoryError,
          setProductHistoryError,

          getProductsHistories,
          getProductHistoryById,
          getProductHistoryByProductId,
          getSubProductHistoriesBySubProductId,

          uploadProductHistory,
          uploadProductHistoryError,
          setUploadProductHistoryError,

          productHistoryByProduct,
          setProductHistoryByProduct,

          subProductHistory,
          setSubProductHistory,
        } as ProductHistoryContextType
      }
    >
      {children}
    </ProductHistoryContext.Provider>
  );
};

export const useProductHistory = () => useContext(ProductHistoryContext);
