import {
  FinancialInstitution,
  FinancialInstitutionPayload,
  PaginatedResponse,
} from '../types';
import { BaseClient } from './BaseService';

export interface FinancialInstitutionApi {
  getFinancialInstitutionById(id: string): Promise<FinancialInstitution>;
  getFinancialInstitutions(): Promise<Array<FinancialInstitution>>;
  getPagedFinancialInstitutions(
    page: number
  ): Promise<PaginatedResponse<FinancialInstitution>>;
  addFinancialInstitution(
    payload: FinancialInstitutionPayload
  ): Promise<FinancialInstitution>;
  editFinancialInstitution(
    payload: FinancialInstitutionPayload
  ): Promise<FinancialInstitution>;
  deleteFinancialInstitution(id: string): Promise<void>;
}

class FinancialInstitutionService
  extends BaseClient
  implements FinancialInstitutionApi
{
  constructor({
    basePath,
    contentType,
    token,
  }: {
    basePath?: string;
    contentType?: string;
    token: string;
  }) {
    super({ basePath, contentType, token });
  }

  async getFinancialInstitutionById(id: string): Promise<FinancialInstitution> {
    const { data } = await this.api.get(`/${id}/`);
    return data as FinancialInstitution;
  }

  async getFinancialInstitutions(): Promise<Array<FinancialInstitution>> {
    const { data } = await this.api.get(`/`);
    return data as Array<FinancialInstitution>;
  }

  async getPagedFinancialInstitutions(
    page: number
  ): Promise<PaginatedResponse<FinancialInstitution>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<FinancialInstitution>;
  }

  async deleteFinancialInstitution(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editFinancialInstitution(
    payload: FinancialInstitutionPayload
  ): Promise<FinancialInstitution> {
    const { data } = await this.api.put(`/`, payload);
    return data.instituicaoFinanceira as FinancialInstitution;
  }

  async addFinancialInstitution(
    payload: FinancialInstitutionPayload
  ): Promise<FinancialInstitution> {
    const { data } = await this.api.post(`/`, payload);
    return data.instituicaoFinanceira as FinancialInstitution;
  }
}

export default FinancialInstitutionService;
