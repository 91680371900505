import React from 'react';
import { AuthProvider } from './AuthProvider';
import { BrokerProvider } from './BrokerProvider';
import { FinancialInstitutionProvider } from './FinancialInstitutionProvider';
import { FinancialAgencyProvider } from './FinancialAgencyProvider';
import { InsuranceCompanyProvider } from './InsuranceCompanyProvider';
import { CoreProvider } from './CoreProvider';
import { ProductProvider } from './ProductProvider';
import { CustomerProvider } from './CustomerProvider';
import { AccountantProvider } from './AccountantProvider';
import { CropProvider } from './CropProvider';
import { ClimateProvider } from './ClimateProvider';
import { ProductHistoryProvider } from './ProductHistoryProvider';
import { ClimateHistoryProvider } from './ClimateHistoryProvider';
import { UiProvider } from './UiProvider';
import { IndexProvider } from './IndexProvider';

export const providers = [
  <AuthProvider key="auth" />,
  <BrokerProvider key="broker" />,
  <FinancialInstitutionProvider key="financialInstitution" />,
  <FinancialAgencyProvider key="financialAgency" />,
  <InsuranceCompanyProvider key="insuranceCompany" />,
  <CoreProvider key="core" />,
  <ProductProvider key="product" />,
  <CustomerProvider key="user" />,
  <AccountantProvider key="accountant" />,
  <CropProvider key="crop" />,
  <ClimateProvider key="climate" />,
  <ProductHistoryProvider key="productHistory" />,
  <ClimateHistoryProvider key="climateHistory" />,
  <UiProvider key="ui" />,
  <IndexProvider key="index" />,
];
