import { commonFormFields, initField } from '../../utils';
import {
  FinancialInstitution,
  FinancialInstitutionFormType,
  FileFieldType,
  TextFieldType,
  SelectFieldType,
  SelectOption,
} from '../../types';

export const initForm = ({ data }: { data?: FinancialInstitution }) => {
  const form: FinancialInstitutionFormType = {
    ...commonFormFields(data),
    Nome: initField<TextFieldType>({
      name: 'Nome',
      value: data?.nome ? data.nome : '',
      label: 'Nome/Razão Social',
      required: true,
    }),
    CorretoraId: initField<SelectFieldType>({
      name: 'CorretoraId',
      value: data?.corretoraId
        ? ({ value: data.corretoraId, label: '' } as SelectOption)
        : null,
      label: 'Corretora',
      required: true,
      extractValue: true,
    }),
    Email: initField<TextFieldType>({
      name: 'Email',
      value: data?.email ? data.email : '',
      type: 'email',
      required: true,
    }),
    Senha: initField<TextFieldType>({
      name: 'Senha',
      value: '',
      type: 'password',
    }),
    ConfirmaSenha: initField<TextFieldType>({
      name: 'ConfirmaSenha',
      value: '',
      type: 'password',
      excludeFromPayload: true,
      label: 'Confirmar Senha',
    }),
    Endereco: initField<TextFieldType>({
      name: 'Endereco',
      value: '',
      label: 'Rua',
    }),
    NumeroEndereco: initField<TextFieldType>({
      name: 'NumeroEndereco',
      value: '',
      label: 'N°',
      type: 'number',
    }),
    Complemento: initField<TextFieldType>({ name: 'Complemento', value: '' }),
    Bairro: initField<TextFieldType>({ name: 'Bairro', value: '' }),
    Cidade: initField<TextFieldType>({
      name: 'Cidade',
      value: data?.cidade ? data.cidade : '',
    }),
    Logomarca: initField<FileFieldType>({ name: 'Logomarca', value: null }),
  };

  if (data?.instituicaoFinanceiraId ?? false) {
    form.InstituicaoFinanceiraId = initField<TextFieldType>({
      name: 'InstituicaoFinanceiraId',
      value: data?.instituicaoFinanceiraId as string,
    });
  }

  return form;
};
