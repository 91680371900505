import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';
import { EmptyList } from '../../components/EmptyList';
import { TableSkeleton } from '../../components/TableSkeleton';
import { DeleteDialog } from '../../components/DeleteDialog';
import { Layout } from '../../components/Layout';
import { SearchField } from '../../components/SearchField';
import { ErrorMsg } from '../../components/ErrorMsg';
import { ImageField } from '../../components/ImageField';

import { useClimateHistory, useProduct } from '../../contexts';
import {
  handleErrorMessage,
  initField,
  SUPORTED_CSV_FIELD_TYPES,
} from '../../utils';
import {
  ClimateHistory,
  SelectOption,
  FileFieldType,
  UploadClimateHistoryPayload,
} from '../../types';

export const ClimateHistoryList = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    climateHistoryApi,
    climatesHistories,
    setClimatesHistories,
    climateHistoryLoading,
    setClimateHistoryError,
    uploadClimateHistory,
    uploadClimateHistoryError,
  } = useClimateHistory();
  const { productsOptions, productLoading, getProductsOptions } = useProduct();

  const climatesHistoriesCount = climatesHistories?.length ?? -1;

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const [climateHistoryDataExcelOpen, setClimateHistoryDataExcelOpen] =
    useState<boolean>(false);
  const [productId, setProductId] = useState<SelectOption>(null);

  const [HistoricoProduto, setHistoricoProduto] = useState(
    initField<FileFieldType>({
      name: 'HistoricoProduto',
      value: null,
      label: 'HistoricoProduto',
    })
  );

  const [page] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [_, setPageSize] = useState<number>(10); // eslint-disable-line

  const getClimatesHistories = async () => {
    if (loading === false) {
      try {
        setLoading(true);
        const data = await climateHistoryApi.getPagedClimatesHistories(page);
        setPageSize(data.items.length);
        setPageCount(
          data.totalItems < data.items.length
            ? 1
            : Math.ceil(data.totalItems / data.items.length)
        );
        setClimatesHistories(data.items);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePageChange = async (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setLoading(true);
      const data = await climateHistoryApi.getPagedClimatesHistories(page);
      setClimatesHistories(data.items);
    } catch (e: unknown) {
      console.error(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClimatesHistories();
    getProductsOptions();
  }, []);

  const handleDelete = async () => {
    await climateHistoryApi.deleteClimateHistory(deleteId as string);
    setClimatesHistories(
      (climatesHistories ?? []).filter(
        (climateHistory: ClimateHistory) =>
          climateHistory.historicoClimaId !== deleteId
      )
    );
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  return (
    <Layout>
      <Typography variant="h4" component="h2" color="textSecondary">
        Histórico Climático de Município
      </Typography>
      <Box
        sx={{
          marginTop: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
        }}
      >
        <Button
          variant="contained"
          color="primary"
          component={Link}
          href="/climates-histories/create/"
        >
          <Typography
            variant="button"
            color="white"
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            <AddIcon />
            Cadastrar Histórico Climático de Município
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setClimateHistoryDataExcelOpen(true);
          }}
        >
          <Typography
            variant="button"
            color="white"
            style={{
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            <CloudUploadIcon />
            Upload Excel de Histórico Climático do Município
          </Typography>
        </Button>
      </Box>

      <div style={{ marginTop: theme.spacing(2) }}>
        {climatesHistoriesCount < 0 && (
          <TableSkeleton
            columns={['Nome', 'Email', 'Telefone', 'UF', 'CNPJ', 'Ações']}
          />
        )}
        {climatesHistoriesCount === 0 && (
          <EmptyList
            to="/climates-histories/create/"
            label="Cadastrar Histórico Climático do Município"
          />
        )}
        {climatesHistoriesCount > 0 && (
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(2),
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableHeadCell component="th" scope="row">
                      Produto
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Estado
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Cidade
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Data
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Valor
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Ações
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {climatesHistories?.map((row) => (
                    <TableRow key={row.historicoClimaId}>
                      <TableCell component="th" scope="row">
                        {`${row.cidadeNome} - ${row.estadoSigla}`}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.estadoNome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cidadeNome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.data}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.valor}
                      </TableCell>

                      <TableCell component="th" scope="row" align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            gap: theme.spacing(1),
                            justifyContent: 'center',
                          }}
                        >
                          <IconButton
                            sx={{
                              background: green[100],
                              color: theme.palette.primary.main,
                            }}
                            aria-label="Editar"
                            LinkComponent={Link}
                            href={`/climates-histories/edit/${row.historicoClimaId}/`}
                          >
                            <EditIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                          <IconButton
                            sx={{ background: red[100] }}
                            color="secondary"
                            aria-label="Excluir"
                            onClick={() => {
                              setDeleteOpen(true);
                              setDeleteId(row.historicoClimaId);
                              setDeleteName(row.historicoClimaId);
                            }}
                          >
                            <CloseIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Pagination
                count={pageCount}
                disabled={loading}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          </Box>
        )}
      </div>
      <DeleteDialog
        open={deleteOpen}
        name={deleteName}
        setOpen={setDeleteOpen}
        handleDelete={handleDelete}
      />
      <Dialog
        open={climateHistoryDataExcelOpen}
        onClose={(event, reason) => {
          if (reason && reason == 'backdropClick' && climateHistoryLoading)
            return;
          setClimateHistoryDataExcelOpen(false);
        }}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <Box marginTop={2}>
          <ErrorMsg error={uploadClimateHistoryError} />
        </Box>
        <DialogTitle id="delete-dialog-title">
          Atualizar Dados Climáticos de Produto
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              display: 'flex',
              marginBottom: theme.spacing(2),
            }}
          >
            Qual produto deseja atualizar?
          </Typography>
          <SearchField
            extraAttrs={{ value: productId as SelectOption }}
            options={productsOptions}
            label="Selecione o Produto"
            loading={productLoading}
            handleChange={(value: SelectOption) => {
              setProductId(value);
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <ImageField
              setField={(field: FileFieldType) => {
                setHistoricoProduto(field);
                setClimateHistoryError(undefined);
              }}
              field={HistoricoProduto}
              allowedFileTypes={SUPORTED_CSV_FIELD_TYPES}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={climateHistoryLoading}
            color="inherit"
            onClick={() => {
              setClimateHistoryDataExcelOpen(false);
              setClimateHistoryError(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            loading={climateHistoryLoading}
            onClick={async () => {
              const payload = {
                HistoricoProduto: HistoricoProduto.value,
                ProdutoId: productId?.value ?? '',
              } as UploadClimateHistoryPayload;
              const data = await uploadClimateHistory(payload);
              if (typeof data === 'object') {
                await getClimatesHistories();
                setProductId(null);
                setClimateHistoryDataExcelOpen(false);
              }
            }}
            color="primary"
            autoFocus
            variant="outlined"
            disabled={productId ? false : true}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
