import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { useAuth, useUi } from '../../contexts';
import hlogo from '../../assets/hlogo.png';

const StyledLink = styled(Link)(() => ({
  fontWeight: 'bold',
  color: 'inherit',
  display: 'block',
  fontSize: 20,
  width: '100%',
}));

const StyledItem = styled('li')(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  width: '100%',
}));

export const Sidebar = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { sidebarOpen, setSidebarOpen, sidebarWidth, closeSidebar } = useUi();
  const { pathname } = useLocation();

  const getItemColor = (path: string) => {
    if (path === '/') {
      if (path === pathname) return theme.palette.primary.main;
      else return theme.palette.text.secondary;
    }
    if (
      pathname.startsWith('/' + path) &&
      !pathname.replace(path, '').startsWith('/-')
    )
      return theme.palette.primary.main;
    return theme.palette.text.secondary;
  };

  return (
    <Drawer open={sidebarOpen} anchor="left" onClose={closeSidebar}>
      <nav
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          height: '100%',
          overflowY: 'scroll',
          width: sidebarWidth,
          padding: sidebarOpen ? `${theme.spacing(3)} ${theme.spacing(3)}` : 0,
          background: theme.palette.background.default,
        }}
      >
        <IconButton
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
          sx={{
            position: 'absolute',
            top: 24,
            left: sidebarWidth - 60,
          }}
        >
          <CloseIcon sx={{ color: theme.palette.text.secondary }} />
        </IconButton>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <img src={hlogo} alt="Field Tech Logo" height={36} />
          </Box>
          <ul
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              gap: theme.spacing(2),
              marginTop: theme.spacing(4),
              flexDirection: 'column',
              color: theme.palette.text.primary,
              fontWeight: 'bold',
            }}
          >
            <StyledItem style={{ color: getItemColor('/') }}>
              <StyledLink href="/" onClick={closeSidebar}>
                Inicio
              </StyledLink>
            </StyledItem>
            {(user?.allowedModules?.includes('customers') ?? false) && (
              <StyledItem style={{ color: getItemColor('customers') }}>
                <StyledLink href="/customers/" onClick={closeSidebar}>
                  Clientes
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('brokers') ?? false) && (
              <StyledItem style={{ color: getItemColor('brokers') }}>
                <StyledLink href="/brokers/" onClick={closeSidebar}>
                  Corretoras
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('financial-institutions') ??
              false) && (
              <StyledItem
                style={{ color: getItemColor('financial-institutions') }}
              >
                <StyledLink
                  href="/financial-institutions/"
                  onClick={closeSidebar}
                >
                  Segurados
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('financial-agencies') ??
              false) && (
              <StyledItem style={{ color: getItemColor('financial-agencies') }}>
                <StyledLink href="/financial-agencies/" onClick={closeSidebar}>
                  Agências / Filiais
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('insurance-companies') ??
              false) && (
              <StyledItem
                style={{ color: getItemColor('insurance-companies') }}
              >
                <StyledLink href="/insurance-companies/" onClick={closeSidebar}>
                  Seguradoras
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('crops') ?? false) && (
              <StyledItem style={{ color: getItemColor('crops') }}>
                <StyledLink href="/crops/" onClick={closeSidebar}>
                  Culturas
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('products') ?? false) && (
              <StyledItem style={{ color: getItemColor('products') }}>
                <StyledLink href="/products/" onClick={closeSidebar}>
                  Produtos
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('accountants') ?? false) && (
              <StyledItem style={{ color: getItemColor('accountants') }}>
                <StyledLink href="/accountants/" onClick={closeSidebar}>
                  Cotações e Propostas
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('proposals') ?? false) && (
              <StyledItem style={{ color: getItemColor('proposals') }}>
                <StyledLink href="/proposals/" onClick={closeSidebar}>
                  Acompanhamento dos índices
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('climates') ?? false) && (
              <StyledItem style={{ color: getItemColor('climates') }}>
                <StyledLink href="/climates/" onClick={closeSidebar}>
                  Dados atuais dos provedores
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('climates-histories') ??
              false) && (
              <StyledItem style={{ color: getItemColor('climates-histories') }}>
                <StyledLink href="/climates-histories/" onClick={closeSidebar}>
                  Base histórica dos municípios
                </StyledLink>
              </StyledItem>
            )}
            {(user?.allowedModules?.includes('indexes') ?? false) && (
              <StyledItem style={{ color: getItemColor('indexes') }}>
                <StyledLink href="/indexes/" onClick={closeSidebar}>
                  Índices
                </StyledLink>
              </StyledItem>
            )}
          </ul>
        </Box>
      </nav>
    </Drawer>
  );
};
