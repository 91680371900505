import React, { createContext, useState, useContext } from 'react';
import CustomerService, { CustomerApi } from '../services/CustomerService';

import { SelectOption, Customer } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './AuthProvider';

export type CustomerContextType = {
  customerApi: CustomerApi;
  customers: Array<Customer> | undefined;
  setCustomers: React.Dispatch<
    React.SetStateAction<Array<Customer> | undefined>
  >;
  customer: Customer | undefined;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;
  customerError: string | undefined;
  setCustomerError: React.Dispatch<React.SetStateAction<string | undefined>>;
  customerLoading: boolean;
  setCustomerLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getCustomer(id: string): Promise<Customer>;
  customersOptions: Array<SelectOption>;
  setCustomersOptions: React.Dispatch<
    React.SetStateAction<Array<SelectOption> | undefined>
  >;
  getCustomerOptions(): Promise<void>;
};

export const CustomerContext = createContext<CustomerContextType>(
  {} as unknown as CustomerContextType
);

export const CustomerProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [customers, setCustomers] = useState<Array<Customer>>();
  const [customer, setCustomer] = useState<Customer>();
  const [customerLoading, setCustomerLoading] = useState<boolean>(false);
  const [customerError, setCustomerError] = useState<string>();
  const [customersOptions, setCustomersOptions] =
    useState<Array<SelectOption>>();

  const { token } = useAuth();

  const customerApi = new CustomerService({
    basePath: '/v1/cliente',
    contentType: 'multipart/form-data',
    token,
  });

  const getCustomer = async (id: string) => {
    let data: Customer | undefined = undefined;
    setCustomerLoading(true);
    setCustomerError(undefined);
    try {
      data = await customerApi.getCustomerById(id);
      setCustomer(data);
    } catch (e: unknown) {
      setCustomerError(handleErrorMessage(e));
    } finally {
      setCustomerLoading(false);
    }
    return data as Customer;
  };

  const getCustomerOptions = async () => {
    try {
      setCustomerLoading(true);
      const data = await customerApi.getCustomers();
      const options = data.map(
        (customer) =>
          ({
            label: customer.nome,
            value: customer.clienteId,
          } as SelectOption)
      );
      setCustomersOptions(options);
    } catch (error: unknown) {
      setCustomerError(handleErrorMessage(error));
    } finally {
      setCustomerLoading(false);
    }
  };

  return (
    <CustomerContext.Provider
      value={
        {
          customerApi,
          customers,
          setCustomers,
          customer,
          setCustomer,
          customerLoading,
          setCustomerLoading,
          customerError,
          setCustomerError,
          getCustomer,
          customersOptions,
          setCustomersOptions,
          getCustomerOptions,
        } as CustomerContextType
      }
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => useContext(CustomerContext);
