import { initField, percentageFieldMask } from '../../utils';
import dayjs from 'dayjs';
import {
  SelectFieldType,
  SubProduct,
  SubProductFormType,
  TextFieldType,
  DateFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: SubProduct }) => {
  const form: SubProductFormType = {
    CidadeId: initField<SelectFieldType>({
      name: 'CidadeId',
      value: null,
      label: 'Cidade',
      required: true,
      extractValue: true,
    }),
    EstadoId: initField<SelectFieldType>({
      name: 'EstadoId',
      value: null,
      label: 'Estado',
      required: true,
      extractValue: true,
    }),
    Taxa: initField<TextFieldType>({
      name: 'Taxa',
      label: 'Taxa (%)',
      value: data?.taxa ? data.taxa : '',
      required: true,
      mask: percentageFieldMask,
    }),
    DataInicioGatilho1: initField<DateFieldType>({
      name: 'DataInicioGatilho1',
      value: data?.dataInicioGatilho1 ? dayjs(data.dataInicioGatilho1) : null,
      label: 'Data Inicio Gatilho 1',
      required: true,
    }),
    DataFinalGatilho1: initField<DateFieldType>({
      name: 'DataFinalGatilho1',
      value: data?.dataFinalGatilho1 ? dayjs(data.dataFinalGatilho1) : null,
      label: 'Data Final Gatilho 1',
      required: true,
    }),
    DataInicioGatilho2: initField<DateFieldType>({
      name: 'DataInicioGatilho2',
      value: data?.dataInicioGatilho2 ? dayjs(data.dataInicioGatilho2) : null,
      label: 'Data Inicio Gatilho 2',
      required: false,
    }),
    DataFinalGatilho2: initField<DateFieldType>({
      name: 'DataFinalGatilho2',
      value: data?.dataFinalGatilho2 ? dayjs(data.dataFinalGatilho2) : null,
      label: 'Data Final Gatilho 2',
      required: false,
    }),
    DataInicioGatilho3: initField<DateFieldType>({
      name: 'DataInicioGatilho3',
      value: data?.dataInicioGatilho3 ? dayjs(data.dataInicioGatilho3) : null,
      label: 'Data Inicio Gatilho 3',
      required: false,
    }),
    DataFinalGatilho3: initField<DateFieldType>({
      name: 'DataFinalGatilho3',
      value: data?.dataFinalGatilho3 ? dayjs(data.dataFinalGatilho3) : null,
      label: 'Data Final Gatilho 3',
      required: false,
    }),
    IndenizacaoStrike1Gatilho1: initField<TextFieldType>({
      name: 'IndenizacaoStrike1Gatilho1',
      value: '',
      label: 'Indenizacao Strike 1 Gatilho 1',
      required: false,
    }),
    IndenizacaoStrike1Gatilho2: initField<TextFieldType>({
      name: 'IndenizacaoStrike1Gatilho2',
      value: '',
      label: 'Indenizacao Strike 1 Gatilho 2',
      required: false,
    }),
    IndenizacaoStrike1Gatilho3: initField<TextFieldType>({
      name: 'IndenizacaoStrike1Gatilho3',
      value: '',
      label: 'Indenizacao Strike 1 Gatilho 3',
      required: false,
    }),
    IndenizacaoStrike2Gatilho1: initField<TextFieldType>({
      name: 'IndenizacaoStrike2Gatilho1',
      value: '',
      label: 'Indenizacao Strike 2 Gatilho 1',
      required: false,
    }),
    IndenizacaoStrike2Gatilho2: initField<TextFieldType>({
      name: 'IndenizacaoStrike2Gatilho2',
      value: '',
      label: 'Indenizacao Strike 2 Gatilho 2',
      required: false,
    }),
    IndenizacaoStrike2Gatilho3: initField<TextFieldType>({
      name: 'IndenizacaoStrike2Gatilho3',
      value: '',
      label: 'Indenizacao Strike 2 Gatilho 3',
      required: false,
    }),
    IndenizacaoStrike3Gatilho1: initField<TextFieldType>({
      name: 'IndenizacaoStrike3Gatilho1',
      value: '',
      label: 'Indenizacao Strike 3 Gatilho 1',
      required: false,
    }),
    IndenizacaoStrike3Gatilho2: initField<TextFieldType>({
      name: 'IndenizacaoStrike3Gatilho2',
      value: '',
      label: 'Indenizacao Strike 3 Gatilho 2',
      required: false,
    }),
    IndenizacaoStrike3Gatilho3: initField<TextFieldType>({
      name: 'IndenizacaoStrike3Gatilho3',
      value: '',
      label: 'Indenizacao Strike 3 Gatilho 3',
      required: false,
    }),
    Strike1Gatilho1: initField<TextFieldType>({
      name: 'Strike1Gatilho1',
      value: '',
      label: 'Strike 1 Gatilho 1',
      required: false,
    }),
    Strike1Gatilho2: initField<TextFieldType>({
      name: 'Strike1Gatilho2',
      value: '',
      label: 'Strike 1 Gatilho 2',
      required: false,
    }),
    Strike1Gatilho3: initField<TextFieldType>({
      name: 'Strike1Gatilho3',
      value: '',
      label: 'Strike 1 Gatilho 3',
      required: false,
    }),
    Strike2Gatilho1: initField<TextFieldType>({
      name: 'Strike2Gatilho1',
      value: '',
      label: 'Strike 2 Gatilho 1',
      required: false,
    }),
    Strike2Gatilho2: initField<TextFieldType>({
      name: 'Strike2Gatilho2',
      value: '',
      label: 'Strike 2 Gatilho 2',
      required: false,
    }),
    Strike2Gatilho3: initField<TextFieldType>({
      name: 'Strike2Gatilho3',
      value: '',
      label: 'Strike 2 Gatilho 3',
      required: false,
    }),
    Strike3Gatilho1: initField<TextFieldType>({
      name: 'Strike3Gatilho1',
      value: '',
      label: 'Strike 3 Gatilho 1',
      required: false,
    }),
    Strike3Gatilho2: initField<TextFieldType>({
      name: 'Strike3Gatilho2',
      value: '',
      label: 'Strike 3 Gatilho 2',
      required: false,
    }),
    Strike3Gatilho3: initField<TextFieldType>({
      name: 'Strike3Gatilho3',
      value: '',
      label: 'Strike 3 Gatilho 3',
      required: false,
    }),
    CoberturaMaxima: initField<TextFieldType>({
      name: 'CoberturaMaxima',
      value: '',
      label: 'Cobertura Máxima',
      required: false,
      mask: ({ e }: { e: React.ChangeEvent<HTMLInputElement> }) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '').slice(0, 15);
        return value;
      },
    }),
  };

  return form;
};
