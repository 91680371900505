import React, { createContext, useState, useContext } from 'react';
import ClimateService, { ClimateApi } from '../services/ClimateService';

import { Climate, UploadClimateProductHistoryPayload } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './AuthProvider';

export type ClimateContextType = {
  climates: Array<Climate> | undefined;
  setClimates: React.Dispatch<React.SetStateAction<Array<Climate> | undefined>>;
  climate: Climate | undefined;
  setClimate: React.Dispatch<React.SetStateAction<Climate | undefined>>;
  climateError: string | undefined;
  setClimateError: React.Dispatch<React.SetStateAction<string | undefined>>;
  climateLoading: boolean;
  setClimateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getClimate(id: string): Promise<Climate>;
  climateApi: ClimateApi;
  getClimates(): Promise<void>;
  uploadClimateProductHistory(
    payload: UploadClimateProductHistoryPayload
  ): Promise<null | string>;
  setUploadClimateError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  uploadClimateError: string | undefined;
};

export const ClimateContext = createContext<ClimateContextType>(
  {} as unknown as ClimateContextType
);

export const ClimateProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [climates, setClimates] = useState<Array<Climate>>();
  const [climate, setClimate] = useState<Climate>();
  const [climateLoading, setClimateLoading] = useState<boolean>(false);
  const [climateError, setClimateError] = useState<string>();
  const [uploadClimateError, setUploadClimateError] = useState<string>();

  const { token } = useAuth();

  const climateApi = new ClimateService({
    basePath: '/v1/dadoclimatico',
    contentType: 'multipart/form-data',
    token,
  });

  const getClimates = async () => {
    if ((climates?.length ?? -1) < 0 && climateLoading === false) {
      try {
        setClimateLoading(true);
        const data = await climateApi.getClimates();
        setClimates(data);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setClimateLoading(false);
      }
    }
  };

  const uploadClimateProductHistory = async (
    payload: UploadClimateProductHistoryPayload
  ) => {
    setClimateLoading(true);
    setUploadClimateError(undefined);
    try {
      await climateApi.uploadClimateProductHistory(payload);
    } catch (e: unknown) {
      setUploadClimateError(handleErrorMessage(e));
      setClimateLoading(false);
      return handleErrorMessage(e);
    } finally {
      setClimateLoading(false);
    }
    return null;
  };

  const getClimate = async (id: string) => {
    let data: Climate | undefined = undefined;
    setClimateLoading(true);
    setClimateError(undefined);
    try {
      data = await climateApi.getClimateById(id);
      setClimate(data);
    } catch (e: unknown) {
      setClimateError(handleErrorMessage(e));
    } finally {
      setClimateLoading(false);
    }
    return data;
  };

  return (
    <ClimateContext.Provider
      value={
        {
          climateApi,
          climates,
          setClimates,
          climate,
          setClimate,
          climateLoading,
          setClimateLoading,
          climateError,
          setClimateError,
          getClimate,
          getClimates,
          uploadClimateProductHistory,
          uploadClimateError,
          setUploadClimateError,
        } as ClimateContextType
      }
    >
      {children}
    </ClimateContext.Provider>
  );
};

export const useClimate = () => useContext(ClimateContext);
