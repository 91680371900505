import React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const ErrorMsg = ({
  error,
  spacing = 1,
}: {
  error: string | Array<string> | undefined;
  spacing?: number;
}) => {
  if (typeof error === 'string') {
    error = [error];
  }
  if (typeof error === 'undefined') return null;
  const theme = useTheme();

  return (
    <div>
      {error
        .filter((err: string) => err != null)
        .map((err: string, index: number) => (
          <Box
            key={index}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ErrorOutlineIcon
              fontSize="inherit"
              color="secondary"
              sx={{ marginRight: theme.spacing(spacing) }}
            />
            <Typography variant="inherit" color="secondary">
              {err}
            </Typography>
          </Box>
        ))}
    </div>
  );
};
