import React from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import building_blocks from '../../assets/building_blocks.svg';
import { useTheme } from '@mui/material/styles';

export const EmptyList = ({
  to,
  label,
  title,
  content = 'Items criados apareceram nessa tela. Tente adicionar um item e ver o que acontece!',
  img = true,
  justifyContent = 'space-between',
}: {
  to?: string;
  label?: string;
  title?: string;
  content?: string;
  img?: boolean;
  justifyContent?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent,
        padding: `${theme.spacing(6)} ${theme.spacing(0)}`,
      }}
    >
      <Box>
        <Typography
          variant="h5"
          color="textSecondary"
          marginBottom={theme.spacing(2)}
          marginRight={theme.spacing(2)}
        >
          {title ?? 'Nenhum item nessa listagem ainda!'}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          marginBottom={theme.spacing(2)}
          marginRight={theme.spacing(2)}
        >
          {content}
        </Typography>
        {to && (
          <Button variant="contained" component={Link} href={to}>
            <Typography
              variant="button"
              sx={{ color: theme.palette.common.white }}
            >
              {label ? label : 'Adicionar Item'}
            </Typography>
          </Button>
        )}
      </Box>
      {img && (
        <img style={{ maxWidth: 300 }} src={building_blocks} alt="Nenhum(a)" />
      )}
    </Box>
  );
};
