import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';

type Item = { href: string; name: string };

export const Breadcrumb = ({
  activeItem,
  items = [],
  size = 18,
  previousRoute,
}: {
  activeItem: string;
  items?: Array<Item> | undefined;
  size?: number;
  previousRoute?: string;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: theme.spacing(1),
        fontSize: size,
      }}
    >
      <IconButton
        aria-label="Voltar"
        LinkComponent={Link}
        href={previousRoute ?? ''} // TODO: use router v6 previous page to go back.
        disabled={previousRoute ? false : true}
      >
        <ArrowBackIcon sx={{ fontSize: size + 4 }} />
      </IconButton>
      <Link href="/" sx={{ textDecoration: 'none' }} color="primary">
        inicio
      </Link>
      {items?.map((item: Item) => (
        <Box key={item.href}>
          <Typography variant="caption" component="span" color="primary">
            /
          </Typography>
          <Link
            href={item.href}
            sx={{ textDecoration: 'none', marginLeft: theme.spacing(1) }}
            color="primary"
          >
            {item.name.toLocaleLowerCase()}
          </Link>
        </Box>
      ))}
      <Typography variant="body1" component="span" color="primary">
        /
      </Typography>
      <Typography
        variant="body1"
        component="span"
        color="textSecondary"
        fontSize={size - 2}
      >
        {activeItem.toLocaleLowerCase()}
      </Typography>
    </Box>
  );
};
