import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Home } from '../pages/Home';
import { BrokerList } from '../pages/BrokerList';
import { BrokerForm } from '../pages/BrokerForm';
import { IndexList } from '../pages/IndexList';
import { IndexForm } from '../pages/IndexForm';
import { CustomerList } from '../pages/CustomerList';
import { CustomerForm } from '../pages/CustomerForm';
import { FinancialInstitutionList } from '../pages/FinancialInstitutionList';
import { FinancialInstitutionForm } from '../pages/FinancialInstitutionForm';
import { FinancialAgencyList } from '../pages/FinancialAgencyList';
import { FinancialAgencyForm } from '../pages/FinancialAgencyForm';
import { InsuranceCompanyList } from '../pages/InsuranceCompanyList';
import { InsuranceCompanyForm } from '../pages/InsuranceCompanyForm';
import { ProductList } from '../pages/ProductList';
import { ProductForm } from '../pages/ProductForm';
import { ProductDetail } from '../pages/ProductDetail';
import { SubProductForm } from '../pages/SubProductForm';
import { FullProductForm } from '../pages/FullProductForm';
import { AccountantForm } from '../pages/AccountantForm';
import { AccountantList } from '../pages/AccountantList';
import { AccountantSimulate } from '../pages/AccountantSimulate';
import { CropList } from '../pages/CropList';
import { CropForm } from '../pages/CropForm';
import { ClimateList } from '../pages/ClimateList';
import { ClimateForm } from '../pages/ClimateForm';
import { ClimateHistoryList } from '../pages/ClimateHistoryList';
import { ClimateHistoryForm } from '../pages/ClimateHistoryForm';
import { ProposalList } from '../pages/ProposalList';
import { ProposalDetail } from '../pages/ProposalDetail';
import { ProductHistoryList } from '../pages/ProductHistoryList';
import { ProductHistoryForm } from '../pages/ProductHistoryForm';
import { ProposalValidate } from '../pages/ProposalValidate';

import { NotFound } from '../pages/NotFound';
import { Login } from '../pages/Login';
import { ForgotPassword } from '../pages/ForgotPassword';
import { ChangePassword } from '../pages/ChangePassword';

import { Module } from '../types';
import { useAuth } from '../contexts';

import { Loading } from '../components/Loading';

const PrivateRoute = ({
  component,
  module: module,
}: {
  component: JSX.Element;
  module?: Module;
}) => {
  const { isAuthenticated, user } = useAuth();

  if (typeof isAuthenticated === 'undefined') return <Loading />;

  if (!isAuthenticated) return <Navigate to="/login" replace />;

  if (module ?? false) {
    if (!user.allowedModules.includes(module as unknown as Module))
      return <Navigate to="/403" replace />;
  }
  return component;
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute component={<Home />} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/change-password/"
        element={<PrivateRoute component={<ChangePassword />} />}
      />
      <Route
        path="/brokers/"
        element={<PrivateRoute component={<BrokerList />} module={'brokers'} />}
      />
      <Route
        path="/brokers/edit/:id/"
        element={
          <PrivateRoute
            component={<BrokerForm isCreation={false} />}
            module={'brokers'}
          />
        }
      />
      <Route
        path="/brokers/create/"
        element={
          <PrivateRoute
            component={<BrokerForm isCreation={true} />}
            module={'brokers'}
          />
        }
      />
      <Route
        path="/indexes/"
        element={<PrivateRoute component={<IndexList />} module={'indexes'} />}
      />
      <Route
        path="/indexes/edit/:id/"
        element={
          <PrivateRoute
            component={<IndexForm isCreation={false} />}
            module={'indexes'}
          />
        }
      />
      <Route
        path="/indexes/create/"
        element={
          <PrivateRoute
            component={<IndexForm isCreation={true} />}
            module={'indexes'}
          />
        }
      />
      <Route
        path="/customers/"
        element={
          <PrivateRoute component={<CustomerList />} module={'customers'} />
        }
      />
      <Route
        path="/customers/edit/:id/"
        element={
          <PrivateRoute
            component={<CustomerForm isCreation={false} />}
            module={'customers'}
          />
        }
      />
      <Route
        path="/customers/create/"
        element={
          <PrivateRoute
            component={<CustomerForm isCreation={true} />}
            module={'customers'}
          />
        }
      />
      <Route
        path="/financial-institutions/"
        element={
          <PrivateRoute
            component={<FinancialInstitutionList />}
            module={'financial-institutions'}
          />
        }
      />
      <Route
        path="/financial-institutions/create/"
        element={
          <PrivateRoute
            component={<FinancialInstitutionForm isCreation={true} />}
            module={'financial-institutions'}
          />
        }
      />
      <Route
        path="/financial-institutions/edit/:id/"
        element={
          <PrivateRoute
            component={<FinancialInstitutionForm isCreation={false} />}
            module={'financial-institutions'}
          />
        }
      />
      <Route
        path="/financial-agencies/"
        element={
          <PrivateRoute
            component={<FinancialAgencyList />}
            module={'financial-agencies'}
          />
        }
      />
      <Route
        path="/financial-agencies/create/"
        element={
          <PrivateRoute
            component={<FinancialAgencyForm isCreation={true} />}
            module={'financial-agencies'}
          />
        }
      />
      <Route
        path="/financial-agencies/edit/:id/"
        element={
          <PrivateRoute
            component={<FinancialAgencyForm isCreation={false} />}
            module={'financial-agencies'}
          />
        }
      />
      <Route
        path="/insurance-companies/"
        element={
          <PrivateRoute
            component={<InsuranceCompanyList />}
            module={'insurance-companies'}
          />
        }
      />
      <Route
        path="/insurance-companies/create/"
        element={
          <PrivateRoute
            component={<InsuranceCompanyForm isCreation={true} />}
            module={'insurance-companies'}
          />
        }
      />
      <Route
        path="/insurance-companies/edit/:id/"
        element={
          <PrivateRoute
            component={<InsuranceCompanyForm isCreation={false} />}
            module={'insurance-companies'}
          />
        }
      />
      <Route
        path="/products/"
        element={
          <PrivateRoute component={<ProductList />} module={'products'} />
        }
      />
      <Route
        path="/products/create/"
        element={
          <PrivateRoute
            component={<ProductForm isCreation={true} />}
            module={'products'}
          />
        }
      />
      <Route
        path="/products/edit/:id/"
        element={
          <PrivateRoute
            component={<ProductForm isCreation={false} />}
            module={'products'}
          />
        }
      />
      <Route
        path="/products/detail/:id/"
        element={
          <PrivateRoute component={<ProductDetail />} module={'products'} />
        }
      />
      <Route
        path="/products/full-product-create/"
        element={
          <PrivateRoute
            component={<FullProductForm isCreation={true} />}
            module={'products'}
          />
        }
      />
      <Route
        path="/products/subproduct/:productId/create/"
        element={
          <PrivateRoute
            component={<SubProductForm isCreation={true} />}
            module={'products'}
          />
        }
      />
      <Route
        path="/products/subproduct/:id/edit/"
        element={
          <PrivateRoute
            component={<SubProductForm isCreation={true} />}
            module={'products'}
          />
        }
      />
      <Route
        path="/accountants/"
        element={
          <PrivateRoute component={<AccountantList />} module={'accountants'} />
        }
      />
      <Route
        path="/accountants/create/"
        element={
          <PrivateRoute
            component={<AccountantForm isCreation={true} />}
            module={'accountants'}
          />
        }
      />
      <Route
        path="/accountants/edit/:id/"
        element={
          <PrivateRoute
            component={<AccountantForm isCreation={false} />}
            module={'accountants'}
          />
        }
      />
      <Route path="/accountant/simulate/" element={<AccountantSimulate />} />
      <Route
        path="/crops/"
        element={<PrivateRoute component={<CropList />} module={'crops'} />}
      />
      <Route
        path="/crops/edit/:id/"
        element={
          <PrivateRoute
            component={<CropForm isCreation={false} />}
            module={'crops'}
          />
        }
      />
      <Route
        path="/crops/create/"
        element={
          <PrivateRoute
            component={<CropForm isCreation={true} />}
            module={'crops'}
          />
        }
      />
      <Route
        path="/climates/"
        element={
          <PrivateRoute component={<ClimateList />} module={'climates'} />
        }
      />
      <Route
        path="/climates/edit/:id/"
        element={
          <PrivateRoute
            component={<ClimateForm isCreation={false} />}
            module={'climates'}
          />
        }
      />
      <Route
        path="/climates/create/"
        element={
          <PrivateRoute
            component={<ClimateForm isCreation={true} />}
            module={'climates'}
          />
        }
      />
      <Route path="/proposals/validate/:id/" element={<ProposalValidate />} />
      <Route
        path="/proposals/"
        element={
          <PrivateRoute component={<ProposalList />} module={'proposals'} />
        }
      />
      <Route
        path="/proposals/detail/:id/"
        element={
          <PrivateRoute component={<ProposalDetail />} module={'proposals'} />
        }
      />
      <Route
        path="/products-histories/"
        element={
          <PrivateRoute
            component={<ProductHistoryList />}
            module={'products-histories'}
          />
        }
      />
      <Route
        path="/products-histories/edit/:id/"
        element={
          <PrivateRoute
            component={<ProductHistoryForm isCreation={false} />}
            module={'products-histories'}
          />
        }
      />
      <Route
        path="/products-histories/create/"
        element={
          <PrivateRoute
            component={<ProductHistoryForm isCreation={true} />}
            module={'products-histories'}
          />
        }
      />

      <Route
        path="/climates-histories/"
        element={
          <PrivateRoute
            component={<ClimateHistoryList />}
            module={'climates-histories'}
          />
        }
      />
      <Route
        path="/climates-histories/edit/:id/"
        element={
          <PrivateRoute
            component={<ClimateHistoryForm isCreation={false} />}
            module={'climates-histories'}
          />
        }
      />
      <Route
        path="/climates-histories/create/"
        element={
          <PrivateRoute
            component={<ClimateHistoryForm isCreation={true} />}
            module={'climates-histories'}
          />
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { Router };
