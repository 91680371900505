import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

export const Loading = ({ fullPage = false }: { fullPage?: boolean }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        height: fullPage ? '100vh' : '100%',
        width: fullPage ? '100vw' : '100%',
      }}
    >
      <CircularProgress></CircularProgress>
    </Box>
  );
};
