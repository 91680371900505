import { Broker, BrokerPayload, PaginatedResponse } from '../types';
import { BaseClient } from './BaseService';

export interface BrokerApi {
  getBrokerById(id: string): Promise<Broker>;
  getBrokers(): Promise<Array<Broker>>;
  getPagedBrokers(page: number): Promise<PaginatedResponse<Broker>>;
  deleteBroker(id: string): Promise<void>;
  editBroker(payload: BrokerPayload): Promise<Broker>;
  addBroker(payload: BrokerPayload): Promise<Broker>;
}

class BrokerService extends BaseClient implements BrokerApi {
  async getBrokerById(id: string): Promise<Broker> {
    const { data } = await this.api.get(`/${id}/`);
    return data as Broker;
  }

  async getPagedBrokers(page: number): Promise<PaginatedResponse<Broker>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<Broker>;
  }

  async getBrokers(): Promise<Array<Broker>> {
    const { data } = await this.api.get(`/`);
    return data as Array<Broker>;
  }

  async deleteBroker(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editBroker(payload: BrokerPayload): Promise<Broker> {
    const { data } = await this.api.put(`/`, payload);
    return data.corretora as Broker;
  }

  async addBroker(payload: BrokerPayload): Promise<Broker> {
    const { data } = await this.api.post(`/`, payload);
    return data.corretora as Broker;
  }
}

export default BrokerService;
