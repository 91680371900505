import React, { useEffect, useState } from 'react';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjs, { Dayjs } from 'dayjs';
import Link from '@mui/material/Link';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MTextField from '@mui/material/TextField';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';
import { EmptyList } from '../../components/EmptyList';
import { TableSkeleton } from '../../components/TableSkeleton';
import { DeleteDialog } from '../../components/DeleteDialog';
import { Layout } from '../../components/Layout';
import { ActionInfoCard } from '../../components/ActionInfoCard';
import { BooleanChip } from '../../components/BooleanChip';
import { ErrorMsg } from '../../components/ErrorMsg';
import { SearchField } from '../../components/SearchField';
import { DownloadLinkButton } from '../../components/DownloadLinkButton';

import {
  useAccountant,
  useCustomer,
  useFinancialInstitution,
  useAuth,
} from '../../contexts';
import { Accountant, SelectOption } from '../../types';

import { downloadFile, formatCurrency, handleErrorMessage } from '../../utils';

type AccountantFilter = {
  customerAcceptance?: boolean;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  InstituicaoFinanceiraId: SelectOption;
};

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const AccountantList = () => {
  const theme = useTheme();
  const {
    accountants,
    setAccountants,
    accountantLoading,
    setAccountantLoading,
    accountantError,
    setAccountantError,
    exportAccountants,
    acceptFinancialInstitutions,
    acceptAccountants,
    linkUser,
    generateCSV,
  } = useAccountant();
  const { accountantApi } = useAccountant();

  const [page] = useState<number>(1); // eslint-disable-line
  const [pageCount, setPageCount] = useState<number>(0);
  const [_, setPageSize] = useState<number>(10); // eslint-disable-line

  const { customersOptions, getCustomerOptions, customerLoading } =
    useCustomer();
  const { getFinancialInstitutionsOptions, financialInstitutionsOptions } =
    useFinancialInstitution();

  const [filteredAccountants, setFilteredAccountants] = useState<
    Accountant[] | null | undefined
  >(accountants);

  const accountantsCount = filteredAccountants?.length ?? -1;

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const [linkUserOpen, setLinkUserOpen] = useState<boolean>(false);
  const [linkUserId, setLinkUserId] = useState<SelectOption>(null);
  const [linkAccountantId] = useState<string>();

  const { user } = useAuth();

  const canWrite = user.role !== 'seguradora';

  const getAccountants = async () => {
    if (accountantLoading === false) {
      try {
        setAccountantLoading(true);
        const data = await accountantApi.getPagedAccountants(page);
        setPageSize(data.items.length);
        setPageCount(
          data.totalItems < data.items.length
            ? 1
            : Math.ceil(data.totalItems / data.items.length)
        );
        setAccountants(data.items);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setAccountantLoading(false);
      }
    }
  };

  const handlePageChange = async (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setAccountantLoading(true);
      const data = await accountantApi.getPagedAccountants(page);
      setAccountants(data.items);
    } catch (e: unknown) {
      console.error(handleErrorMessage(e));
    } finally {
      setAccountantLoading(false);
    }
  };

  useEffect(() => {
    getFinancialInstitutionsOptions();
    getCustomerOptions();
    getAccountants(); // eslint-disable-next-line
    return () => {
      setAccountants(undefined);
    };
  }, []);

  useEffect(() => {
    setFilteredAccountants(accountants);
    handleFilter(); // eslint-disable-next-line
  }, [accountants]);

  useEffect(() => {
    setSelectedIds(
      (selectedIds ?? []).filter((id) => {
        const ids = (filteredAccountants ?? []).map(
          (item) => item?.cotadorId ?? ''
        );
        if (!ids.includes(id)) {
          return false;
        }
        return true;
      })
    ); // eslint-disable-next-line
  }, [filteredAccountants]);

  const handleDelete = async () => {
    await accountantApi.deleteAccountant(deleteId as string);
    setAccountants(
      (accountants ?? []).filter(
        (accountant: Accountant) => accountant.cotadorId !== deleteId
      )
    );
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  const [filters, setFilters] = React.useState<AccountantFilter>({
    InstituicaoFinanceiraId: null,
  });
  const filtersEnabled = Object.entries(filters ?? {}).length > 0;

  const [selectedIds, setSelectedIds] = React.useState<Array<string>>([]);

  const handleFilter = (filter?: AccountantFilter) => {
    if (typeof filter !== 'undefined') setFilters(filter);
    const validFilters = Object.fromEntries(
      Object.entries(filter ?? filters ?? {}).filter(
        ([_, value]) => value !== null // eslint-disable-line
      )
    );
    let auxAccountants = accountants;
    for (const [key, value] of Object.entries(validFilters)) {
      switch (key) {
        case 'customerAcceptance':
          auxAccountants = auxAccountants?.filter(
            (item: Accountant) => item.aceiteCliente === value
          );
          break;
        case 'startDate':
          auxAccountants = auxAccountants?.filter((item) => {
            return dayjs(item.dataCriacao, 'DD/MM/YYYY').isSameOrAfter(
              value as Dayjs
            );
          });
          break;
        case 'endDate':
          auxAccountants = auxAccountants?.filter((item) => {
            return dayjs(item.dataCriacao, 'DD/MM/YYYY').isSameOrBefore(
              value as Dayjs
            );
          });
          break;
        case 'InstituicaoFinanceiraId':
          auxAccountants = auxAccountants?.filter((item: Accountant) => {
            const auxValue = value as SelectOption;
            return (
              item.instituicaoFinanceiraId.toString() === auxValue?.value ??
              null
            );
          });
          break;
      }
    }
    setFilteredAccountants(auxAccountants);
  };

  const handleClearFilters = () => {
    setFilters({ InstituicaoFinanceiraId: null });
    setFilteredAccountants(accountants);
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={12} md={4} lg={2}></Grid>
        <Grid item xs={12} md={8} lg={10}>
          <Typography
            variant="h4"
            component="h2"
            color="textSecondary"
            marginBottom={2}
          >
            Cotações e Propostas
          </Typography>
        </Grid>
        <Grid item md={4} lg={2}>
          <Box marginRight={2}>
            {canWrite && (
              <Box>
                <Button
                  sx={{
                    marginBottom: theme.spacing(2),
                  }}
                  variant="contained"
                  color="primary"
                  component={Link}
                  href="/accountants/create/"
                  fullWidth
                >
                  <Typography
                    variant="button"
                    color="white"
                    style={{
                      display: 'flex',
                      gap: theme.spacing(1),
                    }}
                  >
                    <AddIcon />
                    Nova Cotação
                  </Typography>
                </Button>
                <ActionInfoCard text="" type="add" />
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                gap: theme.spacing(1),
                marginTop: theme.spacing(2),
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                color="textSecondary"
              >
                Filtros
              </Typography>
              <DesktopDatePicker
                label="Data de Inicio"
                inputFormat="DD/MM/YYYY"
                disabled={accountantLoading}
                value={
                  typeof filters?.startDate === 'undefined'
                    ? null
                    : filters?.startDate
                }
                onChange={(newValue) => {
                  handleFilter({ ...filters, startDate: newValue });
                }}
                renderInput={(params) => (
                  <MTextField {...params} size="small" fullWidth />
                )}
              />
              <DesktopDatePicker
                label="Data Final"
                inputFormat="DD/MM/YYYY"
                disabled={accountantLoading}
                value={
                  typeof filters?.endDate === 'undefined'
                    ? null
                    : filters?.endDate
                }
                onChange={(newValue) => {
                  handleFilter({ ...filters, endDate: newValue });
                }}
                renderInput={(params) => (
                  <MTextField {...params} size="small" fullWidth />
                )}
              />
              <SearchField
                extraAttrs={{
                  value: filters.InstituicaoFinanceiraId,
                }}
                options={financialInstitutionsOptions}
                label="Filtrar por Segurado"
                loading={accountantLoading}
                handleChange={(value: SelectOption) => {
                  handleFilter({
                    ...filters,
                    InstituicaoFinanceiraId: value,
                  });
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: theme.spacing(1),
                }}
              >
                <Chip
                  label="Aceito pelo Cliente"
                  variant="outlined"
                  size="small"
                  disabled={accountantLoading}
                  color={
                    filters?.customerAcceptance === true ? 'primary' : undefined
                  }
                  onClick={() => {
                    handleFilter({
                      ...filters,
                      customerAcceptance: true,
                    } as AccountantFilter);
                  }}
                />
                <Chip
                  label="Não aceito"
                  variant="outlined"
                  size="small"
                  disabled={accountantLoading}
                  color={
                    filters?.customerAcceptance === false
                      ? 'secondary'
                      : undefined
                  }
                  onClick={() => {
                    handleFilter({
                      ...filters,
                      customerAcceptance: false,
                    } as AccountantFilter);
                  }}
                />
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={handleClearFilters}
                disabled={accountantLoading}
                fullWidth
              >
                <Typography
                  variant="button"
                  style={{
                    display: 'flex',
                    gap: theme.spacing(1),
                  }}
                >
                  Limpar Filtros
                </Typography>
              </Button>

              <Box>
                {(selectedIds ?? []).length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: theme.spacing(1),
                      flexWrap: 'wrap',
                      marginTop: theme.spacing(2),
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      color="textSecondary"
                    >
                      Selecionados ({selectedIds.length})
                    </Typography>

                    {canWrite && (
                      <Button
                        variant="outlined"
                        color="info"
                        size="small"
                        fullWidth
                        onClick={async () => {
                          const csv = await generateCSV(
                            (accountants ?? []).filter((item) =>
                              selectedIds.includes(item?.cotadorId ?? '')
                            )
                          );
                          downloadFile(
                            `(${selectedIds.length}) ${new Date()
                              .toLocaleDateString()
                              .replaceAll(
                                '/',
                                '-'
                              )}-${new Date().getTime()}.csv`,
                            csv ?? ''
                          );
                        }}
                        disabled={accountantLoading}
                      >
                        <Typography
                          variant="button"
                          color="info"
                          style={{
                            display: 'flex',
                            gap: theme.spacing(1),
                          }}
                        >
                          Exportar CSV ({selectedIds?.length})
                        </Typography>
                      </Button>
                    )}
                    {canWrite && (
                      <Button
                        variant="outlined"
                        color="info"
                        size="small"
                        fullWidth
                        onClick={async () => {
                          await exportAccountants(selectedIds);
                          if (typeof accountantError === 'undefined') {
                            const auxAccountants = (accountants ?? []).map(
                              (item: Accountant) => {
                                if (
                                  selectedIds.includes(item.cotadorId ?? '')
                                ) {
                                  return { ...item, propostaEnviada: true };
                                }
                                return item;
                              }
                            );
                            setAccountants(auxAccountants);
                          }
                          setSelectedIds([]);
                        }}
                        disabled={accountantLoading}
                      >
                        <Typography
                          variant="button"
                          color="info"
                          style={{
                            display: 'flex',
                            gap: theme.spacing(1),
                          }}
                        >
                          Confirmar Envio Seguradora ({selectedIds?.length})
                        </Typography>
                      </Button>
                    )}

                    <Button
                      variant="outlined"
                      color="info"
                      size="small"
                      fullWidth
                      onClick={async () => {
                        await acceptFinancialInstitutions(selectedIds);
                        if (typeof accountantError === 'undefined') {
                          const auxAccountants = (accountants ?? []).map(
                            (item: Accountant) => {
                              if (selectedIds.includes(item.cotadorId ?? '')) {
                                return { ...item, propostaAceita: true };
                              }
                              return item;
                            }
                          );
                          setAccountants(auxAccountants);
                          setSelectedIds([]);
                        }
                      }}
                      disabled={accountantLoading}
                    >
                      <Typography
                        variant="button"
                        color="info"
                        style={{
                          display: 'flex',
                          gap: theme.spacing(1),
                        }}
                      >
                        Confimar aceite pela Seguradora ({selectedIds?.length})
                      </Typography>
                    </Button>

                    {canWrite && (
                      <Button
                        variant="outlined"
                        color="info"
                        size="small"
                        fullWidth
                        onClick={async () => {
                          await acceptAccountants(selectedIds);
                          if (typeof accountantError === 'undefined') {
                            const auxAccountants = (accountants ?? []).map(
                              (item: Accountant) => {
                                if (
                                  selectedIds.includes(item.cotadorId ?? '')
                                ) {
                                  return { ...item, aceiteCliente: true };
                                }
                                return item;
                              }
                            );
                            setAccountants(auxAccountants);
                            setSelectedIds([]);
                          }
                        }}
                        disabled={accountantLoading}
                      >
                        <Typography
                          variant="button"
                          color="info"
                          style={{
                            display: 'flex',
                            gap: theme.spacing(1),
                          }}
                        >
                          Confirmar Aceite do Cliente ({selectedIds?.length})
                        </Typography>
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} lg={10}>
          <div>
            {accountantsCount < 0 && (
              <TableSkeleton
                columns={['Nome', 'Email', 'Telefone', 'UF', 'CNPJ', 'Ações']}
              />
            )}
            {accountantsCount === 0 && (
              <EmptyList
                to="/accountants/create/"
                label="Fazer Nova Cotação"
                title={
                  filtersEnabled ? 'Nenhum registro com esse filtro' : undefined
                }
              />
            )}
            {accountantsCount > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableHeadCell component="th" scope="row">
                          <FormControlLabel
                            sx={{
                              marginLeft: theme.spacing(1),
                            }}
                            control={
                              <Checkbox
                                onChange={() => {
                                  if (selectedIds.length > 0) {
                                    setSelectedIds([]);
                                  } else {
                                    setSelectedIds(
                                      (filteredAccountants ?? []).map(
                                        (item) => item?.cotadorId ?? ''
                                      )
                                    );
                                  }
                                }}
                                checked={
                                  selectedIds.length ===
                                  (filteredAccountants ?? []).length
                                }
                                required
                                name="selectOrDeselectAllIds"
                                color="default"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              />
                            }
                            label=""
                          />
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Cliente
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Cidade
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Estado
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Premio
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Nível de Cobertura
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Importância Segurada por ha
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Valor da Cobertura
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Comprovante KML
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Comprovante CNH
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Comprovante Residência
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Enviado para Seguradora
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Aceite pela Seguradora
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Aceito pelo Cliente
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Ações
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredAccountants?.map((row) => (
                        <TableRow key={row.cotadorId}>
                          <TableCell component="th" scope="row">
                            <FormControlLabel
                              sx={{
                                marginLeft: theme.spacing(1),
                              }}
                              control={
                                <Checkbox
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      if (
                                        !selectedIds.includes(
                                          row?.cotadorId as string
                                        )
                                      )
                                        setSelectedIds(
                                          selectedIds.concat(
                                            row?.cotadorId as string
                                          )
                                        );
                                    } else {
                                      setSelectedIds(
                                        selectedIds.filter(
                                          (item) =>
                                            item !== (row?.cotadorId as string)
                                        )
                                      );
                                    }
                                  }}
                                  checked={selectedIds.includes(
                                    row?.cotadorId ?? ''
                                  )}
                                  required
                                  name="selectId"
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                />
                              }
                              label=""
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.nomeCliente ?? 'Sem nome'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.nomeCidade}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.nomeEstado}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            R$
                            {formatCurrency(
                              parseFloat(row.premio)
                                .toFixed(2)
                                .replace('.', ',')
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.nivelDeCobertura}%
                          </TableCell>
                          <TableCell component="th" scope="row">
                            R$
                            {formatCurrency(
                              parseFloat(row.importanciaSeguradaPorHA)
                                .toFixed(2)
                                .replace('.', ',')
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            R$
                            {formatCurrency(
                              parseFloat(row.valorDaCobertura)
                                .toFixed(2)
                                .replace('.', ',')
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DownloadLinkButton url={row.comprovanteKML} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DownloadLinkButton url={row.comprovanteCNH} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DownloadLinkButton
                              url={row.comprovanteResidencia}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <BooleanChip value={row.propostaEnviada} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <BooleanChip value={row.propostaAceita} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <BooleanChip value={row.aceiteCliente} />
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <Box
                              sx={{
                                display: 'flex',
                                gap: theme.spacing(1),
                                justifyContent: 'center',
                                padding: theme.spacing(1),
                              }}
                            >
                              {/* {typeof row.clienteId !== 'string' && (
                          <IconButton
                            sx={{
                              background: green[100],
                              color: theme.palette.primary.main,
                            }}
                            onClick={() => {
                              setLinkUserOpen(true);
                              setLinkAccountantId(row?.cotadorId as string);
                            }}
                            aria-label="Vincular Cliente a Cotação"
                          >
                            <AccountBox sx={{ fontSize: 14 }} />
                          </IconButton>
                        )} */}
                              <IconButton
                                sx={{ background: red[100] }}
                                color="secondary"
                                aria-label="Excluir"
                                onClick={() => {
                                  setDeleteOpen(true);
                                  setDeleteId(row.cotadorId);
                                  setDeleteName(row.produtoId);
                                }}
                              >
                                <CloseIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2} justifyContent="center" alignItems="center">
                  <Pagination
                    count={pageCount}
                    disabled={accountantLoading}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </Box>
            )}
          </div>
        </Grid>
      </Grid>

      <DeleteDialog
        open={deleteOpen}
        name={deleteName}
        setOpen={setDeleteOpen}
        handleDelete={handleDelete}
      />
      <Dialog
        open={linkUserOpen}
        onClose={(event, reason) => {
          if (reason && reason == 'backdropClick' && accountantLoading) return;
          setLinkUserOpen(false);
        }}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <Box marginTop={2}>
          <ErrorMsg error={accountantError} />
        </Box>
        <DialogTitle id="delete-dialog-title">Associar Cotação</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              display: 'flex',
              marginBottom: theme.spacing(2),
            }}
          >
            Qual usuário cliente associar com essa cotação?
          </Typography>
          <SearchField
            extraAttrs={{ value: linkUserId as SelectOption }}
            options={customersOptions}
            label="Selecione o Cliente"
            loading={customerLoading}
            handleChange={(value: SelectOption) => {
              setLinkUserId(value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={accountantLoading}
            color="inherit"
            onClick={() => {
              setLinkUserOpen(false);
              setAccountantError(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            loading={accountantLoading}
            onClick={async () => {
              await linkUser(linkUserId?.value ?? '', linkAccountantId ?? '');
              if (typeof accountantError === 'undefined') {
                setLinkUserId(null);
                setLinkUserOpen(false);
              }
              setAccountants(
                (accountants ?? []).map((item) => {
                  if ((item?.cotadorId ?? '') === linkAccountantId) {
                    return { ...item, clienteId: linkUserId?.value ?? '' };
                  }
                  return item;
                })
              );
            }}
            color="primary"
            autoFocus
            variant="outlined"
            disabled={linkUserId ? false : true}
          >
            Associar Cotação
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
