import { commonFormFields, initField, maskCPF } from '../../utils';
import {
  Customer,
  CustomerFormType,
  FileFieldType,
  TextFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: Customer }) => {
  const form: CustomerFormType = {
    ...commonFormFields(data),
    Nome: initField<TextFieldType>({
      name: 'Nome',
      value: data?.nome ? data.nome : '',
      label: 'Nome/Razão Social',
      required: true,
    }),
    CPF: initField<TextFieldType>({
      name: 'CPF',
      value: data?.cpf ? data.cpf : '',
      type: 'CPF',
      mask: maskCPF,
      required: true,
    }),
    Email: initField<TextFieldType>({
      name: 'Email',
      value: data?.email ? data.email : '',
      type: 'email',
      required: true,
    }),
    Senha: initField<TextFieldType>({
      name: 'Senha',
      value: '',
      type: 'password',
    }),
    ConfirmaSenha: initField<TextFieldType>({
      name: 'ConfirmaSenha',
      value: '',
      type: 'password',
      excludeFromPayload: true,
      label: 'Confirmar Senha',
    }),
    Endereco: initField<TextFieldType>({
      name: 'Endereco',
      value: '',
      label: 'Rua',
    }),
    NumeroEndereco: initField<TextFieldType>({
      name: 'NumeroEndereco',
      value: '',
      label: 'N°',
      type: 'number',
    }),
    Complemento: initField<TextFieldType>({ name: 'Complemento', value: '' }),
    Bairro: initField<TextFieldType>({ name: 'Bairro', value: '' }),
    Cidade: initField<TextFieldType>({
      name: 'Cidade',
      value: data?.cidade ?? '',
    }),
    ComprovanteResidencia: initField<FileFieldType>({
      name: 'ComprovanteResidencia',
      value: null,
    }),
  };

  if (data?.clienteId ?? false) {
    form.ClienteId = initField<TextFieldType>({
      name: 'ClienteId',
      value: data?.clienteId as string,
    });
  }

  return form;
};
