import React from 'react';

import MaterialTextField, { TextFieldProps } from '@mui/material/TextField';
import { TextFieldType } from '../../types';

export const TextField = ({
  field,
  extraAttrs,
}: {
  field: TextFieldType;
  extraAttrs: TextFieldProps;
}) => {
  return (
    <MaterialTextField
      id={field.id}
      name={field.name}
      type={field.type}
      label={field.label}
      required={field.required ? true : undefined}
      size={field.size}
      value={field.value}
      fullWidth
      {...extraAttrs}
    />
  );
};
