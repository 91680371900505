import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Button from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Person from '@mui/icons-material/Person';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Key from '@mui/icons-material/Key';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ErrorMsg } from '../../components/ErrorMsg';
import { LoginPayload } from '../../types';
import { Field, cleanForm } from '../../utils';
import { handleErrorMessage } from '../../utils';

import Logo from '../../assets/logo.png';
import { useAuth } from '../../contexts';

type LoginForm = {
  email: Field<string>;
  password: Field<string>;
};

export const Login = () => {
  const theme = useTheme();
  const { login } = useAuth();

  const [form, setForm] = useState<LoginForm>({
    email: new Field<string>({ name: 'email' }),
    password: new Field<string>({ name: 'password' }),
  } as LoginForm);

  const [aceito, setAceito] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    auxForm[e.target.name as 'email' | 'password'].setValue(e.target.value);
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const cleanedForm = {
        ...cleanForm<LoginForm, LoginPayload>(form),
        aceito,
      };
      await login(cleanedForm);
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <main
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        gap: theme.spacing(2),
        paddingBottom: 14,
      }}
    >
      <Link href="/">
        <img src={Logo} alt="Field Tech Logo" />
      </Link>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          minWidth: '240px',
        }}
      >
        <ErrorMsg error={error} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            position: 'relative',
          }}
        >
          <Person
            sx={{
              color: 'action.active',
              mr: 1,
              my: 0.5,
              position: 'absolute',
              left: -30,
            }}
          />
          <FormControl variant="filled" sx={{ width: '100%' }}>
            <InputLabel htmlFor="email-label">Email</InputLabel>
            <FilledInput
              type="email"
              id={form.email.id}
              name="email"
              required
              size="small"
              disabled={loading}
              style={{ minWidth: '213px' }}
              fullWidth
              value={form.email.value}
              onChange={handleChange}
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            position: 'relative',
          }}
        >
          <Key
            sx={{
              color: 'action.active',
              mr: 1,
              my: 0.5,
              position: 'absolute',
              left: -30,
            }}
          />
          <FormControl variant="filled" sx={{ width: '100%' }}>
            <InputLabel htmlFor="passwrod-label">Senha</InputLabel>
            <FilledInput
              type={showPassword ? 'text' : 'password'}
              id={form.password.id}
              name="password"
              required
              size="small"
              disabled={loading}
              style={{ minWidth: '213px' }}
              fullWidth
              value={form.password.value}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={loading}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(
                      event: React.MouseEvent<HTMLButtonElement>
                    ) => {
                      event.preventDefault();
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>

        <FormGroup style={{ display: 'flex' }}>
          <FormControlLabel
            sx={{
              maxWidth: 618,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            control={
              <Checkbox
                name="aceito"
                onChange={() => {
                  setError(undefined);
                  setAceito(!aceito);
                }}
                disabled={loading}
                checked={aceito}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            }
            label={
              <Typography variant="caption" color="textSecondary">
                Aceite de{' '}
                <Link
                  color="primary"
                  sx={{ padding: 0 }}
                  href="#"
                  onClick={() => {
                    window.open(
                      'https://fieldtechstorage.blob.core.windows.net/fieldtechfiles/termos.pdf'
                    );
                  }}
                >
                  termos de uso
                </Link>{' '}
                da plataforma
              </Typography>
            }
          />
        </FormGroup>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ minWidth: '213px' }}
          disabled={error ? true : undefined}
          loading={loading}
        >
          <Typography variant="button" color="white">
            Entrar
          </Typography>
        </Button>
      </form>

      <Link href="/forgot-password/" color="textSecondary">
        Esqueci minha Senha
      </Link>

      <div
        style={{
          position: 'fixed',
          zIndex: 8,
          height: 14,
          bottom: 0,
          width: '100%',
          backgroundColor: theme.palette.primary.main,
        }}
      ></div>
    </main>
  );
};
