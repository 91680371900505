import { commonFormFields, initField } from '../../utils';
import {
  FinancialAgency,
  FinancialAgencyFormType,
  TextFieldType,
  SelectFieldType,
  SelectOption,
} from '../../types';

export const initForm = ({
  data,
  isCreation = true,
}: {
  data?: FinancialAgency;
  isCreation?: boolean;
}) => {
  const form: FinancialAgencyFormType = {
    ...commonFormFields(data),
    Nome: initField<TextFieldType>({
      name: 'Nome',
      value: data?.nome ? data.nome : '',
      label: 'Nome/Razão Social',
      required: true,
    }),
    InstituicaoFinanceiraId: initField<SelectFieldType>({
      name: 'InstituicaoFinanceiraId',
      value: data?.instituicaoFinanceiraId
        ? ({ value: data.instituicaoFinanceiraId, label: '' } as SelectOption)
        : null,
      label: 'Segurado',
      required: true,
      extractValue: true,
    }),
    Senha: initField<TextFieldType>({
      name: 'Senha',
      value: '',
      type: 'password',
    }),
    ConfirmaSenha: initField<TextFieldType>({
      name: 'ConfirmaSenha',
      value: '',
      type: 'password',
      excludeFromPayload: true,
      label: 'Confirmar Senha',
    }),
    Endereco: initField<TextFieldType>({
      name: 'Endereco',
      value: '',
      label: 'Rua',
    }),
    NumeroEndereco: initField<TextFieldType>({
      name: 'NumeroEndereco',
      value: '',
      label: 'N°',
      type: 'number',
    }),
    Complemento: initField<TextFieldType>({ name: 'Complemento', value: '' }),
    Bairro: initField<TextFieldType>({ name: 'Bairro', value: '' }),
    Cidade: initField<TextFieldType>({
      name: 'Cidade',
      value: data?.cidade ? data.cidade : '',
    }),
  };

  if (isCreation) {
    form.Email = initField<TextFieldType>({
      name: 'Email',
      value: data?.email ? data.email : '',
      type: 'email',
      required: true,
    });
  }

  if (data?.agenciaId ?? false) {
    form.AgenciaId = initField<TextFieldType>({
      name: 'AgenciaId',
      value: data?.agenciaId as string,
    });
  }

  return form;
};
