import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

import { Bar, Doughnut } from 'react-chartjs-2';

import { useTheme } from '@mui/material/styles';
import { Layout } from '../../components/Layout';
import { SearchField } from '../../components/SearchField';

import { EmptyList } from '../../components/EmptyList';
import { CardWithDecal } from '../../components/CardWithDecal';
import {
  useAuth,
  useBroker,
  useCore,
  useFinancialInstitution,
} from '../../contexts';
import { ErrorMsg } from '../../components/ErrorMsg';
import { Loading } from '../../components/Loading';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MTextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { formatCurrency, getColor, filterValidKeys } from '../../utils';

import {
  AccountantFilter,
  DashboardDataType,
  SelectOption,
  Accountant,
} from '../../types';

export const Home = () => {
  const theme = useTheme();
  const { DashboardData } = useCore();
  const { getFinancialInstitutionsOptions, financialInstitutionsOptions } =
    useFinancialInstitution();
  const { getBrokersOptions, brokersOptions } = useBroker();
  const { user } = useAuth();

  const {
    handleGetDashboardData,
    dashboardData,
    dashboardDataError,
    dashboardDataLoading,
  } = DashboardData;

  const [filteredDashboardData, setFilteredDashboardData] = useState<
    DashboardDataType | null | undefined
  >(dashboardData);

  const dashboardDataCount = filteredDashboardData?.length ?? -1;

  const [filters, setFilters] = React.useState<AccountantFilter>({
    InstituicaoFinanceiraId: null,
    CorretoraId: null,
    DataInicio: null,
    DataFinal: null,
  });

  const filtersEnabled =
    Object.entries(filterValidKeys(filters ?? {})).length > 0;

  const labels = (dashboardData?.map((item) => item.nomeCultura) ?? []).filter(
    (v, i, a) => a.indexOf(v) === i
  );

  const handleFilter = (filter?: AccountantFilter) => {
    if (typeof filter !== 'undefined') setFilters(filter);
    const validFilters = filterValidKeys(
      filter ?? filters ?? {}
    ) as AccountantFilter;
    let auxDashboardData = dashboardData;
    for (const [key, value] of Object.entries(validFilters)) {
      switch (key) {
        case 'DataInicio':
          auxDashboardData = auxDashboardData?.filter((item: Accountant) => {
            const auxValue = value as Dayjs;
            return dayjs(item.dataCriacao, 'DD/MM/YYYY').isSameOrAfter(
              auxValue
            );
          });
          break;
        case 'DataFinal':
          auxDashboardData = auxDashboardData?.filter((item: Accountant) => {
            const auxValue = value as Dayjs;
            return dayjs(item.dataCriacao, 'DD/MM/YYYY').isSameOrBefore(
              auxValue
            );
          });
          break;
        case 'InstituicaoFinanceiraId':
          auxDashboardData = auxDashboardData?.filter((item: Accountant) => {
            const auxValue = value as SelectOption;
            return (
              item.instituicaoFinanceiraId?.toString() ===
              (auxValue?.value ?? null)
            );
          });
          break;
        case 'CorretoraId':
          auxDashboardData = auxDashboardData?.filter((item: Accountant) => {
            const auxValue = value as SelectOption;
            return item.corretoraId?.toString() === auxValue?.value ?? null;
          });
          break;
      }
    }
    setFilteredDashboardData(auxDashboardData);
  };

  const handleClearFilters = () => {
    setFilters({
      InstituicaoFinanceiraId: null,
      CorretoraId: null,
      DataFinal: null,
      DataInicio: null,
    });
    setFilteredDashboardData(dashboardData);
  };

  useEffect(() => {
    handleFilter();
  }, [dashboardData]);

  useEffect(() => {
    getFinancialInstitutionsOptions();
    getBrokersOptions();
    handleGetDashboardData();
  }, []);

  const stateLabels =
    filteredDashboardData
      ?.map((item) => item.nomeEstado)
      .filter((v, i, a) => a.indexOf(v) === i) ?? [];

  const stateData = stateLabels
    .map((state) =>
      filteredDashboardData
        ?.filter((item) => item.nomeEstado === state)
        ?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.premio);
        }, 0)
    )
    .sort((a?: number, b?: number) => (b ?? 0) - (a ?? 0));

  const stateColors = (
    [
      theme.palette.primary.light,
      theme.palette.success.light,
      theme.palette.info.light,
    ] as Array<string>
  ).concat(stateData.map(() => getColor()) ?? []);

  const cityLabels =
    filteredDashboardData
      ?.map((item) => item.nomeCidade)
      .filter((v, i, a) => a.indexOf(v) === i) ?? [];

  const cityData = cityLabels
    .map((city) =>
      filteredDashboardData
        ?.filter((item) => item.nomeCidade === city)
        ?.reduce((accumulator, object) => {
          return accumulator + parseFloat(object.premio);
        }, 0)
    )
    .sort((a?: number, b?: number) => (b ?? 0) - (a ?? 0));

  const cityColors = (
    [
      theme.palette.primary.light,
      theme.palette.success.light,
      theme.palette.info.light,
    ] as Array<string>
  ).concat(cityData.map(() => getColor()) ?? []);

  return (
    <Layout>
      {dashboardDataError ? (
        <ErrorMsg error={dashboardDataError} />
      ) : (
        <>
          {dashboardDataLoading ? (
            <Loading />
          ) : (
            <Grid container>
              <Grid item md={4} lg={2}></Grid>
              <Grid item md={8} lg={10}>
                <Typography
                  variant="h4"
                  component="h2"
                  fontWeight="bold"
                  color="primary"
                  marginBottom={theme.spacing(2)}
                >
                  Inicio
                </Typography>
              </Grid>
              {['admin', 'corretora'].includes(user.role) && (
                <Grid item md={4} lg={2}>
                  <Box
                    sx={{
                      marginBottom: theme.spacing(2),
                      display: 'flex',
                      paddingRight: theme.spacing(2),
                      gap: theme.spacing(2),
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <SearchField
                        extraAttrs={{
                          value: filters.InstituicaoFinanceiraId,
                        }}
                        options={financialInstitutionsOptions}
                        label="Filtrar por Segurado"
                        required={false}
                        loading={dashboardDataLoading}
                        handleChange={(value: SelectOption) => {
                          handleFilter({
                            ...filters,
                            InstituicaoFinanceiraId: value,
                          });
                        }}
                      />
                    </Box>
                    {user.role === 'admin' && (
                      <Box sx={{ width: '100%' }}>
                        <SearchField
                          extraAttrs={{
                            value: filters.CorretoraId,
                          }}
                          options={brokersOptions}
                          label="Filtrar por Corretora"
                          required={false}
                          loading={dashboardDataLoading}
                          handleChange={(value: SelectOption) => {
                            handleFilter({
                              ...filters,
                              CorretoraId: value,
                            });
                          }}
                        />
                      </Box>
                    )}

                    <DesktopDatePicker
                      label="Data de Inicio"
                      inputFormat="DD/MM/YYYY"
                      disabled={dashboardDataLoading}
                      value={filters.DataInicio}
                      onChange={(newValue) => {
                        handleFilter({ ...filters, DataInicio: newValue });
                      }}
                      renderInput={(params) => (
                        <MTextField
                          {...params}
                          size="small"
                          style={{ width: 300 }}
                        />
                      )}
                    />
                    <DesktopDatePicker
                      label="Data de Final"
                      inputFormat="DD/MM/YYYY"
                      disabled={dashboardDataLoading}
                      value={filters.DataFinal}
                      onChange={(newValue) => {
                        handleFilter({ ...filters, DataFinal: newValue });
                      }}
                      renderInput={(params) => (
                        <MTextField
                          {...params}
                          size="small"
                          style={{ width: 300 }}
                        />
                      )}
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={handleClearFilters}
                      disabled={dashboardDataLoading}
                    >
                      <Typography
                        variant="button"
                        color="primary"
                        style={{
                          display: 'flex',
                          gap: theme.spacing(1),
                        }}
                      >
                        Limpar Filtros
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              )}
              <Grid item md={8} lg={10}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(2),
                    flexWrap: 'wrap',
                  }}
                >
                  <CardWithDecal
                    title="Valor em Prêmios"
                    color={theme.palette.primary.main}
                    content={`R$${formatCurrency(
                      (
                        filteredDashboardData?.reduce((accumulator, object) => {
                          return accumulator + parseFloat(object.premio);
                        }, 0) ?? 0
                      )
                        .toFixed(2)
                        .replace('.', ',')
                    )}`}
                  />
                  <CardWithDecal
                    title="Importância Segurada Total"
                    color={theme.palette.info.light}
                    content={`R$${formatCurrency(
                      (
                        filteredDashboardData?.reduce((accumulator, object) => {
                          return (
                            accumulator +
                            parseFloat(object.importanciaSeguradaTotal)
                          );
                        }, 0) ?? 0
                      )
                        .toFixed(2)
                        .replace('.', ',')
                    )}`}
                  />
                  <CardWithDecal
                    title="Aceite pela Seguradora"
                    color={theme.palette.success.main}
                    content={(
                      filteredDashboardData?.filter(
                        (item) => item.propostaAceita === true
                      ).length ?? 0
                    ).toString()}
                  />
                  <CardWithDecal
                    title="Propostas Enviadas"
                    color={theme.palette.info.dark}
                    content={(
                      filteredDashboardData?.filter(
                        (item) => item.propostaEnviada === true
                      ).length ?? 0
                    ).toString()}
                  />
                  <CardWithDecal
                    title="Propostas em Análise"
                    color={theme.palette.error.main}
                    content={(
                      filteredDashboardData?.filter(
                        (item) => item.propostaAceita === false
                      ).length ?? 0
                    ).toString()}
                  />
                  <CardWithDecal
                    title="Aceite pelo Cliente"
                    color={theme.palette.warning.main}
                    content={(
                      filteredDashboardData?.filter(
                        (item) => item.aceiteCliente === true
                      ).length ?? 0
                    ).toString()}
                  />
                </Box>
                {dashboardDataCount === 0 &&
                  (filtersEnabled ? (
                    <EmptyList
                      img={false}
                      justifyContent="flex-start"
                      title={
                        filtersEnabled
                          ? 'Nenhum registro com esse filtro'
                          : 'Nenhuma proposta cadastrada =('
                      }
                      content=""
                    />
                  ) : (
                    <EmptyList />
                  ))}
                {dashboardDataCount > 0 && (
                  <Grid container marginTop={theme.spacing(3)} spacing={3}>
                    <Grid item lg={6}>
                      <Paper
                        elevation={2}
                        sx={{
                          padding: theme.spacing(4),
                        }}
                      >
                        <Typography
                          marginBottom={theme.spacing(2)}
                          variant="h6"
                          fontWeight="bold"
                          color="textSecondary"
                        >
                          Prêmio por Cultura
                        </Typography>
                        <Bar
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                              title: {
                                display: false,
                              },
                              tooltip: {
                                callbacks: {
                                  label: (item) => {
                                    return `R$${formatCurrency(
                                      (
                                        parseFloat(item.raw as string).toFixed(
                                          2
                                        ) ?? '0'
                                      ).replace('.', ',')
                                    )}`;
                                  },
                                },
                              },
                            },
                            scales: {
                              yAxes: {
                                ticks: {
                                  callback: (tickValue) => {
                                    return 'R$' + tickValue;
                                  },
                                },
                              },
                            },
                          }}
                          data={{
                            labels,
                            datasets:
                              [
                                {
                                  label: 'Valor em Prêmios',
                                  backgroundColor: theme.palette.primary.light,
                                  data: labels.map((cultura) => {
                                    return filteredDashboardData
                                      ?.filter(
                                        (item) => item.nomeCultura === cultura
                                      )
                                      .reduce((accumulator, object) => {
                                        return (
                                          accumulator +
                                          parseFloat(object.premio)
                                        );
                                      }, 0);
                                  }),
                                },
                              ] ?? [],
                          }}
                        />
                      </Paper>
                    </Grid>
                    <Grid item lg={3}>
                      <Paper
                        sx={{
                          padding: theme.spacing(4),
                        }}
                      >
                        <Typography
                          marginBottom={theme.spacing(2)}
                          variant="h6"
                          fontWeight="bold"
                          color="textSecondary"
                        >
                          Valor em Prêmios por Estado
                        </Typography>
                        <Doughnut
                          data={{
                            labels: stateLabels,
                            datasets: [
                              {
                                label: 'Prêmio por Estado',
                                data: stateData,
                                backgroundColor: stateColors,
                                borderWidth: 1,
                              },
                            ],
                          }}
                        />
                      </Paper>
                    </Grid>
                    <Grid item lg={3}>
                      <Paper
                        sx={{
                          padding: theme.spacing(4),
                        }}
                      >
                        <Typography
                          marginBottom={theme.spacing(2)}
                          variant="h6"
                          fontWeight="bold"
                          color="textSecondary"
                        >
                          Valor em Prêmios por Cidade
                        </Typography>
                        <Doughnut
                          data={{
                            labels: cityLabels,
                            datasets: [
                              {
                                label: 'Prêmio por Cidade',
                                data: cityData,
                                backgroundColor: cityColors,
                                borderWidth: 1,
                              },
                            ],
                          }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Layout>
  );
};
