import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Add from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { useProduct, useProductHistory } from '../../contexts';
import IconButton from '@mui/material/IconButton';
import { DeleteDialog } from '../../components/DeleteDialog';
import { Loading } from '../../components/Loading';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Product, SubProduct } from '../../types';
import { Paper } from '@mui/material';
import EnvironmentIaus from '../../assets/environment_iaus.svg';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ProductDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    product,
    setProduct,
    productApi,
    productLoading,
    productError,
    products,
    setProducts,
    getProduct,
  } = useProduct();

  const { productHistoryByProduct, getProductHistoryByProductId } =
    useProductHistory();

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const [subProductDeleteOpen, setSubProductDeleteOpen] =
    useState<boolean>(false);
  const [subProductDeleteId, setSubProductDeleteId] = useState<string>();
  const [subProductDeleteName, setSubProductDeleteName] = useState<string>();

  useEffect(() => {
    getProduct(id ?? '');
    getProductHistoryByProductId(id ?? '');
  }, []);

  const handleDelete = async () => {
    await productApi.deleteProduct(deleteId as string);
    if (products ?? false) {
      setProducts(
        (products ?? []).filter(
          (product: Product) => product.produtoId !== deleteId
        )
      );
    }
    navigate('/products/');
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  const handleDeleteSubProduct = async () => {
    await productApi.deleteSubProduct(subProductDeleteId as string);
    if (product ?? false) {
      setProduct({
        ...product,
        subProduto: (product.subProduto ?? []).filter(
          (subProduct: SubProduct) =>
            subProduct?.subProdutoId !== subProductDeleteId
        ),
      });
    }
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  return (
    <Layout>
      {productLoading && <Loading />}
      {!productLoading && productError && <ErrorMsg error={productError} />}
      {!productError && !productLoading && (
        <>
          <Breadcrumb
            items={[{ href: '/products/', name: 'produtos' }]}
            activeItem={product?.nome ?? ''}
            previousRoute="/products/"
          />
          <Typography
            variant="h2"
            component="h2"
            color="primary"
            fontWeight="bold"
          >
            {product?.nome ?? ''}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
          >
            {product?.descricao}
          </Typography>
          <Box
            sx={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(4),
              display: 'flex',
              gap: theme.spacing(1),
            }}
          >
            <Button
              variant="contained"
              color="primary"
              component={Link}
              href={`/products/edit/${product?.produtoId}/`}
            >
              <Typography
                variant="button"
                color="white"
                style={{
                  display: 'flex',
                  gap: theme.spacing(1),
                }}
              >
                <EditIcon />
                Editar
              </Typography>
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setDeleteOpen(true);
                setDeleteId(product.produtoId);
                setDeleteName(product.nome);
              }}
            >
              <DeleteIcon />
              Excluir
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: theme.spacing(4),
              flexWrap: 'wrap',
            }}
          >
            <Box>
              {product?.instituicaoFinanceiraNome && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <AccountBalanceIcon
                    style={{
                      color: theme.palette.primary.main,
                      marginRight: theme.spacing(1),
                    }}
                  />

                  <Typography variant="body1" color="textSecondary">
                    {product?.instituicaoFinanceiraNome}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  marginTop: theme.spacing(3),
                }}
              >
                <RequestQuoteIcon
                  style={{
                    color: theme.palette.primary.main,
                    marginRight: theme.spacing(1),
                  }}
                />
                <Typography variant="body1" color="textSecondary">
                  {product?.seguradoraNome}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" color="primary" fontWeight="bold">
                Nível de Cobertura
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color="primary"
                fontWeight="bold"
                fontSize={60}
                sx={{
                  marginTop: '-20px',
                  display: 'inline-block',
                }}
              >
                {product?.nivelDeCobertura}%
              </Typography>
              <Box sx={{ display: 'flex', gap: theme.spacing(1) }}>
                {product?.ativo ? (
                  <Chip
                    label="✓ Ativo"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Chip
                    label="X Inativo"
                    color="secondary"
                    variant="outlined"
                    size="small"
                  />
                )}

                {product?.nivelDeCoberturaVariavel ? (
                  <Chip
                    label="✓ Cobertura Variável"
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  <Chip
                    label="X Cobertura Invariável"
                    color="secondary"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <img
                src={EnvironmentIaus}
                style={{ height: '120px' }}
                alt="Ícone de Produo"
              />
            </Box>
          </Box>
          <Box style={{ marginTop: theme.spacing(3) }}>
            <Typography
              variant="subtitle1"
              component="p"
              color="textSecondary"
              fontWeight="bold"
            >
              Municípios do Programa
            </Typography>
            <Box
              style={{
                marginTop: theme.spacing(1),
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: theme.spacing(2),
              }}
            >
              {(product?.subProduto ?? []).length > 0 && (
                <>
                  {(product?.subProduto ?? []).map(
                    (subProduct: SubProduct, index: number) => (
                      <Card
                        key={subProduct?.subProdutoId ?? index}
                        sx={{
                          minWidth: 275,
                        }}
                      >
                        <CardContent>
                          <Typography
                            sx={{
                              fontSize: 14,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <FlagIcon sx={{ marginRight: theme.spacing(1) }} />
                            {subProduct.estado}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 14,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            color="text.secondary"
                            gutterBottom
                          >
                            <LocationCityIcon
                              sx={{ marginRight: theme.spacing(1) }}
                            />
                            {subProduct.cidade}
                          </Typography>
                          {productHistoryByProduct ? (
                            <Box sx={{ marginTop: theme.spacing(2) }}>
                              <div
                                style={{
                                  minWidth: 300,
                                }}
                              >
                                <Bar
                                  options={{
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                      title: {
                                        display: false,
                                      },
                                      tooltip: {
                                        callbacks: {
                                          label: (item) => {
                                            return `%${item.raw}`;
                                          },
                                        },
                                      },
                                    },
                                    scales: {
                                      yAxes: {
                                        ticks: {
                                          callback: (tickValue) => {
                                            return '%' + tickValue;
                                          },
                                        },
                                      },
                                    },
                                  }}
                                  data={{
                                    labels: productHistoryByProduct
                                      .filter((item) => {
                                        return (
                                          item?.subProdutoId ===
                                          subProduct.subProdutoId
                                        );
                                      })
                                      .map((item) => item.ano),
                                    datasets:
                                      [
                                        {
                                          label: 'Histórico',
                                          backgroundColor:
                                            theme.palette.primary.light,
                                          data: productHistoryByProduct
                                            .filter((item) => {
                                              return (
                                                item?.subProdutoId ===
                                                subProduct.subProdutoId
                                              );
                                            })
                                            .map((item) => item.valor),
                                        },
                                      ] ?? [],
                                  }}
                                />
                              </div>
                            </Box>
                          ) : (
                            <Loading />
                          )}
                        </CardContent>
                        <CardActions>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: theme.spacing(1),
                              marginTop: theme.spacing(1),
                              marginBottom: theme.spacing(1),
                              justifyContent: 'center',
                              width: '100%',
                            }}
                          >
                            {/* <IconButton
                              sx={{
                                background: green[100],
                                color: theme.palette.primary.main,
                              }}
                              aria-label="Editar"
                              LinkComponent={Link}
                              href={`/products/subproduct/${product.produtoId}/edit/`}
                            >
                              <EditIcon sx={{ fontSize: 14 }} />
                            </IconButton> */}
                            <IconButton
                              sx={{ background: red[100] }}
                              color="secondary"
                              aria-label="Excluir"
                              onClick={() => {
                                setSubProductDeleteOpen(true);
                                setSubProductDeleteId(subProduct.subProdutoId);
                                setSubProductDeleteName(
                                  `O Sub Produto ${subProduct.estado} - ${subProduct.cidade}`
                                );
                              }}
                            >
                              <CloseIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                          </Box>
                        </CardActions>
                      </Card>
                    )
                  )}
                </>
              )}

              <Paper
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'solid 1px ' + theme.palette.primary.main,
                }}
              >
                <Button
                  LinkComponent={Link}
                  href={`/products/subproduct/${product?.produtoId}/create/?next=${window.location.pathname}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: theme.spacing(3),
                    minHeight: 180,
                    minWidth: 300,
                  }}
                >
                  <Add color="primary" sx={{ fontSize: 32 }} />
                  <Typography
                    sx={{ fontSize: 14, textAlign: 'center' }}
                    color="primary"
                    gutterBottom
                  >
                    Adicionar Subproduto
                  </Typography>
                </Button>
              </Paper>
            </Box>
          </Box>
          <DeleteDialog
            open={deleteOpen}
            name={deleteName}
            setOpen={setDeleteOpen}
            handleDelete={handleDelete}
          />
          <DeleteDialog
            open={subProductDeleteOpen}
            name={subProductDeleteName}
            setOpen={setSubProductDeleteOpen}
            handleDelete={handleDeleteSubProduct}
          />
        </>
      )}
    </Layout>
  );
};
