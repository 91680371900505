import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { styled, useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import MTextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Add from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { Loading } from '../../components/Loading';
import { SearchField } from '../../components/SearchField';
import { handleErrorMessage, serializePayload } from '../../utils';
import {
  FullProductPayload,
  FullProductFormType,
  Product,
  SelectOption,
} from '../../types';
import {
  useCore,
  useFinancialInstitution,
  useInsuranceCompany,
  useProduct,
} from '../../contexts/contexts';

import { Form } from './styled';

import { AuthorizationCheckbox } from '../../components/FormCheckboxes';

import { initForm } from './utils';
import { InputAdornment } from '@mui/material';

const HalfedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
}));

export const FullProductForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [error, setError] = useState<string[] | string>();
  const [loading, setLoading] = useState<boolean>(false); // eslint-disable-next-line
  const [product, setProduct] = useState<Product | undefined>();
  const { products, setProducts, productApi } = useProduct();
  const { id } = useParams();

  const { stateOptions, cityOptions, fetchCityOptions } = useCore();

  const { insuranceCompanyApi } = useInsuranceCompany();
  const { financialInstitutionApi } = useFinancialInstitution();

  const [financialInstitutionsOptions, setFinancialInstitutionsOptions] =
    useState<Array<SelectOption>>();

  const [insuranceCompaniesOptions, setInsuranceCompaniesOptions] =
    useState<Array<SelectOption>>();

  const [fetchingProduct, setFetchingProduct] = useState<boolean>(!isCreation);
  const [fetchingError, setFetchingError] = useState<string>();

  const [form, setForm] = useState<FullProductFormType>(
    initForm({}) as FullProductFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof FullProductFormType];
    auxForm[e.target.name as keyof FullProductFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleDateChange = (
    fieldName: keyof FullProductFormType,
    newValue: Dayjs | null
  ) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[fieldName as keyof FullProductFormType];
    auxForm[fieldName] = {
      ...field,
      value: newValue,
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = serializePayload<FullProductFormType, FullProductPayload>(
        form
      ) as FullProductPayload;
      const data = await productApi.addFullProduct(payload);
      if ((products?.length ?? -1) > 0)
        setProducts((products ?? []).concat([data]));
      navigate('/products');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const getProduct = async () => {
    if (id ?? false) {
      setFetchingError(undefined);
      try {
        setFetchingProduct(true);
        const data: Product = await productApi.getProductById(id as string);
        setForm(initForm({}));
        setProduct(data);
      } catch (e: unknown) {
        setFetchingError(handleErrorMessage(e));
      } finally {
        setFetchingProduct(false);
      }
    }
  };

  const getFinancialInstitutionsOptions = async () => {
    try {
      setLoading(true);
      const data = await financialInstitutionApi.getFinancialInstitutions();
      setFinancialInstitutionsOptions(
        data.map(
          (financialInstitution) =>
            ({
              label: financialInstitution.nome,
              value: financialInstitution.instituicaoFinanceiraId,
            } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const getInsuranceCompaniesOptions = async () => {
    try {
      setLoading(true);
      const data = await insuranceCompanyApi.getInsuranceCompanies();
      setInsuranceCompaniesOptions(
        data.map(
          (insuranceCompanies) =>
            ({
              label: insuranceCompanies.nome,
              value: insuranceCompanies.seguradoraId,
            } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
    getInsuranceCompaniesOptions();
    getFinancialInstitutionsOptions();
  }, []);

  useEffect(() => {
    if (
      (financialInstitutionsOptions?.length ?? -1) > 0 &&
      (product ?? false)
    ) {
      const financialInstitution = financialInstitutionsOptions?.filter(
        (option) => {
          return option?.value === product?.instituicaoFinanceiraId;
        }
      );
      if ((financialInstitution?.length ?? -1) > 0) {
        setForm({
          ...form,
          InstituicaoFinanceiraId: {
            ...form.InstituicaoFinanceiraId,
            value: (financialInstitution ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [financialInstitutionsOptions, product]);

  useEffect(() => {
    if ((insuranceCompaniesOptions?.length ?? -1) > 0 && (product ?? false)) {
      const insuranceCompany = insuranceCompaniesOptions?.filter((option) => {
        return option?.value === product?.seguradoraId;
      });
      if ((insuranceCompany?.length ?? -1) > 0) {
        setForm({
          ...form,
          SeguradoraId: {
            ...form.SeguradoraId,
            value: (insuranceCompany ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [insuranceCompaniesOptions, product]);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {fetchingError && <ErrorMsg error={fetchingError} />}

        {fetchingProduct && <Loading />}
        {!fetchingError && !fetchingProduct && (
          <>
            <Typography variant="h5" component="h2" color="textSecondary">
              {isCreation && (
                <>
                  Cadastro de Produto{' '}
                  <Button
                    LinkComponent={Link}
                    href={`/products/create/`}
                    variant="contained"
                    style={{
                      marginLeft: theme.spacing(1),
                      color: theme.palette.common.white,
                    }}
                    startIcon={
                      <Add style={{ color: theme.palette.common.white }} />
                    }
                  >
                    <Typography variant="button" color="white">
                      Cadastro Normal
                    </Typography>
                  </Button>
                </>
              )}
            </Typography>
            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                gap: theme.spacing(2),
                flexDirection: 'column',
              }}
            >
              <ErrorMsg error={error} />
              <TextField field={form.Nome} extraAttrs={extraAttrs} />
              <TextField field={form.Descricao} extraAttrs={extraAttrs} />
              <HalfedBox>
                <SearchField
                  extraAttrs={{ value: form.InstituicaoFinanceiraId.value }}
                  options={financialInstitutionsOptions}
                  label={form.InstituicaoFinanceiraId.label}
                  required={
                    form.InstituicaoFinanceiraId.required ? true : false
                  }
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      InstituicaoFinanceiraId: {
                        ...form.InstituicaoFinanceiraId,
                        value,
                      },
                    });
                  }}
                />
                <SearchField
                  extraAttrs={{ value: form.SeguradoraId.value }}
                  options={insuranceCompaniesOptions}
                  label={form.SeguradoraId.label}
                  required={form.SeguradoraId.required ? true : false}
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      SeguradoraId: {
                        ...form.SeguradoraId,
                        value,
                      },
                    });
                  }}
                />
              </HalfedBox>
              <HalfedBox>
                <SearchField
                  extraAttrs={{ value: form.EstadoId.value }}
                  options={stateOptions}
                  label={form.EstadoId.label}
                  id={form.EstadoId.id}
                  required={form.EstadoId.required ? true : false}
                  loading={loading}
                  handleChange={(option: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      CidadeId: {
                        ...form.CidadeId,
                        value: null,
                      },
                      EstadoId: {
                        ...form.EstadoId,
                        value: option,
                      },
                    });
                    fetchCityOptions(option?.value ?? '');
                  }}
                />

                <SearchField
                  extraAttrs={{ value: form.CidadeId.value }}
                  options={cityOptions}
                  label={form.CidadeId.label}
                  id={form.CidadeId.id}
                  required={form.CidadeId.required ? true : false}
                  loading={loading}
                  handleChange={(option: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      CidadeId: {
                        ...form.CidadeId,
                        value: option,
                      },
                    });
                  }}
                />
              </HalfedBox>
              <TextField
                field={form.NivelDeCobertura}
                extraAttrs={extraAttrs}
              />
              <HalfedBox>
                <TextField field={form.Taxa} extraAttrs={extraAttrs} />
                <TextField
                  field={form.CoberturaMaxima}
                  extraAttrs={{...extraAttrs,
                    InputProps: {
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }
                  }}
                />
              </HalfedBox>
              <HalfedBox>
                <DesktopDatePicker
                  label={form.DataInicioGatilho1.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataInicioGatilho1.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataInicioGatilho1.name as keyof FullProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
                <DesktopDatePicker
                  label={form.DataFinalGatilho1.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataFinalGatilho1.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataFinalGatilho1.name as keyof FullProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
              </HalfedBox>
              <HalfedBox>
                <DesktopDatePicker
                  label={form.DataInicioGatilho2.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataInicioGatilho2.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataInicioGatilho2.name as keyof FullProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
                <DesktopDatePicker
                  label={form.DataFinalGatilho2.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataFinalGatilho2.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataFinalGatilho2.name as keyof FullProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
              </HalfedBox>
              <HalfedBox>
                <DesktopDatePicker
                  label={form.DataInicioGatilho3.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataInicioGatilho3.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataInicioGatilho3.name as keyof FullProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
                <DesktopDatePicker
                  label={form.DataFinalGatilho3.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataFinalGatilho3.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataFinalGatilho3.name as keyof FullProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.Strike1Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike2Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike3Gatilho1}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.Strike1Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike2Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike3Gatilho2}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.Strike1Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike2Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike3Gatilho3}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.IndenizacaoStrike1Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike2Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike3Gatilho1}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>

              <HalfedBox>
                <TextField
                  field={form.IndenizacaoStrike1Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike2Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike3Gatilho2}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.IndenizacaoStrike1Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike2Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike3Gatilho3}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FormGroup style={{ display: 'flex' }}>
                  <FormControlLabel
                    sx={{
                      maxWidth: 618,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.PagamentoLinear.name}
                        onChange={handleChange}
                        checked={form.PagamentoLinear.value}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.PagamentoLinear.label}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    display: 'flex',
                  }}
                >
                  <FormControlLabel
                    sx={{
                      maxWidth: 618,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.NivelDeCoberturaVariavel.label}
                        onChange={handleChange}
                        checked={form.NivelDeCoberturaVariavel.value}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.NivelDeCoberturaVariavel.label}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>
              {!isCreation && (
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  sx={{
                    marginBottom: theme.spacing(3),
                    marginTop: theme.spacing(2),
                  }}
                >
                  <FormGroup style={{ display: 'flex' }}>
                    <AuthorizationCheckbox />
                  </FormGroup>
                </Grid>
              )}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={loading}
                  disabled={error ? true : undefined}
                  endIcon={
                    <ArrowForwardIcon
                      sx={{ color: theme.palette.common.white }}
                    />
                  }
                >
                  <Typography variant="button" color="white">
                    {isCreation ? 'Cadastrar' : 'Salvar'}
                  </Typography>
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Grid>
    </Layout>
  );
};
