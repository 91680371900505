import * as React from 'react';
// import useMediaQuery from '@mui/material/useMediaQuery';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { useTheme } from '@mui/material/styles';
import { useAuth, useUi } from '../../contexts';

import hlogo from '../../assets/hlogo.png';
import { Button } from '@mui/material';

export const AppBar = () => {
  const theme = useTheme();
  const { sidebarOpen, setSidebarOpen } = useUi();
  const { logout, isAuthenticated, user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleAvatarMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAvatarMenu = () => {
    setAnchorEl(null);
  };

  const desktopLayout = false; //useMediaQuery('(min-width:1200px)');

  return (
    <Box sx={{ flexGrow: 1, zIndex: 64, position: 'relative' }}>
      <MuiAppBar
        position="static"
        color="inherit"
        sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)' }}
      >
        <Toolbar sx={{ color: theme.palette.text.secondary }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: theme.spacing(10),
              width: '100%',
            }}
          >
            <Link href="/">
              <img src={hlogo} alt="Field Tech Logo" height={30} />
            </Link>
            {desktopLayout && (
              <ul
                style={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                }}
              >
                <li>
                  <Link href="/" color="textSecondary">
                    Inicio
                  </Link>
                </li>
                <li>
                  <Link href="/customers/" color="textSecondary">
                    Clientes
                  </Link>
                </li>
                <li>
                  <Link href="/brokers/" color="textSecondary">
                    Corretoras
                  </Link>
                </li>
                <li>
                  <Link href="/brokers/" color="textSecondary">
                    Segurados
                  </Link>
                </li>
                <li>
                  <Link href="/brokers/" color="textSecondary">
                    Agências
                  </Link>
                </li>
                <li>
                  <Link href="/brokers/" color="textSecondary">
                    Seguradoras
                  </Link>
                </li>
                <li>
                  <Link href="/brokers/" color="textSecondary">
                    Culturas
                  </Link>
                </li>
              </ul>
            )}
          </Box>

          {isAuthenticated && (
            <div>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {user?.imageUrl ? (
                  <img
                    style={{
                      borderRadius: user?.imageUrl ? theme.spacing(2) : 0,
                    }}
                    src={user?.imageUrl ?? ''}
                    alt={user.nome}
                    height="28"
                  />
                ) : (
                  <AccountCircle
                    sx={{
                      fontSize: 28,
                    }}
                  />
                )}

                <Button
                  size="large"
                  variant="text"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleAvatarMenu}
                  color="inherit"
                  sx={{
                    textTransform: 'capitalize',
                    padding: `0 ${theme.spacing(1.6)}`,
                    fontSize: theme.spacing(2.2),
                    fontWeight: 'bold',
                  }}
                >
                  {user?.nome}
                  <ArrowDropDown />
                </Button>
              </Box>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseAvatarMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem>
                  <Link href="/change-password/">Mudar Senha</Link>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{
                      textTransform: 'capitalize',
                      fontWeight: 'regular',
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                    }}
                  >
                    <PowerSettingsNewIcon fontSize="inherit" color="inherit" />
                    Sair
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
          )}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 2 }}
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
};
