import React, { createContext, useState, useContext } from 'react';

import CoreService from '../services/CoreService';
import { handleErrorMessage } from '../utils';

import {
  SelectOption,
  State,
  City,
  Address,
  DashboardDataType,
} from '../types';
import { useAuth } from './AuthProvider';

export type CoreContextType = {
  stateOptions: Array<SelectOption>;
  setStateOptions: React.Dispatch<React.SetStateAction<Array<SelectOption>>>;
  stateLoading: boolean;
  setStateLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stateError: string | undefined;
  setStateError: React.Dispatch<React.SetStateAction<string | undefined>>;
  cityOptions: Array<SelectOption> | undefined;
  setCityOptions: React.Dispatch<React.SetStateAction<Array<SelectOption>>>;
  cityLoading: boolean;
  setCityLoading: React.Dispatch<React.SetStateAction<boolean>>;
  cityError: string | undefined;
  setCityError: React.Dispatch<React.SetStateAction<string | undefined>>;
  fetchStateOptions(): Promise<SelectOption[]>;
  getCity(cityId: string): Promise<City | null>;
  fetchCityOptions(ufId: string): Promise<SelectOption[]>; // eslint-disable-next-line
  fetchCepData(cep: string, form: any, setForm: any): void;
  coreApi: CoreService;
  DashboardData: {
    handleGetDashboardData(): Promise<void>;
    dashboardData: DashboardDataType | undefined;
    dashboardDataError: string | undefined;
    dashboardDataLoading: boolean;
    dashboardDataCount: number;
  };
};

export const CoreContext = createContext<CoreContextType>(
  {} as unknown as CoreContextType
);

export const CoreProvider = ({ children }: { children?: React.ReactNode }) => {
  const { token } = useAuth();
  const [stateOptions, setStateOptions] = useState<Array<SelectOption>>();
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const [stateError, setStateError] = useState<string>();
  const [cityOptions, setCityOptions] = useState<Array<SelectOption>>();
  const [cityLoading, setCityLoading] = useState<boolean>(false);
  const [cityError, setCityError] = useState<string>();

  const [dashboardData, setDashboardData] = useState<DashboardDataType>();
  const [dashboardDataError, setDashboardDataError] = useState<string>();
  const [dashboardDataLoading, setDashboardDataLoading] =
    useState<boolean>(false);

  const dashboardDataCount = dashboardData?.length ?? -1;

  const coreApi = new CoreService({
    basePath: '/v1',
    token,
  });

  const fetchStateOptions = async () => {
    let options: SelectOption[] = [];
    try {
      setStateLoading(true);
      const data = await coreApi.getStates();
      options = data.map(
        (state: State) =>
          ({
            value: state.estadoId.toString(),
            label: state.sigla,
          } as SelectOption)
      );
      setStateOptions(options);
    } catch (error: unknown) {
      setStateError(handleErrorMessage(error));
    } finally {
      setStateLoading(false);
    }
    return options;
  };

  const fetchCityOptions = async (ufId: string) => {
    let options: SelectOption[] = [];
    if (ufId) {
      try {
        setCityLoading(true);
        const data = await coreApi.getCitiesByStateId(ufId);
        options = data.map(
          (city: City) =>
            ({
              label: city.nome,
              value: city.cidadeId.toString(),
            } as SelectOption)
        );
        setCityOptions(options);
      } catch (error: unknown) {
        setCityError(handleErrorMessage(error));
      } finally {
        setCityLoading(false);
      }
    }
    return options;
  };

  const getCity = async (cityId: string) => {
    if (cityId) {
      try {
        setCityLoading(true);
        return await coreApi.getCity(cityId);
      } catch (error: unknown) {
        setCityError(handleErrorMessage(error));
      } finally {
        setCityLoading(false);
      }
    }
    return null;
  };

  // eslint-disable-next-line
  const fetchCepData = async (cep: string, form: any, setForm: any) => {
    // todo: fix any
    let address: Address | null = null;
    if (cep.length === 8) {
      try {
        const data = await coreApi.getCepData(cep);
        address = data;
      } catch (e) {
        console.info(e);
      }
    }
    if (address ?? false) {
      setForm({
        ...form,
        Bairro: {
          ...form.Bairro,
          value: address?.bairro ?? '',
        },
        Cidade: {
          ...form.Cidade,
          value: address?.localidade ?? '',
        },
        UF: {
          ...form.UF,
          value: address?.uf ?? '',
        },
        Endereco: {
          ...form.Endereco,
          value: address?.logradouro ?? '',
        },
        Complemento: {
          ...form.Complemento,
          value: address?.complemento ?? '',
        },
      });
    }
  };
  const handleGetDashboardData = async () => {
    try {
      setDashboardDataError(undefined);
      setDashboardDataLoading(true);
      const data = await coreApi.getDashboardData();
      setDashboardData(data);
    } catch (e: unknown) {
      setDashboardDataError(handleErrorMessage(e));
    } finally {
      setDashboardDataLoading(false);
    }
  };

  return (
    <CoreContext.Provider
      value={
        {
          stateOptions,
          setStateOptions,
          stateLoading,
          setStateLoading,
          stateError,
          setStateError,
          cityOptions,
          setCityOptions,
          cityLoading,
          setCityLoading,
          cityError,
          setCityError,
          fetchStateOptions,
          getCity,
          fetchCityOptions,
          fetchCepData,
          coreApi,
          DashboardData: {
            handleGetDashboardData,
            dashboardData,
            dashboardDataError,
            dashboardDataLoading,
            dashboardDataCount,
          },
        } as CoreContextType
      }
    >
      {children}
    </CoreContext.Provider>
  );
};

export const useCore = () => useContext(CoreContext);
