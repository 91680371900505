import {
  Climate,
  ClimatePayload,
  PaginatedResponse,
  UploadClimateProductHistoryPayload,
} from '../types';
import { BaseClient } from './BaseService';

export interface ClimateApi {
  getClimateById(id: string): Promise<Climate>;
  getClimates(): Promise<Array<Climate>>;
  getPagedClimates(page: number): Promise<PaginatedResponse<Climate>>;
  deleteClimate(id: string): Promise<void>;
  editClimate(payload: ClimatePayload): Promise<Climate>;
  addClimate(payload: ClimatePayload): Promise<Climate>;
  uploadClimateProductHistory(
    payload: UploadClimateProductHistoryPayload
  ): Promise<void>;
}

class ClimateService extends BaseClient implements ClimateApi {
  async getClimateById(id: string): Promise<Climate> {
    const { data } = await this.api.get(`/${id}/`);
    return data as Climate;
  }

  async getClimates(): Promise<Array<Climate>> {
    const { data } = await this.api.get(`/`);
    return data as Array<Climate>;
  }

  async getPagedClimates(page: number): Promise<PaginatedResponse<Climate>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<Climate>;
  }

  async deleteClimate(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editClimate(payload: ClimatePayload): Promise<Climate> {
    const { data } = await this.api.put(`/`, payload);
    return data as Climate;
  }

  async addClimate(payload: ClimatePayload): Promise<Climate> {
    const { data } = await this.api.post(`/`, payload);
    return data as Climate;
  }

  async uploadClimateProductHistory(
    payload: UploadClimateProductHistoryPayload
  ): Promise<void> {
    const { data } = await this.api.post(`/import/`, payload);
    return data;
  }
}

export default ClimateService;
