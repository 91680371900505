import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const CardWithDecal = ({
  color,
  title = '',
  content = '',
}: {
  color?: string;
  title?: string;
  content?: string;
}) => {
  const theme = useTheme();
  color = color ?? theme.palette.info.main;

  return (
    <Paper
      sx={{
        borderLeft: `solid 4px ${color}`,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="caption"
        fontSize={theme.spacing(2)}
        fontWeight="bold"
        color={color}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="bold"
        component="span"
        fontSize={theme.spacing(4)}
        color={color}
      >
        {content}
      </Typography>
    </Paper>
  );
};
