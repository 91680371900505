import React, { createContext, useState, useContext } from 'react';
import ClimateHistoryService, {
  ClimateHistoryApi,
} from '../services/ClimateHistoryService';

import { ClimateHistory, UploadClimateHistoryPayload } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './AuthProvider';

export type ClimateHistoryContextType = {
  climatesHistories: Array<ClimateHistory> | undefined;
  setClimatesHistories: React.Dispatch<
    React.SetStateAction<Array<ClimateHistory> | undefined>
  >;
  climateHistory: ClimateHistory | undefined;
  setClimateHistory: React.Dispatch<
    React.SetStateAction<ClimateHistory | undefined>
  >;
  climateHistoryError: string | undefined;
  setClimateHistoryError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  climateHistoryLoading: boolean;
  setClimateHistoryLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getClimateHistory(id: string): Promise<ClimateHistory>;
  climateHistoryApi: ClimateHistoryApi;
  getClimatesHistories(): Promise<void>;
  uploadClimateHistory(
    payload: UploadClimateHistoryPayload
  ): Promise<null | string>;
  setUploadClimateHistoryError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  uploadClimateHistoryError: string | undefined;
};

export const ClimateHistoryContext = createContext<ClimateHistoryContextType>(
  {} as unknown as ClimateHistoryContextType
);

export const ClimateHistoryProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [climatesHistories, setClimatesHistories] =
    useState<Array<ClimateHistory>>();
  const [climateHistory, setClimateHistory] = useState<ClimateHistory>();
  const [climateHistoryLoading, setClimateHistoryLoading] =
    useState<boolean>(false);
  const [climateHistoryError, setClimateHistoryError] = useState<string>();
  const [uploadClimateHistoryError, setUploadClimateHistoryError] =
    useState<string>();

  const { token } = useAuth();

  const climateHistoryApi = new ClimateHistoryService({
    basePath: '/v1/historicoclima',
    contentType: 'multipart/form-data',
    token,
  });

  const getClimatesHistories = async () => {
    if (
      (climatesHistories?.length ?? -1) < 0 &&
      climateHistoryLoading === false
    ) {
      try {
        setClimateHistoryLoading(true);
        const data = await climateHistoryApi.getClimatesHistories();
        setClimatesHistories(data);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setClimateHistoryLoading(false);
      }
    }
  };

  const uploadClimateHistory = async (payload: UploadClimateHistoryPayload) => {
    setClimateHistoryLoading(true);
    setUploadClimateHistoryError(undefined);
    try {
      await climateHistoryApi.uploadClimateHistory(payload);
    } catch (e: unknown) {
      setUploadClimateHistoryError(handleErrorMessage(e));
      setClimateHistoryLoading(false);
      return handleErrorMessage(e);
    } finally {
      setClimateHistoryLoading(false);
    }
    return null;
  };

  const getClimateHistory = async (id: string) => {
    let data: ClimateHistory | undefined = undefined;
    setClimateHistoryLoading(true);
    setClimateHistoryError(undefined);
    try {
      data = await climateHistoryApi.getClimateHistoryById(id);
      setClimateHistory(data);
    } catch (e: unknown) {
      setClimateHistoryError(handleErrorMessage(e));
    } finally {
      setClimateHistoryLoading(false);
    }
    return data;
  };

  return (
    <ClimateHistoryContext.Provider
      value={
        {
          climateHistoryApi,
          climatesHistories,
          setClimatesHistories,
          climateHistory,
          setClimateHistory,
          climateHistoryLoading,
          setClimateHistoryLoading,
          climateHistoryError,
          setClimateHistoryError,
          getClimateHistory,
          getClimatesHistories,
          uploadClimateHistory,
          uploadClimateHistoryError,
          setUploadClimateHistoryError,
        } as ClimateHistoryContextType
      }
    >
      {children}
    </ClimateHistoryContext.Provider>
  );
};

export const useClimateHistory = () => useContext(ClimateHistoryContext);
