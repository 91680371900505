import React, { createContext, useState, useContext } from 'react';
import IndexService, { IndexApi } from '../services/IndexService';

import { Index, SelectOption } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './AuthProvider';

export type IndexContextType = {
  indexes: Array<Index> | undefined;
  setIndexes: React.Dispatch<React.SetStateAction<Array<Index> | undefined>>;
  index: Index | undefined;
  setIndex: React.Dispatch<React.SetStateAction<Index | undefined>>;
  indexError: string | undefined;
  setIndexError: React.Dispatch<React.SetStateAction<string | undefined>>;
  indexLoading: boolean;
  setIndexLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getIndex(id: string): Promise<Index>;
  indexesOptions: Array<SelectOption>;
  setIndexesOptions: React.Dispatch<
    React.SetStateAction<Array<SelectOption> | undefined>
  >;
  getIndexesOptions(): Promise<Array<SelectOption> | undefined>;
  indexApi: IndexApi;
};

export const IndexContext = createContext<IndexContextType>(
  {} as unknown as IndexContextType
);

export const IndexProvider = ({ children }: { children?: React.ReactNode }) => {
  const [indexes, setIndexes] = useState<Array<Index>>();
  const [index, setIndex] = useState<Index>();
  const [indexLoading, setIndexLoading] = useState<boolean>(false);
  const [indexError, setIndexError] = useState<string>();

  const [indexesOptions, setIndexesOptions] = useState<Array<SelectOption>>();

  const { token } = useAuth();

  const indexApi = new IndexService({
    basePath: '/v1/indice',
    token,
  });

  const getIndex = async (id: string) => {
    let data: Index | undefined = undefined;
    setIndexLoading(true);
    setIndexError(undefined);
    try {
      data = await indexApi.getIndexById(id);
      setIndex(data);
    } catch (e: unknown) {
      setIndexError(handleErrorMessage(e));
    } finally {
      setIndexLoading(false);
    }
    return data as Index;
  };

  const getIndexesOptions = async () => {
    try {
      setIndexError(undefined);
      setIndexLoading(true);
      const data = await indexApi.getIndexes();
      const options = data.map(
        (index) =>
          ({
            label: index.nome,
            value: index.indiceId,
          } as SelectOption)
      );
      setIndexesOptions(options);
    } catch (error: unknown) {
      setIndexError(handleErrorMessage(error));
    } finally {
      setIndexLoading(false);
      return indexesOptions;
    }
  };

  return (
    <IndexContext.Provider
      value={
        {
          indexApi,
          indexes,
          setIndexes,
          index,
          setIndex,
          indexLoading,
          setIndexLoading,
          indexError,
          setIndexError,
          getIndex,
          indexesOptions,
          setIndexesOptions,
          getIndexesOptions,
        } as IndexContextType
      }
    >
      {children}
    </IndexContext.Provider>
  );
};

export const useIndex = () => useContext(IndexContext);
