import React from 'react';

import { useUi } from '../../contexts';

export const Page = ({ children }: { children: React.ReactNode }) => {
  const { sidebarOpen, setSidebarOpen } = useUi();

  return (
    <div
      style={{
        maxHeight: '100vh',
        background: 'transparent',
        paddingBottom: 64,
      }}
    >
      {sidebarOpen && (
        <div
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
          style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, .5)',
            zIndex: 128,
          }}
        />
      )}
      {children}
    </div>
  );
};
