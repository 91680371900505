import axios from 'axios';
import { BaseClient } from './BaseService';

import { State, City, Address, DashboardDataType } from '../types';

export interface StateApi {
  getStateById(id: string): Promise<State>;
  getStates(): Promise<Array<State>>;
  getCity(cityId: string): Promise<City>;
  getCities(): Promise<Array<City>>;
  getCitiesByStateId(id: string): Promise<Array<City>>;
  getCepData(cep: string): Promise<Address>;
}

class CoreService extends BaseClient implements StateApi {
  async getStateById(id: string): Promise<State> {
    const { data } = await this.api.get(`/estado/${id}/`);
    return data as State;
  }

  async getStates(): Promise<Array<State>> {
    const { data } = await this.api.get(`/estado/`);
    return data as Array<State>;
  }

  async getCity(cityId: string): Promise<City> {
    const { data } = await this.api.get(`/cidade/${cityId}`);
    return data as City;
  }

  async getCities(): Promise<Array<City>> {
    const { data } = await this.api.get(`/cidade/`);
    return data as Array<City>;
  }

  async getCitiesByStateId(stateId: string): Promise<Array<City>> {
    const { data } = await this.api.get(`/cidade/estado/id/${stateId}/`);
    return data as Array<City>;
  }

  async getCepData(cep: string): Promise<Address> {
    const { data } = await axios.get(
      `${process.env.REACT_APP_VIACEP_URL}/ws/${cep}/json/`
    );
    return data as Address;
  }

  async getDashboardData(): Promise<DashboardDataType> {
    const { data } = await this.api.get(`/dashboard/`);
    return data as DashboardDataType;
  }
}

export default CoreService;
