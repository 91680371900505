import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { ErrorMsg } from '../../components/ErrorMsg';
import { Loading } from '../../components/Loading';

import logo from '../../assets/logo.png';
import { handleErrorMessage } from '../../utils';

type ValidateAccountant = {
  cpf: string;
  nomeCliente: string;
  premio: string;
  valorDaCobertura: string;
};

export const ProposalValidate = () => {
  const theme = useTheme();
  const { id } = useParams();

  const [accountant, setAccountant] = useState<ValidateAccountant>();
  const [accountantLoading, setAccountantLoading] = useState<boolean>(false);
  const [accountantError, setAccountantError] = useState<string>();

  const fetchData = async (id: string) => {
    if (accountantLoading === false) {
      try {
        setAccountantError(undefined);
        setAccountantLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/proposta/validar/${id}`
        );
        setAccountant(data as ValidateAccountant);
        return data;
      } catch (e: unknown) {
        setAccountantError(handleErrorMessage(e));
      } finally {
        setAccountantLoading(false);
      }
    }
    return null;
  };

  useEffect(() => {
    fetchData(id ?? '');
  }, []);

  return (
    <Grid container justifyContent="center">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(10),
        }}
      >
        {accountantLoading && <Loading />}
        {!accountantLoading && accountantError && (
          <ErrorMsg error={accountantError} />
        )}
        {!accountantError && !accountantLoading && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(3),
              }}
            >
              <img src={logo} alt="Field Tech Logo" />
            </Box>
            <Typography
              variant="h4"
              component="h2"
              color="primary"
              fontWeight="bold"
              align="center"
            >
              SEGURO AGRÍCOLA PARAMÉTRICO
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: theme.spacing(4),
              }}
            >
              <Box
                sx={{
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(3),
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: theme.spacing(1),
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{ marginTop: theme.spacing(3) }}
                  variant="h6"
                  color="primary"
                  fontWeight="bold"
                >
                  Informações Gerais
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  CPF
                  <Typography variant="body1" color="textSecondary">
                    {accountant?.cpf ?? 'Sem CPF'}
                  </Typography>
                </Typography>

                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  Nome do Cliente
                  <Typography variant="body1" color="textSecondary">
                    {accountant?.nomeCliente ?? 'Sem Nome'}
                  </Typography>
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  Prêmio
                  <Typography variant="body1" color="textSecondary">
                    {accountant?.premio ?? 'Sem Premio'}
                  </Typography>
                </Typography>

                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  Valor da Cobertura
                  <Typography variant="body1" color="textSecondary">
                    {accountant?.valorDaCobertura ?? 'Sem valor'}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
};
