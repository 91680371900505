import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import dayjs from 'dayjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

import { ErrorMsg } from '../../components/ErrorMsg';
import { useAccountant } from '../../contexts';
import { Loading } from '../../components/Loading';

import logo from '../../assets/logo.png';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ProposalDetail = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { accountant, accountantLoading, accountantError, getAccountant } =
    useAccountant();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    await getAccountant(id ?? '');
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (accountant ?? false) {
      if (loading === false) window.print();
    }
  }, [accountant, loading]);

  return (
    <Grid container justifyContent="center">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(10),
        }}
      >
        {accountantLoading && <Loading />}
        {!accountantLoading && accountantError && (
          <ErrorMsg error={accountantError} />
        )}
        {!accountantError && !accountantLoading && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(3),
              }}
            >
              <img
                src={logo}
                onLoad={() => setLoading(false)}
                alt="Field Tech Logo"
              />
            </Box>
            <Typography
              variant="h4"
              component="h2"
              color="primary"
              fontWeight="bold"
              align="center"
            >
              SEGURO AGRÍCOLA PARAMÉTRICO
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              fontWeight="medium"
              align="center"
            >
              {accountant?.nomeCultura}{' '}
              {dayjs(accountant?.dataCriacao, 'DD/MM/YYYY').year()} {''}– Seguro
              / Paramétrico Declaração {''}
              {accountant?.cotadorId ?? ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: theme.spacing(4),
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{ marginTop: theme.spacing(3) }}
                  variant="h6"
                  color="primary"
                  fontWeight="bold"
                >
                  Informações Gerais
                </Typography>
                <Box
                  sx={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(3),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: theme.spacing(1),
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Seguradora
                    <Typography variant="body1" color="textSecondary">
                      {accountant?.nomeSeguradora}
                    </Typography>
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Inicio da Vigência
                    <Typography variant="body1" color="textSecondary">
                      {dayjs(accountant?.dataInicio).format('DD/MM/YYYY')}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Final da Vigência
                    <Typography variant="body1" color="textSecondary">
                      {dayjs(accountant?.dataFinal).format('DD/MM/YYYY')}
                    </Typography>
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Produto
                    <Typography variant="body1" color="textSecondary">
                      {accountant?.nomeProduto}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{ marginTop: theme.spacing(3) }}
                  variant="h6"
                  color="primary"
                  fontWeight="bold"
                >
                  QR Code de Validação
                </Typography>
                <Box
                  sx={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(3),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                    justifyContent: 'flex-start',
                  }}
                >
                  <QRCodeSVG
                    value={`https://app.fieldtechseg.com/proposals/validate/${
                      accountant?.cotadorId ?? ''
                    }/`}
                    size={200}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Typography
              sx={{ marginTop: theme.spacing(3) }}
              variant="h6"
              color="primary"
              fontWeight="bold"
            >
              Segurado
            </Typography>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  Nome do Segurado
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.nomeInstituicaoFinanceira}
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  CPF
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.cnpjInstituicaoFinanceira}
                </Typography>
              </Box>
            </Box>

            <Divider />
            <Typography
              sx={{ marginTop: theme.spacing(3) }}
              variant="h6"
              color="primary"
              fontWeight="bold"
            >
              Dados do Risco
            </Typography>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                CPF
                <Typography variant="body1" color="textSecondary">
                  {accountant?.cpfCliente}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Nome
                <Typography variant="body1" color="textSecondary">
                  {accountant?.nomeCliente}
                </Typography>
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Endereço
                <Typography variant="body1" color="textSecondary">
                  {accountant?.enderecoCliente}
                </Typography>
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                CEP
                <Typography variant="body1" color="textSecondary">
                  {accountant?.cepCliente}
                </Typography>
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Município - Estado
                <Typography variant="body1" color="textSecondary">
                  {accountant?.cidadeCliente} - {accountant?.estadoCliente}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Área Contratada (ha)
                <Typography variant="body1" color="textSecondary">
                  {accountant?.area} (ha)
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Importância Segurada
                <Typography variant="body1" color="textSecondary">
                  R$ {accountant?.importanciaSeguradaTotal}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                NC%
                <Typography variant="body1" color="textSecondary">
                  {accountant?.nivelDeCobertura}%
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Valor da Cobertura
                <Typography variant="body1" color="textSecondary">
                  R$ {accountant?.valorDaCobertura}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Taxa
                <Typography variant="body1" color="textSecondary">
                  {accountant?.taxa}%
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Prêmio do Seguro
                <Typography variant="body1" color="textSecondary">
                  R${accountant?.premio}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Início de Pagamento (Strike)
                <Typography variant="body1" color="textSecondary">
                  {accountant?.inicioPagamento}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Final de Pagamento (Exit)
                <Typography variant="body1" color="textSecondary">
                  {accountant?.finalPagamento}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  E-mail
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.emailCliente}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  Telefone / Celular
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.telefoneCliente}
                </Typography>
              </Box>
            </Box>

            <Divider />
            <Typography
              sx={{ marginTop: theme.spacing(3) }}
              variant="h6"
              color="primary"
              fontWeight="bold"
            >
              Beneficiário
            </Typography>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  CPF / CNPJ
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.cnpjBeneficiario ?? 'Sem beneficiário'}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  Nome / Razão Social
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.nomeBeneficiario ?? 'Sem beneficiário'}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Grid>
  );
};
