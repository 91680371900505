import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import Button from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';
import { EmptyList } from '../../components/EmptyList';
import { TableSkeleton } from '../../components/TableSkeleton';
import { DeleteDialog } from '../../components/DeleteDialog';
import { Layout } from '../../components/Layout';
import { SearchField } from '../../components/SearchField';
import { ErrorMsg } from '../../components/ErrorMsg';
import { ImageField } from '../../components/ImageField';

import { useProductHistory, useProduct } from '../../contexts';
import {
  handleErrorMessage,
  initField,
  SUPORTED_CSV_FIELD_TYPES,
} from '../../utils';
import {
  ProductHistory,
  SelectOption,
  FileFieldType,
  UploadProductHistoryPayload,
} from '../../types';

export const ProductHistoryList = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    productHistoryApi,
    productsHistories,
    setProductsHistories,
    productHistoryLoading,
    setProductHistoryError,
    uploadProductHistoryError,
  } = useProductHistory();
  const { productsOptions, productLoading, getProductsOptions } = useProduct();

  const productsHistoriesCount = productsHistories?.length ?? -1;

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const [productHistoryDataExcelOpen, setProductHistoryDataExcelOpen] =
    useState<boolean>(false);
  const [productId, setProductId] = useState<SelectOption>(null); // eslint-disable-next-line
  const [linkProductHistoryId, setLinkProductHistoryId] = useState<string>();

  const [HistoricoProduto, setHistoricoProduto] = useState(
    initField<FileFieldType>({
      name: 'HistoricoProduto',
      value: null,
      label: 'HistoricoProduto',
    })
  );

  const getProductsHistories = async () => {
    if (productsHistoriesCount < 0 && loading === false) {
      try {
        setLoading(true);
        const data = await productHistoryApi.getProductsHistories();
        setProductsHistories(data);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getProductsHistories();
    getProductsOptions();
  }, []);

  const handleDelete = async () => {
    await productHistoryApi.deleteProductHistory(deleteId as string);
    setProductsHistories(
      (productsHistories ?? []).filter(
        (productHistory: ProductHistory) =>
          productHistory.historicoProdutoId !== deleteId
      )
    );
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  return (
    <Layout>
      <Typography variant="h4" component="h2" color="textSecondary">
        Cadastro de Histórico de Produto
      </Typography>
      <Button
        style={{ marginTop: theme.spacing(2) }}
        variant="contained"
        color="primary"
        component={Link}
        href="/productsHistories/create/"
      >
        <Typography
          variant="button"
          color="white"
          style={{
            display: 'flex',
            gap: theme.spacing(1),
          }}
        >
          <AddIcon />
          Cadastrar novo Histórico de Produto
        </Typography>
      </Button>
      <Button
        style={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(1) }}
        variant="contained"
        color="primary"
        onClick={() => {
          setProductHistoryDataExcelOpen(true);
        }}
      >
        <Typography
          variant="button"
          color="white"
          style={{
            display: 'flex',
            gap: theme.spacing(1),
          }}
        >
          <CloudUploadIcon />
          Atualizar Históricos de Produtos de Produto
        </Typography>
      </Button>
      <div style={{ marginTop: theme.spacing(2) }}>
        {productsHistoriesCount < 0 && (
          <TableSkeleton
            columns={['Nome', 'Email', 'Telefone', 'UF', 'CNPJ', 'Ações']}
          />
        )}
        {productsHistoriesCount === 0 && (
          <EmptyList
            to="/productHistory/create/"
            label="Cadastrar Histórico de Produto"
          />
        )}
        {productsHistoriesCount > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableHeadCell component="th" scope="row">
                    Produto
                  </TableHeadCell>
                  <TableHeadCell component="th" scope="row">
                    Estado
                  </TableHeadCell>
                  <TableHeadCell component="th" scope="row">
                    Cidade
                  </TableHeadCell>
                  <TableHeadCell component="th" scope="row">
                    Data
                  </TableHeadCell>
                  <TableHeadCell component="th" scope="row">
                    Valor
                  </TableHeadCell>
                  <TableHeadCell component="th" scope="row">
                    Ações
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsHistories?.map((row) => (
                  <TableRow key={row.historicoProdutoId}>
                    <TableCell component="th" scope="row">
                      {`${row.cidadeNome} - ${row.estadoSigla}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.estadoNome}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.cidadeNome}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.ano}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.valor}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      <Box
                        sx={{
                          display: 'flex',
                          gap: theme.spacing(1),
                          justifyContent: 'center',
                        }}
                      >
                        <IconButton
                          sx={{
                            background: green[100],
                            color: theme.palette.primary.main,
                          }}
                          aria-label="Editar"
                          LinkComponent={Link}
                          href={`/productsHistories/edit/${row.historicoProdutoId}/`}
                        >
                          <EditIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                        <IconButton
                          sx={{ background: red[100] }}
                          color="secondary"
                          aria-label="Excluir"
                          onClick={() => {
                            setDeleteOpen(true);
                            setDeleteId(row.historicoProdutoId);
                            setDeleteName(row.historicoProdutoId);
                          }}
                        >
                          <CloseIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <DeleteDialog
        open={deleteOpen}
        name={deleteName}
        setOpen={setDeleteOpen}
        handleDelete={handleDelete}
      />
      <Dialog
        open={productHistoryDataExcelOpen}
        onClose={(event, reason) => {
          if (reason && reason == 'backdropClick' && productHistoryLoading)
            return;
          setProductHistoryDataExcelOpen(false);
        }}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <Box marginTop={2}>
          <ErrorMsg error={uploadProductHistoryError} />
        </Box>
        <DialogTitle id="delete-dialog-title">
          Atualizar Históricos de Produtos de Produto
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              display: 'flex',
              marginBottom: theme.spacing(2),
            }}
          >
            Qual produto deseja atualizar?
          </Typography>
          <SearchField
            extraAttrs={{ value: productId as SelectOption }}
            options={productsOptions}
            label="Selecione o Produto"
            loading={productLoading}
            handleChange={(value: SelectOption) => {
              setProductId(value);
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: theme.spacing(2),
            }}
          >
            <ImageField
              setField={(field: FileFieldType) => {
                setHistoricoProduto(field);
                setProductHistoryError(undefined);
              }}
              field={HistoricoProduto}
              allowedFileTypes={SUPORTED_CSV_FIELD_TYPES}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={productHistoryLoading}
            color="inherit"
            onClick={() => {
              setProductHistoryDataExcelOpen(false);
              setProductHistoryError(undefined);
            }}
          >
            Cancelar
          </Button>
          <Button
            loading={productHistoryLoading}
            onClick={async () => {
              const payload = {
                HistoricoProduto: HistoricoProduto.value,
                ProdutoId: productId?.value ?? '',
              } as UploadProductHistoryPayload;
              const data = await productHistoryApi.uploadProductHistory(
                payload
              );
              if (typeof data === 'object') {
                await productHistoryApi.getProductsHistories();
                setProductId(null);
                setProductHistoryDataExcelOpen(false);
              }
            }}
            color="primary"
            autoFocus
            variant="outlined"
            disabled={productId ? false : true}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
