import { Crop, CropPayload, PaginatedResponse } from '../types';
import { BaseClient } from './BaseService';

export interface CropApi {
  getCropById(id: string): Promise<Crop>;
  getCrops(): Promise<Array<Crop>>;
  getPagedCrops(page: number): Promise<PaginatedResponse<Crop>>;
  deleteCrop(id: string): Promise<void>;
  editCrop(payload: CropPayload): Promise<Crop>;
  addCrop(payload: CropPayload): Promise<Crop>;
}

class CropService extends BaseClient implements CropApi {
  async getCropById(id: string): Promise<Crop> {
    const { data } = await this.api.get(`/${id}/`);
    return data as Crop;
  }

  async getCrops(): Promise<Array<Crop>> {
    const { data } = await this.api.get(`/`);
    return data as Array<Crop>;
  }

  async getPagedCrops(page: number): Promise<PaginatedResponse<Crop>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<Crop>;
  }

  async deleteCrop(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editCrop(payload: CropPayload): Promise<Crop> {
    const { data } = await this.api.put(`/`, payload);
    return data.cultura as Crop;
  }

  async addCrop(payload: CropPayload): Promise<Crop> {
    const { data } = await this.api.post(`/`, payload);
    return data.cultura as Crop;
  }
}

export default CropService;
