import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Bar } from 'react-chartjs-2';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';

import { ErrorMsg } from '../../components/ErrorMsg';
import { Loading } from '../../components/Loading';

import logo from '../../assets/logo.png';
import { SIMULATE_ACCOUNTANT_KEY, formatCurrency } from '../../utils';

import {
  AccountantPayload,
  City,
  Crop,
  FinancialAgency,
  FinancialInstitution,
  HistoryChartData,
  Product,
  SubProduct,
} from '../../types';
import {
  useAuth,
  useCore,
  useCrop,
  useFinancialAgency,
  useFinancialInstitution,
  useProduct,
} from '../../contexts';

interface SimulateCity extends City {
  nomeEstado: string;
  siglaEstado: string;
}

interface AccountantSimulateType extends AccountantPayload {
  Cidade?: SimulateCity | null;
  NomeCliente?: string;
  Cultura?: Crop;
  InstituicaoFinanceira?: FinancialInstitution;
  Produto?: Product | null;
  Agencia?: FinancialAgency;
  SubProduto?: SubProduct;
  Exit?: number;
  SubProductHistory?: HistoryChartData | undefined;
}

export const AccountantSimulate = () => {
  const theme = useTheme();
  const { token } = useAuth();

  const [accountant, setAccountant] = useState<AccountantSimulateType>();
  const [accountantLoading, setAccountantLoading] = useState<boolean>(false);
  const [accountantError, setAccountantError] = useState<string>();

  const { getCity } = useCore();
  const { getCrop } = useCrop();
  const { getFinancialInstitution } = useFinancialInstitution();
  const { getProduct } = useProduct();
  const { getFinancialAgency } = useFinancialAgency();

  const getSimulateData = async () => {
    let data: AccountantSimulateType | null = JSON.parse(
      sessionStorage.getItem(SIMULATE_ACCOUNTANT_KEY) ?? 'null'
    );
    if (data !== null) {
      try {
        const [agency, city, crop, financialInstitution, product] =
          await Promise.all([
            data.AgenciaId ? getFinancialAgency(data.AgenciaId) : undefined,

            data.CidadeId ? getCity(data.CidadeId) : undefined,
            data.CulturaId ? getCrop(data.CulturaId) : undefined,
            data.InstituicaoFinanceiraId
              ? getFinancialInstitution(data.InstituicaoFinanceiraId)
              : undefined,
            data.ProdutoId ? getProduct(data.ProdutoId) : undefined,
          ]);

        const subProduct = product?.subProduto?.find((sub) => {
          return sub.cidadeId.toString() === data?.CidadeId;
        });

        let exit: number | undefined = undefined;

        if (subProduct?.strike3Gatilho3 !== 0)
          exit = subProduct?.strike3Gatilho3;
        else if (subProduct?.strike3Gatilho2 !== 0)
          exit = subProduct?.strike3Gatilho2;
        else if (subProduct?.strike3Gatilho1 !== 0)
          exit = subProduct?.strike3Gatilho1;
        else if (subProduct?.strike2Gatilho3 !== 0)
          exit = subProduct?.strike2Gatilho3;
        else if (subProduct?.strike2Gatilho2 !== 0)
          exit = subProduct?.strike2Gatilho2;
        else if (subProduct?.strike2Gatilho1 !== 0)
          exit = subProduct?.strike2Gatilho1;
        else if (subProduct?.strike1Gatilho3 !== 0)
          exit = subProduct?.strike1Gatilho3;
        else if (subProduct?.strike1Gatilho2 !== 0)
          exit = subProduct?.strike1Gatilho2;
        else if (subProduct?.strike1Gatilho1 !== 0)
          exit = subProduct?.strike1Gatilho1;

        data = {
          ...data,
          Agencia: agency,
          Cidade: city as SimulateCity,
          Cultura: crop,
          InstituicaoFinanceira: financialInstitution,
          Produto: product,
          SubProduto: subProduct,
          Exit: exit,
        };

        setAccountant(data);
        setAccountantError(undefined);
      } catch {
        setAccountantError('Erro ao carregar dados de simulação');
      }
    } else {
      setAccountantError('Sem dados de simulação');
    }
  };

  useEffect(() => {
    if (accountant) {
      if (!accountant.SubProductHistory) window.print();
    }
  }, [accountant]);

  useEffect(() => {
    if ((token ?? '').length > 0) {
      getSimulateData();
    }
  }, [token]);

  return (
    <Grid container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(10),
        }}
      >
        {accountantLoading && <Loading />}
        {!accountantLoading && accountantError && (
          <ErrorMsg error={accountantError} />
        )}
        {!accountantError && !accountantLoading && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(3),
              }}
            >
              <img
                src={logo}
                onLoad={() => setAccountantLoading(false)}
                alt="Field Tech Logo"
              />
            </Box>
            <Typography
              variant="h4"
              component="h2"
              color="primary"
              fontWeight="bold"
              align="center"
            >
              SEGURO AGRÍCOLA PARAMÉTRICO
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              fontWeight="medium"
              align="center"
            >
              {accountant?.Cultura?.nome} {dayjs().year()} {''}– Seguro /
              Paramétrico Declaração de SIMULAÇÃO
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: theme.spacing(4),
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{ marginTop: theme.spacing(3) }}
                  variant="h6"
                  color="primary"
                  fontWeight="bold"
                >
                  Informações Gerais
                </Typography>
                <Box
                  sx={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(3),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: theme.spacing(1),
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Seguradora
                    <Typography variant="body1" color="textSecondary">
                      {accountant?.Produto?.seguradoraNome ?? 'Nenhum(a)'}
                    </Typography>
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Inicio da Vigência
                    <Typography variant="body1" color="textSecondary">
                      {dayjs(accountant?.SubProduto?.dataInicioGatilho1).format(
                        'DD/MM/YYYY'
                      )}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Final da Vigência
                    <Typography variant="body1" color="textSecondary">
                      {dayjs(accountant?.SubProduto?.dataFinalGatilho1).format(
                        'DD/MM/YYYY'
                      )}
                    </Typography>
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    fontWeight="medium"
                  >
                    Produto
                    <Typography variant="body1" color="textSecondary">
                      {accountant?.Produto?.nome ?? 'Nenhum(a)'}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Typography
              sx={{ marginTop: theme.spacing(3) }}
              variant="h6"
              color="primary"
              fontWeight="bold"
            >
              Segurado
            </Typography>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  Nome do Segurado
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.InstituicaoFinanceira?.nome ?? 'Nenhum(a)'}
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  CPF/CNPJ
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.InstituicaoFinanceira?.cnpj ?? 'Nenhum(a)'}
                </Typography>
              </Box>
            </Box>

            <Divider />
            <Typography
              sx={{ marginTop: theme.spacing(3) }}
              variant="h6"
              color="primary"
              fontWeight="bold"
            >
              Dados do Risco
            </Typography>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                CPF
                <Typography variant="body1" color="textSecondary">
                  {accountant?.CPFCliente ?? 'Nenhum(a)'}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Nome
                <Typography variant="body1" color="textSecondary">
                  {accountant?.NomeCliente ?? 'Nenhum(a)'}
                </Typography>
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Endereço
                <Typography variant="body1" color="textSecondary">
                  {accountant?.EnderecoCliente ?? 'Nenhum(a)'}
                </Typography>
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                CEP
                <Typography variant="body1" color="textSecondary">
                  {accountant?.CEPCliente ?? 'Nenhum(a)'}
                </Typography>
              </Typography>

              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Município - Estado
                <Typography variant="body1" color="textSecondary">
                  {accountant?.Cidade?.nome} -{' '}
                  {accountant?.Cidade?.nomeEstado ?? 'Nenhum(a)'}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Área Contratada (ha)
                <Typography variant="body1" color="textSecondary">
                  {accountant?.Area ?? 0} (ha)
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Importância Segurada
                <Typography variant="body1" color="textSecondary">
                  R$
                  {formatCurrency(
                    parseFloat(accountant?.ImportanciaSeguradaTotal ?? '0')
                      .toFixed(2)
                      .replace('.', ',')
                  ) ?? 0}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                NC%
                <Typography variant="body1" color="textSecondary">
                  {accountant?.NivelDeCobertura ?? 0}%
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Valor da Cobertura
                <Typography variant="body1" color="textSecondary">
                  R$
                  {formatCurrency(
                    parseFloat(accountant?.ValorDaCobertura ?? '0')
                      .toFixed(2)
                      .replace('.', ',')
                  ) ?? 0}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Taxa
                <Typography variant="body1" color="textSecondary">
                  {accountant?.Taxa ?? 0}%
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Prêmio do Seguro
                <Typography variant="body1" color="textSecondary">
                  R$
                  {formatCurrency(
                    parseFloat(accountant?.Premio ?? '0')
                      .toFixed(2)
                      .replace('.', ',')
                  ) ?? 0}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Início de Pagamento (Strike)
                <Typography variant="body1" color="textSecondary">
                  {accountant?.SubProduto?.strike1Gatilho1 ?? 'Nenhum(a)'}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                fontWeight="medium"
              >
                Final de Pagamento (Exit)
                <Typography variant="body1" color="textSecondary">
                  {accountant?.Exit ?? '0'}
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  E-mail
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.EmailCliente ?? 'Nenhum(a)'}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  Telefone / Celular
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.TelefoneCliente ?? 'Nenhum(a)'}
                </Typography>
              </Box>
            </Box>

            <Divider />
            <Typography
              sx={{ marginTop: theme.spacing(3) }}
              variant="h6"
              color="primary"
              fontWeight="bold"
            >
              Beneficiário
            </Typography>
            <Box
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(3),
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  CPF / CNPJ
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.CNPJBeneficiario ?? 'Sem beneficiário'}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  fontWeight="medium"
                >
                  Nome / Razão Social
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountant?.NomeBeneficiario ?? 'Sem beneficiário'}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {accountant?.SubProductHistory && (
          <Box sx={{ maxWidth: '100%', width: '100%' }}>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: (item) => {
                        return `%${item.raw}`;
                      },
                    },
                  },
                },
                scales: {
                  yAxes: {
                    ticks: {
                      callback: (tickValue) => {
                        return '%' + tickValue;
                      },
                    },
                  },
                },
                animation: {
                  onComplete: function onComplete() {
                    window.print();
                  },
                },
              }}
              data={accountant.SubProductHistory as HistoryChartData}
            />
          </Box>
        )}
      </Box>
    </Grid>
  );
};
