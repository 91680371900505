import {
  InsuranceCompany,
  InsuranceCompanyPayload,
  PaginatedResponse,
} from '../types';
import { BaseClient } from './BaseService';

export interface InsuranceCompanyApi {
  getInsuranceCompanyById(id: string): Promise<InsuranceCompany>;
  getInsuranceCompanies(): Promise<Array<InsuranceCompany>>;
  getPagedInsuranceCompanies(
    page: number
  ): Promise<PaginatedResponse<InsuranceCompany>>;
  deleteInsuranceCompany(id: string): Promise<void>;
  editInsuranceCompany(
    payload: InsuranceCompanyPayload
  ): Promise<InsuranceCompany>;
  addInsuranceCompany(
    payload: InsuranceCompanyPayload
  ): Promise<InsuranceCompany>;
}

class InsuranceCompanyService
  extends BaseClient
  implements InsuranceCompanyApi
{
  async getInsuranceCompanyById(id: string): Promise<InsuranceCompany> {
    const { data } = await this.api.get(`/${id}/`);
    return data as InsuranceCompany;
  }

  async getInsuranceCompanies(): Promise<Array<InsuranceCompany>> {
    const { data } = await this.api.get(`/`);
    return data as Array<InsuranceCompany>;
  }

  async getPagedInsuranceCompanies(
    page: number
  ): Promise<PaginatedResponse<InsuranceCompany>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<InsuranceCompany>;
  }

  async deleteInsuranceCompany(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editInsuranceCompany(
    payload: InsuranceCompanyPayload
  ): Promise<InsuranceCompany> {
    const { data } = await this.api.put(`/`, payload);
    return data.seguradora as InsuranceCompany;
  }

  async addInsuranceCompany(
    payload: InsuranceCompanyPayload
  ): Promise<InsuranceCompany> {
    const { data } = await this.api.post(`/`, payload);
    return data.seguradora as InsuranceCompany;
  }
}

export default InsuranceCompanyService;
