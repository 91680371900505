/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import axios, { AxiosError } from 'axios';
import {
  BaseFieldType,
  SelectFieldType,
  TextFieldType,
  CommonFormData,
  Module,
} from '../types';

export class Field<T> {
  public name: string;
  public label: string;
  public id: string;
  public value: T;
  public errors: Array<string> = [];

  constructor({
    name,
    label,
    initialValue,
    id,
    errors,
  }: {
    name: string;
    label?: string;
    initialValue?: any;
    id?: string;
    errors?: Array<string>;
  }) {
    this.name = name;
    this.label = name;
    this.label = label ? label : name;
    this.id = id ? id : `id_${name}`;
    this.value = initialValue ? initialValue : '';
    if (errors) this.errors = errors;
  }

  public addError = (error: string) => {
    this.errors = [...this.errors, error];
  };

  public setError = (value: string[]) => {
    this.errors = value;
  };

  public hasError = () => {
    return this.errors.length > 0;
  };

  public setValue = (value: T) => {
    this.value = value;
  };
}

export function cleanForm<T, K>(form: T) {
  const cleanedForm: any = {};
  Object.keys(form as any).map((key: string) => {
    const auxField = form[key as keyof T] as unknown as Field<keyof K>;
    cleanedForm[key as keyof K] = auxField['value' as keyof Field<K>];
  });
  return cleanedForm as K;
}

interface StatusError extends Error {
  status?: number | string;
}

export const handleErrorMessage = (
  err: AxiosError | StatusError | any
): string => {
  if (axios.isAxiosError(err)) {
    const auxErr = err as AxiosError<any>;
    if (typeof auxErr.response !== 'undefined') {
      if (typeof (auxErr.response?.data || false) === 'string') {
        return auxErr.response.data as string;
      } else if (typeof (auxErr.response.data?.message ?? false) === 'string') {
        return auxErr.response.data.message as string;
      } else if (auxErr.response.data?.errors?.length > 0) {
        return auxErr.response.data?.errors;
      }
    }
  }
  const serializedError: StatusError = JSON.parse(JSON.stringify(err));
  if (typeof (serializedError?.status ?? false) === 'number')
    return ` A requsição falhou com status ${serializedError.status}`;
  return err.message;
};

export const SIDEBAR_WIDTH = 320;

export const SUPORTED_IMAGE_FIELD_TYPES = ['svg', 'png', 'jpeg', 'jpg', 'gif'];
export const SUPORTED_DOC_FIELD_TYPES = ['pdf', 'docx', 'pptx'];
export const SUPORTED_CSV_FIELD_TYPES = ['csv'];

export function percentageFieldMask({
  e,
  min = 0,
  max = 100,
}: {
  e: React.ChangeEvent<HTMLInputElement>;
  min?: number;
  max?: number;
}) {
  e.currentTarget.maxLength = 5; // TODO: Pass maxLength to field
  let value = e.target.value;
  value = value.replace(',', '.');
  value = value.replace(/[^0-9.]/g, '');
  if (value.length === 1 && value === '0') value = '';
  if (parseFloat(value) > max) {
    value = String(max);
  }
  if (parseFloat(value) < min) {
    value = String(min);
  }
  return value;
}

export function initField<T extends BaseFieldType<unknown>>({
  name,
  value,
  label,
  id,
  errors = [],
  type = 'text',
  size = 'small',
  required = false,
  excludeFromPayload = false,
  extractValue = false,
  mask,
}: T) {
  label = label ?? name;
  id = id ?? name + '-id';
  errors = errors ?? [];
  type = type ?? 'text';
  size = size ?? 'small';
  required = required ?? undefined;
  if (typeof mask === 'undefined') {
    mask = ({ e }) => {
      if (typeof value === 'boolean') {
        return e.target.checked;
      }
      return e.target.value;
    };
  }

  return {
    name,
    value,
    label,
    id,
    errors,
    type,
    size,
    required,
    excludeFromPayload,
    extractValue,
    mask,
  } as T;
}

export function serializePayload<
  T extends { [key: string]: BaseFieldType<unknown> },
  K
>(form: T) {
  const payload: Partial<K> = {};
  const keys = Object.keys(form) as Array<keyof T>;
  keys.forEach((key) => {
    let field = form[key as keyof T] as BaseFieldType<unknown>;
    if (!(field?.excludeFromPayload ?? false)) {
      if (field.value ?? false) {
        if (field.extractValue ?? false) {
          field = field;
          payload[field.name as keyof K] = (
            field as BaseFieldType<SelectFieldType>
          ).value.value as any;
        } else {
          payload[field.name as keyof K] = field.value as any;
        }
      }
    }
  });

  return payload as K;
}

export const AUTH_SESSION_DATA_KEY = 'FIELD-TECH-SESSION-DATA';

export function maskCNPJ({ e }: { e: React.ChangeEvent<HTMLInputElement> }) {
  e.currentTarget.maxLength = 18; // TODO: Pass maxLength to field
  let value = e.target.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/, '$1.$2');
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');
  return value;
}

export function maskCPF({ e }: { e: React.ChangeEvent<HTMLInputElement> }) {
  e.currentTarget.maxLength = 14; // TODO: Pass maxLength to field
  let value = e.target.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d)/, '$1.$2');
  value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return value;
}

export function maskCEP({ e }: { e: React.ChangeEvent<HTMLInputElement> }) {
  e.currentTarget.maxLength = 9; // TODO: Pass maxLength to field
  let value = e.target.value;
  value = value.replace(/^\d+\.\d{0,2}$/, '');
  value = value.replace(/^(\d{5})(\d)/g, '$1-$2');
  return value;
}

export function maskCellphone({
  e,
}: {
  e: React.ChangeEvent<HTMLInputElement>;
}) {
  e.currentTarget.maxLength = 15; // TODO: Pass maxLength to field
  let value = e.target.value;
  value = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2');
  return value;
}

export const commonFormFields = (data?: Partial<CommonFormData>) => {
  return {
    CNPJ: initField<TextFieldType>({
      name: 'CNPJ',
      value: data?.cnpj ?? '',
      mask: maskCNPJ,
    }),
    CEP: initField<TextFieldType>({
      name: 'CEP',
      value: '',
      mask: maskCEP,
    }),
    UF: initField<TextFieldType>({
      name: 'UF',
      value: data?.uf ?? '',
      mask: ({ e }: { e: React.ChangeEvent<HTMLInputElement> }) => {
        e.currentTarget.maxLength = 2; // TODO: Pass maxLength to field
        let value = e.target.value;
        value = value.replace(/[0-9]/g, '').toUpperCase();
        return value;
      },
    }),
    Telefone: initField<TextFieldType>({
      name: 'Telefone',
      value: data?.telefone ? data.telefone : '',
      required: true,
      mask: maskCellphone,
    }),
  };
};

export function truncate({
  value,
  sizeLimit = 18,
}: {
  value: string;
  sizeLimit?: number;
}) {
  let truncatedValue = value;
  if (value.length >= 18) {
    truncatedValue = value.slice(0, sizeLimit - 2).trim() + '...';
  }
  return truncatedValue;
}

export function downloadFile(fileName: string, fileContent: string) {
  const element = document.createElement('a');
  const file = new Blob([fileContent], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
}

export function downloadLink(url: string) {
  const link = document.createElement('a');
  const items = url.split('/');
  link.download = items.pop() ?? '';
  link.href = items.join('/') + '/' + link.download;
  link.click();
}

export const allModules: Array<Module> = [
  'brokers',
  'customers',
  'financial-institutions',
  'financial-agencies',
  'insurance-companies',
  'products',
  'accountants',
  'crops',
  'climates',
  'proposals',
  'products-histories',
  'climates-histories',
];

export function formatCurrency(n: string) {
  const auxN = n.split(',');
  const decimals = auxN.pop();

  return (
    (auxN[0] ?? '').replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
    ',' +
    decimals
  );
}

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

export function randomRGB() {
  return `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
}

export function getColor() {
  return (
    'hsl(' +
    360 * Math.random() +
    ',' +
    (25 + 70 * Math.random()) +
    '%,' +
    (85 + 10 * Math.random()) +
    '%)'
  );
}

export function filterValidKeys(obj: Record<string, unknown>) {
  return Object.fromEntries(
    Object.entries(obj ?? {}).filter(
      ([_, value]) => value !== null // eslint-disable-line
    )
  );
}

export function convertDMSToDD(
  degrees: string,
  minutes: string,
  seconds: string,
  direction: string
) {
  let dd = +degrees + +minutes / 60 + +seconds / (60 * 60);

  if (direction == 'S' || direction == 'W') {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}

export const computeKML = (drawedPolygons: google.maps.Polygon[]) => {
  const xml = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
  <Document>

      <name>polygon.kml</name>

      <Style id="green-5px">
          <LineStyle>
              <color>8fbe0eff</color>
              <width>5</width>
          </LineStyle>
      </Style>

      ${drawedPolygons
        .map((item, index) => {
          return `<Placemark>
          <name>Área ${index + 1}</name>
          <styleUrl>#green-5px</styleUrl>
          <LineString>
              <tessellate>1</tessellate>
              <coordinates>
                ${item
                  .getPath()
                  .getArray()
                  .map((coord) => {
                    return `${coord.lng()},${coord.lat()},0`;
                  })
                  .join('\n')}
                ${
                  item.getPath().getArray()[0]
                    ? `${item.getPath().getArray()[0].lng()},${item
                        .getPath()
                        .getArray()[0]
                        .lat()},0`
                    : ''
                }
              </coordinates>
          </LineString>
        </Placemark>`;
        })
        .join('\n')}
  </Document>
  </kml>`;
  return xml;
};

export const SIMULATE_ACCOUNTANT_KEY = 'ACCOUNTANT_SIMULATE_DATA';
