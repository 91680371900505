import {
  LoginResponse,
  LoginPayload,
  Role,
  Module,
  ForgotPasswordPayload,
  ChangePasswordPayload,
} from '../types';
import { BaseClient } from './BaseService';

export interface AuthApi {
  login: (payload: LoginPayload) => Promise<LoginResponse>;
  getAllowedRoles: (role: Role) => Promise<Module[]>;
  resetPassword: (payload: ForgotPasswordPayload) => Promise<void>;
}

class AuthService extends BaseClient implements AuthApi {
  login = async (payload: LoginPayload) => {
    const { data } = await this.api.post('/login/', payload);
    return data as LoginResponse;
  };

  resetPassword = async (payload: ForgotPasswordPayload) => {
    await this.api.post('/forgotpassword/', payload);
  };

  changePassword = async (payload: ChangePasswordPayload) => {
    await this.api.put('/changepassword/', payload);
  };

  getAllowedRoles = async (role: Role) => {
    const { data } = await this.api.get(`/permission/${role}`);
    return data as Module[];
  };
}

export default AuthService;
