import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Add from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { Loading } from '../../components/Loading';
import { SearchField } from '../../components/SearchField';
import { ImageField } from '../../components/ImageField';
import {
  handleErrorMessage,
  serializePayload,
  initField,
  SUPORTED_CSV_FIELD_TYPES,
} from '../../utils';
import {
  ProductPayload,
  ProductFormType,
  FileFieldType,
  Product,
  SelectOption,
} from '../../types';
import {
  useFinancialInstitution,
  useIndex,
  useInsuranceCompany,
  useProduct,
} from '../../contexts/contexts';

import { Form } from './styled';

/* import { AuthorizationCheckbox } from '../../components/FormCheckboxes'; */
import { initForm } from './utils';

const HalfedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
}));

export const ProductForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [error, setError] = useState<string[] | string>();
  const [loading, setLoading] = useState<boolean>(false); // eslint-disable-next-line
  const [product, setProduct] = useState<Product | undefined>();
  const { products, setProducts, productApi } = useProduct();
  const { id } = useParams();

  const { indexesOptions, getIndexesOptions } = useIndex();

  const { insuranceCompanyApi } = useInsuranceCompany();
  const { financialInstitutionApi } = useFinancialInstitution();

  const [subProduto, setSubProduto] = useState(
    initField<FileFieldType>({
      name: 'subProduto',
      value: null,
      label: 'Excel Municípios de Programa',
    })
  );
  const [historicoProduto, setHistoricoProduto] = useState(
    initField<FileFieldType>({
      name: 'historicoProduto',
      value: null,
      label: 'Excel Histórico',
    })
  );

  const [financialInstitutionsOptions, setFinancialInstitutionsOptions] =
    useState<Array<SelectOption>>();

  const [insuranceCompaniesOptions, setInsuranceCompaniesOptions] =
    useState<Array<SelectOption>>();

  const [fetchingProduct, setFetchingProduct] = useState<boolean>(!isCreation);
  const [fetchingError, setFetchingError] = useState<string>();

  const [form, setForm] = useState<ProductFormType>(
    initForm({}) as ProductFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof ProductFormType];
    auxForm[e.target.name as keyof ProductFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = {
        ...serializePayload<ProductFormType, ProductPayload>(form),
        SubProduto: subProduto.value,
        HistoricoProduto: historicoProduto.value,
      } as ProductPayload;
      if (payload?.ProdutoId ?? false) {
        const data = await productApi.editProduct(payload);
        if ((products?.length ?? -1) > 0) {
          const auxProducts = [
            ...(products ?? [])?.map((product: Product) => {
              if (product.produtoId === data.produtoId) {
                return data;
              }
              return product;
            }),
          ];
          setProducts(auxProducts);
        }
      } else {
        const data = await productApi.addProduct(payload);
        if ((products?.length ?? -1) > 0)
          setProducts((products ?? []).concat([data]));
      }
      navigate('/products');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const getProduct = async () => {
    if (id ?? false) {
      setFetchingError(undefined);
      try {
        setFetchingProduct(true);
        const data: Product = await productApi.getProductById(id as string);
        setForm(initForm({ data }) as ProductFormType);
        setProduct(data);
      } catch (e: unknown) {
        setFetchingError(handleErrorMessage(e));
      } finally {
        setFetchingProduct(false);
      }
    }
  };

  const getFinancialInstitutionsOptions = async () => {
    try {
      setLoading(true);
      const data = await financialInstitutionApi.getFinancialInstitutions();
      setFinancialInstitutionsOptions(
        data.map(
          (financialInstitution) =>
            ({
              label: financialInstitution.nome,
              value: financialInstitution.instituicaoFinanceiraId,
            } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const getInsuranceCompaniesOptions = async () => {
    try {
      setLoading(true);
      const data = await insuranceCompanyApi.getInsuranceCompanies();
      setInsuranceCompaniesOptions(
        data.map(
          (insuranceCompanies) =>
            ({
              label: insuranceCompanies.nome,
              value: insuranceCompanies.seguradoraId,
            } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
    getInsuranceCompaniesOptions();
    getFinancialInstitutionsOptions();
    getIndexesOptions();
  }, []);

  useEffect(() => {
    if (
      (financialInstitutionsOptions?.length ?? -1) > 0 &&
      (product ?? false)
    ) {
      const financialInstitution = financialInstitutionsOptions?.filter(
        (option) => {
          return option?.value === product?.instituicaoFinanceiraId;
        }
      );
      if ((financialInstitution?.length ?? -1) > 0) {
        setForm({
          ...form,
          InstituicaoFinanceiraId: {
            ...form.InstituicaoFinanceiraId,
            value: (financialInstitution ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [financialInstitutionsOptions, product]);

  useEffect(() => {
    if ((insuranceCompaniesOptions?.length ?? -1) > 0 && (product ?? false)) {
      const insuranceCompany = insuranceCompaniesOptions?.filter((option) => {
        return option?.value === product?.seguradoraId;
      });
      if ((insuranceCompany?.length ?? -1) > 0) {
        setForm({
          ...form,
          SeguradoraId: {
            ...form.SeguradoraId,
            value: (insuranceCompany ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [insuranceCompaniesOptions, product]);

  useEffect(() => {
    if ((indexesOptions?.length ?? -1) > 0 && (product ?? false)) {
      const index = indexesOptions?.filter((option) => {
        return option?.value === product?.indiceId;
      });
      if ((index?.length ?? -1) > 0) {
        setForm({
          ...form,
          IndiceId: {
            ...form.IndiceId,
            value: (index ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [indexesOptions, product]);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {fetchingError && <ErrorMsg error={fetchingError} />}

        {fetchingProduct && <Loading />}
        {!fetchingError && !fetchingProduct && (
          <>
            <Typography variant="h5" component="h2" color="textSecondary">
              {isCreation ? (
                <>
                  Cadastro de Produto{' '}
                  <Button
                    LinkComponent={Link}
                    href={`/products/full-product-create/`}
                    variant="contained"
                    style={{
                      marginLeft: theme.spacing(1),
                      color: theme.palette.common.white,
                    }}
                    startIcon={
                      <Add style={{ color: theme.palette.common.white }} />
                    }
                  >
                    <Typography variant="button" color="white">
                      Cadastro Detalhado
                    </Typography>
                  </Button>
                </>
              ) : (
                'Edição de Produto'
              )}
            </Typography>

            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                gap: theme.spacing(2),
                flexDirection: 'column',
              }}
            >
              <ErrorMsg error={error} />
              <TextField field={form.Nome} extraAttrs={extraAttrs} />
              <TextField field={form.Descricao} extraAttrs={extraAttrs} />
              <HalfedBox>
                <SearchField
                  extraAttrs={{ value: form.InstituicaoFinanceiraId.value }}
                  options={financialInstitutionsOptions}
                  label={form.InstituicaoFinanceiraId.label}
                  required={
                    form.InstituicaoFinanceiraId.required ? true : false
                  }
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      InstituicaoFinanceiraId: {
                        ...form.InstituicaoFinanceiraId,
                        value,
                      },
                    });
                  }}
                />
                <SearchField
                  extraAttrs={{ value: form.SeguradoraId.value }}
                  options={insuranceCompaniesOptions}
                  label={form.SeguradoraId.label}
                  required={form.SeguradoraId.required ? true : false}
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      SeguradoraId: {
                        ...form.SeguradoraId,
                        value,
                      },
                    });
                  }}
                />
              </HalfedBox>
              <SearchField
                extraAttrs={{ value: form.IndiceId.value }}
                options={indexesOptions}
                label={form.IndiceId.label}
                required={form.IndiceId.required ? true : false}
                loading={loading}
                handleChange={(value: SelectOption) => {
                  setError(undefined);
                  setForm({
                    ...form,
                    IndiceId: {
                      ...form.IndiceId,
                      value,
                    },
                  });
                }}
              />
              <TextField
                field={form.NivelDeCobertura}
                extraAttrs={extraAttrs}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FormGroup style={{ display: 'flex' }}>
                  <FormControlLabel
                    sx={{
                      maxWidth: 618,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.PagamentoLinear.name}
                        onChange={handleChange}
                        checked={form.PagamentoLinear.value}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.PagamentoLinear.label}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    display: 'flex',
                  }}
                >
                  <FormControlLabel
                    sx={{
                      maxWidth: 618,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.NivelDeCoberturaVariavel.name}
                        onChange={handleChange}
                        checked={form.NivelDeCoberturaVariavel.value}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.NivelDeCoberturaVariavel.label}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup style={{ display: 'flex' }}>
                  <FormControlLabel
                    sx={{
                      maxWidth: 618,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.Ativo.name}
                        checked={form.Ativo.value}
                        onChange={handleChange}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.Ativo.label}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>
              <Grid
                container
                spacing={0}
                gap={2}
                direction="row"
                justifyContent="center"
                alignItems="start"
                alignContent="center"
                wrap="wrap"
              >
                <ImageField
                  setField={(field: FileFieldType) => {
                    setSubProduto(field);
                    setError(undefined);
                  }}
                  field={subProduto}
                  allowedFileTypes={SUPORTED_CSV_FIELD_TYPES}
                />
                <ImageField
                  setField={(field: FileFieldType) => {
                    setHistoricoProduto(field);
                    setError(undefined);
                  }}
                  field={historicoProduto}
                  allowedFileTypes={SUPORTED_CSV_FIELD_TYPES}
                />
              </Grid>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={loading}
                  disabled={error ? true : undefined}
                  endIcon={
                    <ArrowForwardIcon
                      sx={{ color: theme.palette.common.white }}
                    />
                  }
                >
                  <Typography variant="button" color="white">
                    {isCreation ? 'Cadastrar' : 'Salvar'}
                  </Typography>
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Grid>
    </Layout>
  );
};
