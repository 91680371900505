import {
  initField,
  maskCPF,
  maskCEP,
  maskCellphone,
  maskCNPJ,
} from '../../utils';
import {
  TextFieldType,
  SelectFieldType,
  FileFieldType,
  AccountantFormType,
  Accountant,
} from '../../types';

export const initForm = ({ data }: { data?: Accountant }) => {
  const form: AccountantFormType = {
    ProdutoId: initField<SelectFieldType>({
      name: 'ProdutoId',
      label: 'Produto',
      value: null,
      required: true,
      extractValue: true,
    }),
    EstadoId: initField<TextFieldType>({
      name: 'EstadoId',
      label: 'Estado',
      value: data?.estadoId ? data?.estadoId : '',
      required: true,
    }),
    CidadeId: initField<TextFieldType>({
      name: 'CidadeId',
      label: 'Cidade',
      value: data?.cidadeId ? data?.cidadeId ?? '' : '',
      required: true,
    }),
    SeguradoraId: initField<SelectFieldType>({
      name: 'SeguradoraId',
      value: null,
      label: 'Seguradora',
      required: true,
      extractValue: true,
    }),
    SubProductId: initField<SelectFieldType>({
      name: 'SubProductId',
      label: 'Cidade e Estado',
      value: null,
      required: true,
      extractValue: true,
      excludeFromPayload: true,
    }),
    ClienteId: initField<SelectFieldType>({
      name: 'ClienteId',
      label: 'Cliente',
      value: null,
      required: false,
      extractValue: true,
    }),
    CulturaId: initField<SelectFieldType>({
      name: 'CulturaId',
      label: 'Cultura',
      value: null,
      required: true,
      extractValue: true,
    }),
    InstituicaoFinanceiraId: initField<SelectFieldType>({
      name: 'InstituicaoFinanceiraId',
      label: 'Instituicao Financeira',
      value: null,
      required: false,
      extractValue: true,
    }),
    AgenciaId: initField<SelectFieldType>({
      name: 'AgenciaId',
      label: 'Agência',
      value: null,
      required: false,
      extractValue: true,
    }),
    Area: initField<TextFieldType>({
      name: 'Area',
      label: 'Area (ha)',
      type: 'number',
      value: data?.area ? data.area : '0',
      required: true,
    }),
    NivelDeCobertura: initField<TextFieldType>({
      name: 'NivelDeCobertura',
      label: 'Nivel de Cobertura (%)',
      value: data?.nivelDeCobertura ? data.nivelDeCobertura : '0',
      required: true,
    }),
    ValorDaCobertura: initField<TextFieldType>({
      name: 'ValorDaCobertura',
      label: 'Valor da Cobertura (R$)',
      value: data?.nivelDeCobertura ? data.nivelDeCobertura : '0',
      required: true,
    }),
    Taxa: initField<TextFieldType>({
      name: 'Taxa',
      label: 'Taxa (%)',
      value: data?.taxa ? data.taxa : '0',
      required: true,
    }),
    ImportanciaSeguradaPorHA: initField<TextFieldType>({
      name: 'ImportanciaSeguradaPorHA',
      label: 'Importancia SeguradaPor Ha (R$)',
      value: data?.importanciaSeguradaPorHA
        ? data.importanciaSeguradaPorHA
        : '0',
      required: true,
      type: 'number',
    }),
    ImportanciaSeguradaTotal: initField<TextFieldType>({
      name: 'ImportanciaSeguradaTotal',
      label: 'Importancia Segurada Total',
      value: data?.importanciaSeguradaTotal
        ? data.importanciaSeguradaTotal
        : '0',
      required: true,
    }),
    Premio: initField<TextFieldType>({
      name: 'Premio',
      label: 'Premio (R$)',
      value: data?.premio ? data.premio : '0',
      required: true,
    }),
    EmailCliente: initField<TextFieldType>({
      name: 'EmailCliente',
      label: 'Email do Cliente',
      value: data?.emailCliente ? data.emailCliente : '',
      required: true,
    }),
    NomeCliente: initField<TextFieldType>({
      name: 'NomeCliente',
      label: 'Nome do Cliente',
      value: data?.nomeCliente ? data.nomeCliente : '',
      required: true,
    }),
    CPFCliente: initField<TextFieldType>({
      name: 'CPFCliente',
      label: 'CPF do Cliente',
      value: data?.cpfCliente ? data.cpfCliente : '',
      mask: maskCPF,
      required: true,
    }),
    NomeFazendaCliente: initField<TextFieldType>({
      name: 'NomeFazendaCliente',
      label: 'Nome da Fazenda do Cliente',
      value: data?.nomeFazendaCliente ? data.nomeFazendaCliente : '',
      required: false,
    }),
    TelefoneCliente: initField<TextFieldType>({
      name: 'TelefoneCliente',
      label: 'Telefone',
      value: data?.telefoneCliente ? data.telefoneCliente : '',
      mask: maskCellphone,
      required: false,
    }),
    CEPCliente: initField<TextFieldType>({
      name: 'CEPCliente',
      label: 'CEP',
      value: data?.cepCliente ? data.cepCliente : '',
      mask: maskCEP,
      required: true,
    }),
    UFCliente: initField<TextFieldType>({
      name: 'UFCliente',
      label: 'UF',
      value: data?.ufCliente ? data.ufCliente : '',
      required: true,
    }),
    EnderecoCliente: initField<TextFieldType>({
      name: 'EnderecoCliente',
      label: 'Logradouro',
      value: data?.enderecoCliente ? data.enderecoCliente : '',
      required: false,
    }),
    NumeroEnderecoCliente: initField<TextFieldType>({
      name: 'NumeroEnderecoCliente',
      label: 'Numero',
      value: data?.numeroEnderecoCliente ? data.numeroEnderecoCliente : '',
      required: false,
    }),
    ComplementoCliente: initField<TextFieldType>({
      name: 'ComplementoCliente',
      label: 'Complemento',
      value: data?.complementoCliente ? data.complementoCliente : '',
      required: false,
    }),
    BairroCliente: initField<TextFieldType>({
      name: 'BairroCliente',
      label: 'Bairro',
      value: data?.bairroCliente ? data.bairroCliente : '',
      required: false,
    }),
    CidadeCliente: initField<TextFieldType>({
      name: 'CidadeCliente',
      label: 'Cidade',
      value: data?.cidadeCliente ? data.cidadeCliente : '',
      required: true,
    }),
    ComprovanteResidencia: initField<FileFieldType>({
      name: 'ComprovanteResidencia',
      value: null,
    }),
    ComprovanteKML: initField<FileFieldType>({
      name: 'ComprovanteKML',
      value: null,
    }),
    ComprovanteCNH: initField<FileFieldType>({
      name: 'ComprovanteCNH',
      value: null,
    }),
    CNPJBeneficiario: initField<TextFieldType>({
      name: 'CNPJBeneficiario',
      label: 'CNPJ do Beneficiario',
      value: data?.cnpjBeneficiario ? data.cnpjBeneficiario : '',
      required: true,
      mask: maskCNPJ,
    }),
    NomeBeneficiario: initField<TextFieldType>({
      name: 'NomeBeneficiario',
      label: 'Nome do Beneficiario',
      value: data?.nomeBeneficiario ? data.nomeBeneficiario : '',
      required: true,
    }),
  };

  if (data?.cotadorId ?? false) {
    form.CotadorId = initField<TextFieldType>({
      name: 'CotadorId',
      value: data?.cotadorId as string,
    });
  }

  return form;
};
