import React from 'react';
import { providers } from './providers';

function ProviderComposer({
  contexts,
  children,
}: {
  contexts: Array<JSX.Element>;
  children: JSX.Element;
}) {
  return contexts.reduceRight(
    // eslint-disable-next-line
    (kids: any, parent: any) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children
  );
}

type MasterProviderProps = {
  children: JSX.Element;
};

export function MasterProvider({ children }: MasterProviderProps) {
  return <ProviderComposer contexts={providers}>{children}</ProviderComposer>;
}
