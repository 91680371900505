import React, { useState, useEffect } from 'react';
import { Dayjs } from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
/* import FormGroup from '@mui/material/FormGroup'; */
import MTextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid';

import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import {
  useProductHistory,
  useProduct,
  useCore,
} from '../../contexts/contexts';
import { handleErrorMessage, serializePayload } from '../../utils';
import {
  ProductHistoryPayload,
  ProductHistoryFormType,
  ProductHistory,
  SelectOption,
  SelectFieldType,
} from '../../types';
import { Form } from './styled';
import { initForm } from './utils';
/* import {
  ServiceTermsCheckbox,
  AuthorizationCheckbox,
} from '../../components/FormCheckboxes'; */
import { Loading } from '../../components/Loading';
import { SearchField } from '../../components/SearchField';

export const ProductHistoryForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const {
    productsHistories,
    setProductsHistories,
    productHistoryError,
    productHistoryLoading,
    productHistoryApi,
  } = useProductHistory();
  const { productsOptions, productError, getProductsOptions } = useProduct();

  const {
    stateLoading,
    stateError,
    cityError,
    stateOptions,
    fetchStateOptions,
    cityOptions,
    cityLoading,
    fetchCityOptions,
  } = useCore();

  const fetchLoading = productHistoryLoading || stateLoading;
  const fetchError =
    productHistoryError || stateError || cityError || productError;

  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<string[] | string>();
  const [form, setForm] = useState<ProductHistoryFormType>(
    initForm({}) as ProductHistoryFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof ProductHistoryFormType];
    auxForm[e.target.name as keyof ProductHistoryFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleDateChange = (
    fieldName: keyof ProductHistoryFormType,
    newValue: Dayjs | null
  ) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[fieldName as keyof ProductHistoryFormType];
    auxForm[fieldName] = {
      ...field,
      value: newValue,
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = serializePayload<
        ProductHistoryFormType,
        ProductHistoryPayload
      >(form);
      if (payload?.HistoricoProdutoId ?? false) {
        const data = await productHistoryApi.editProductHistory(payload);
        if ((productsHistories?.length ?? -1) > 0) {
          const auxProductsHistories = [
            ...(productsHistories ?? []).map(
              (productHistory: ProductHistory) => {
                if (
                  productHistory.historicoProdutoId === data.historicoProdutoId
                ) {
                  return data;
                }
                return productHistory;
              }
            ),
          ];
          setProductsHistories(auxProductsHistories);
        }
      } else {
        const data = await productHistoryApi.addProductHistory(payload);
        if ((productsHistories?.length ?? -1) > 0)
          setProductsHistories((productsHistories ?? []).concat([data]));
      }
      navigate('/productsHistories');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const initFormData = async () => {
    if (id ?? false) {
      const initialData = await productHistoryApi.getProductHistoryById(
        id ?? ''
      );
      const auxForm = initForm({ data: initialData }) as ProductHistoryFormType;
      setForm(auxForm);
    }
  };

  useEffect(() => {
    getProductsOptions();
    fetchStateOptions();
    initFormData();
  }, []);

  const selectHandleChange = ({
    value,
    field,
  }: {
    value: SelectOption;
    field: SelectFieldType;
  }) => {
    setError(undefined);
    setForm({
      ...form,
      [field.name]: { ...field, value: value },
    });
  };

  const extraAttrs = {
    disabled: fetchLoading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        <Typography variant="h5" component="h2" color="textSecondary">
          {isCreation
            ? 'Cadastro de Histórico Climático do Município'
            : 'Edição de Histórico Climático do Município'}
        </Typography>
        {fetchLoading ? (
          <Loading />
        ) : (
          <>
            {fetchError ? (
              <ErrorMsg error={fetchError} />
            ) : (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  flexDirection: 'column',
                }}
              >
                <ErrorMsg error={error} />
                <SearchField
                  extraAttrs={{ value: form.ProdutoId.value }}
                  options={productsOptions}
                  label={form.ProdutoId.label}
                  required={form.ProdutoId.required ? true : false}
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    selectHandleChange({ value, field: form.ProdutoId });
                  }}
                />
                <SearchField
                  extraAttrs={{ value: form.EstadoId.value }}
                  options={stateOptions}
                  label={form.EstadoId.label}
                  required={form.EstadoId.required ? true : false}
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    selectHandleChange({ value, field: form.EstadoId });
                    fetchCityOptions(value?.value ?? '');
                  }}
                />
                <SearchField
                  extraAttrs={{ value: form.CidadeId.value }}
                  options={cityOptions}
                  label={form.CidadeId.label}
                  required={form.CidadeId.required ? true : false}
                  loading={cityLoading}
                  handleChange={(value: SelectOption) => {
                    selectHandleChange({ value, field: form.CidadeId });
                  }}
                />
                <DesktopDatePicker
                  label={form.Data.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.Data.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.Data.name as keyof ProductHistoryFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
                <TextField field={form.Valor} extraAttrs={extraAttrs} />
                {/* {!isCreation && (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    sx={{
                      marginBottom: theme.spacing(3),
                      marginTop: theme.spacing(2),
                    }}
                  >
                    <FormGroup style={{ display: 'flex' }}>
                      <ServiceTermsCheckbox />
                      <AuthorizationCheckbox />
                    </FormGroup>
                  </Grid>
                )} */}
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={loading}
                    disabled={error ? true : undefined}
                    endIcon={
                      <ArrowForwardIcon
                        sx={{ color: theme.palette.common.white }}
                      />
                    }
                  >
                    <Typography variant="button" color="white">
                      {isCreation ? 'Cadastrar' : 'Salvar'}
                    </Typography>
                  </Button>
                </Box>
              </Form>
            )}
          </>
        )}
      </Grid>
    </Layout>
  );
};
