import React, { createContext, useState, useContext } from 'react';
import InsuranceCompanyService, {
  InsuranceCompanyApi,
} from '../services/InsuranceCompanyService';

/* import InsuranceCompanyService from '../services/InsuranceCompanyService'; */

import { InsuranceCompany } from '../types';
import { useAuth } from './contexts';

export type InsuranceCompanyContextType = {
  insuranceCompanies: Array<InsuranceCompany>;
  setInsuranceCompanies: React.Dispatch<
    React.SetStateAction<Array<InsuranceCompany> | undefined>
  >;
  insuranceCompanyApi: InsuranceCompanyApi;
};

export const InsuranceCompanyContext =
  createContext<InsuranceCompanyContextType>(
    {} as unknown as InsuranceCompanyContextType
  );

export const InsuranceCompanyProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [insuranceCompanies, setInsuranceCompanies] =
    useState<Array<InsuranceCompany>>();

  const { token } = useAuth();

  const insuranceCompanyApi = new InsuranceCompanyService({
    basePath: '/v1/seguradora',
    token,
  });

  return (
    <InsuranceCompanyContext.Provider
      value={
        {
          insuranceCompanyApi,
          insuranceCompanies,
          setInsuranceCompanies,
        } as InsuranceCompanyContextType
      }
    >
      {children}
    </InsuranceCompanyContext.Provider>
  );
};

export const useInsuranceCompany = () => useContext(InsuranceCompanyContext);
