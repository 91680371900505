import {
  FinancialAgency,
  FinancialAgencyPayload,
  PaginatedResponse,
} from '../types';
import { BaseClient } from './BaseService';

export type MeAgencyResponse = {
  agenciaId?: string | null;
  cidade?: null | string;
  cnpj?: null | string;
  deleted: boolean;
  email?: string | null;
  instituicaoFinanceiraId?: string | null;
  nome?: string | null;
  numeroEndereco?: null | string;
  telefone?: string | null;
  uf?: null | string;
};

export interface FinancialAgencyApi {
  getFinancialAgencyById(id: string): Promise<FinancialAgency>;
  getFinancialAgencies(): Promise<Array<FinancialAgency>>;
  getPagedFinancialAgencies(
    page: number
  ): Promise<PaginatedResponse<FinancialAgency>>;
  deleteFinancialAgency(id: string): Promise<void>;
  editFinancialAgency(
    payload: FinancialAgencyPayload
  ): Promise<FinancialAgency>;
  addFinancialAgency(payload: FinancialAgencyPayload): Promise<FinancialAgency>;
  getMeAgency: () => Promise<any>;
}

class FinancialAgencyService extends BaseClient implements FinancialAgencyApi {
  async getFinancialAgencyById(id: string): Promise<FinancialAgency> {
    const { data } = await this.api.get(`/${id}/`);
    return data as FinancialAgency;
  }

  async getFinancialAgencies(): Promise<Array<FinancialAgency>> {
    const { data } = await this.api.get(`/`);
    return data as Array<FinancialAgency>;
  }

  async getPagedFinancialAgencies(
    page: number
  ): Promise<PaginatedResponse<FinancialAgency>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<FinancialAgency>;
  }

  async deleteFinancialAgency(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editFinancialAgency(
    payload: FinancialAgencyPayload
  ): Promise<FinancialAgency> {
    const { data } = await this.api.put(`/`, payload);
    return data.agencia as FinancialAgency;
  }

  async addFinancialAgency(
    payload: FinancialAgencyPayload
  ): Promise<FinancialAgency> {
    const { data } = await this.api.post(`/`, payload);
    return data.agencia as FinancialAgency;
  }

  async getMeAgency(): Promise<MeAgencyResponse> {
    const { data } = await this.api.get(`/me`);
    return data as MeAgencyResponse;
  }
}

export default FinancialAgencyService;
