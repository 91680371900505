import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { SearchField } from '../../components/SearchField';
import { handleErrorMessage, serializePayload } from '../../utils';
import {
  FinancialAgencyPayload,
  FinancialAgencyFormType,
  FinancialAgency,
  SelectOption,
  TextFieldType,
} from '../../types';
import { Form } from './styled';

import { initForm } from './utils';

import {
  useAuth,
  useCore,
  useFinancialInstitution,
  useFinancialAgency,
} from '../../contexts/contexts';

const HalfedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
}));

export const FinancialAgencyForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const { user } = useAuth();
  const { fetchCepData } = useCore();
  const theme = useTheme();
  const navigate = useNavigate();
  const { financialInstitutionApi } = useFinancialInstitution();
  const [error, setError] = useState<string[] | string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [financialAgency, setFinancialAgency] = useState<
    FinancialAgency | undefined
  >();
  const { financialAgencies, setFinancialAgencies, financialAgencyApi } =
    useFinancialAgency();
  const [financialInstitutionsOptions, setFinancialInstitutionsOptions] =
    useState<Array<SelectOption>>();
  const { id } = useParams();

  const [fetchingFinancialAgency, setFetchingFinancialAgency] =
    useState<boolean>(!isCreation);
  const [fetchingError, setFetchingError] = useState<string>();

  const [form, setForm] = useState<FinancialAgencyFormType>(
    initForm({ isCreation }) as FinancialAgencyFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof FinancialAgencyFormType];
    auxForm[e.target.name as keyof FinancialAgencyFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = serializePayload<
        FinancialAgencyFormType,
        FinancialAgencyPayload
      >(form) as FinancialAgencyPayload;
      if (payload?.AgenciaId ?? false) {
        const data = await financialAgencyApi.editFinancialAgency(payload);
        if ((financialAgencies?.length ?? -1) > 0) {
          const auxFinancialAgencies = [
            ...financialAgencies?.map((financialAgency: FinancialAgency) => {
              if (financialAgency.agenciaId === data.agenciaId) {
                return data;
              }
              return financialAgency;
            }),
          ];
          setFinancialAgencies(auxFinancialAgencies);
        }
      } else {
        const data = await financialAgencyApi.addFinancialAgency(payload);
        if ((financialAgencies?.length ?? -1) > 0)
          setFinancialAgencies(financialAgencies.concat([data]));
      }
      navigate('/financial-agencies');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const getFinancialAgency = async () => {
    if (id ?? false) {
      setFetchingError(undefined);
      try {
        setFetchingFinancialAgency(true);
        const data: FinancialAgency =
          await financialAgencyApi.getFinancialAgencyById(id as string);
        setForm(initForm({ data, isCreation }) as FinancialAgencyFormType);
        setFinancialAgency(data);
      } catch (e: unknown) {
        setFetchingError(handleErrorMessage(e));
      } finally {
        setFetchingFinancialAgency(false);
      }
    }
  };

  const getFinancialInstitutionsOptions = async () => {
    try {
      setLoading(true);
      const data = await financialInstitutionApi.getFinancialInstitutions();
      setFinancialInstitutionsOptions(
        data.map(
          (financialInstitution) =>
            ({
              label: financialInstitution.nome,
              value: financialInstitution.instituicaoFinanceiraId,
            } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFinancialAgency();
    getFinancialInstitutionsOptions();
  }, []);

  useEffect(() => {
    if (
      (financialInstitutionsOptions?.length ?? -1) > 0 &&
      (financialAgency ?? false)
    ) {
      const financialInstitution = financialInstitutionsOptions?.filter(
        (option) => {
          return option?.value === financialAgency?.instituicaoFinanceiraId;
        }
      );
      if ((financialInstitution?.length ?? -1) > 0) {
        setForm({
          ...form,
          InstituicaoFinanceiraId: {
            ...form.InstituicaoFinanceiraId,
            value: (financialInstitution ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [financialInstitutionsOptions, financialAgency]);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {fetchingError && fetchingError}
        <Typography variant="h5" component="h2" color="textSecondary">
          {isCreation
            ? 'Cadastro de Agência / Filiais'
            : 'Edição de Agência / Filiais'}
        </Typography>
        {fetchingFinancialAgency && <Loading />}
        {!fetchingFinancialAgency && (
          <Form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              gap: theme.spacing(2),
              flexDirection: 'column',
            }}
          >
            <ErrorMsg error={error} />
            <HalfedBox>
              {isCreation && (
                <TextField
                  field={form.Email as TextFieldType}
                  extraAttrs={extraAttrs}
                />
              )}
              {(isCreation || user.role === 'admin') && (
                <SearchField
                  extraAttrs={{ value: form.InstituicaoFinanceiraId.value }}
                  options={financialInstitutionsOptions}
                  label={form.InstituicaoFinanceiraId.label}
                  required={
                    form.InstituicaoFinanceiraId.required ? true : false
                  }
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      InstituicaoFinanceiraId: {
                        ...form.InstituicaoFinanceiraId,
                        value,
                      },
                    });
                  }}
                />
              )}
            </HalfedBox>
            <TextField field={form.Nome} extraAttrs={extraAttrs} />
            <HalfedBox>
              <TextField field={form.CNPJ} extraAttrs={extraAttrs} />
              <TextField field={form.Telefone} extraAttrs={extraAttrs} />
            </HalfedBox>
            <HalfedBox>
              <TextField field={form.Senha} extraAttrs={extraAttrs} />
              <TextField field={form.ConfirmaSenha} extraAttrs={extraAttrs} />
            </HalfedBox>

            <HalfedBox>
              <Box
                style={{
                  width: '100%',
                  gap: theme.spacing(2),
                  marginBottom: theme.spacing(3),
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    gap: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <TextField
                    field={form.CEP}
                    extraAttrs={{
                      ...extraAttrs,
                      onBlur: () => fetchCepData(form.CEP.value, form, setForm),
                    }}
                  />
                  <TextField field={form.UF} extraAttrs={extraAttrs} />
                </Box>
                <HalfedBox>
                  <TextField field={form.Complemento} extraAttrs={extraAttrs} />
                  <TextField field={form.Bairro} extraAttrs={extraAttrs} />
                </HalfedBox>
              </Box>
              <HalfedBox>
                <Box style={{ width: '100%' }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: theme.spacing(2),
                      width: '100%',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <TextField field={form.Endereco} extraAttrs={extraAttrs} />
                    <TextField
                      field={form.NumeroEndereco}
                      extraAttrs={extraAttrs}
                    />
                  </Box>
                  <TextField field={form.Cidade} extraAttrs={extraAttrs} />
                </Box>
              </HalfedBox>
            </HalfedBox>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                loading={loading}
                disabled={error ? true : undefined}
                endIcon={
                  <ArrowForwardIcon
                    sx={{ color: theme.palette.common.white }}
                  />
                }
              >
                <Typography variant="button" color="white">
                  {isCreation ? 'Cadastrar' : 'Salvar'}
                </Typography>
              </Button>
            </Box>
          </Form>
        )}
      </Grid>
    </Layout>
  );
};
