import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  GoogleMap,
  useJsApiLoader,
  Polygon,
  PolygonProps,
  LoadScriptProps,
} from '@react-google-maps/api';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler,
  LineController,
  BarController,
  ChartOptions,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

import { useTheme } from '@mui/material/styles';
import { green, orange } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import GrassIcon from '@mui/icons-material/Grass';
import PublicIcon from '@mui/icons-material/Public';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler,
  LineController,
  BarController
);
import { EmptyList } from '../../components/EmptyList';
import { Loading } from '../../components/Loading';
import { Sidebar } from '../../components/Sidebar';
import { AppBar } from '../../components/AppBar';
import { DownloadLinkButton } from '../../components/DownloadLinkButton';

import {
  useAccountant,
  useAuth,
  useBroker,
  useCore,
  useFinancialInstitution,
  useProduct,
} from '../../contexts';
import {
  handleErrorMessage,
  filterValidKeys,
  formatCurrency,
} from '../../utils';
import {
  Proposal,
  ProposalHistory,
  ProposalHistorySubProduct,
  SelectOption,
  AccountantFilter,
  City,
} from '../../types';
import { ErrorMsg } from '../../components/ErrorMsg';
import { SearchField } from '../../components/SearchField';
import { Person } from '@mui/icons-material';
import { Chip, Grid, TextField } from '@mui/material';

import { BrazilCoordinates, getBoundsZoomLevel } from '../../utils/googleMaps';
import { DesktopDatePicker } from '@mui/x-date-pickers';

const libraries = ['drawing', 'geometry'] as LoadScriptProps['libraries'];

export const options = {
  responsive: true,
  layout: {
    padding: 16,
  },
  plugins: {
    legend: {
      position: 'top' as const,
    },
    datalabels: {
      display: true,
      align: 'center',
      anchor: 'center',
      formatter: function (value: string) {
        if (parseFloat(value) > 0) {
          return value;
        } else {
          return null;
        }
      },
    },
  },
};

export type PolygonFillColor = 'aqua' | 'orangeRed' | 'orange' | 'red';

export type CityWithCoordinates = {
  cidadeId: string;
  coordenadaCidade: Array<google.maps.LatLngLiteral>;
  cidadeNome: string;
  color: PolygonFillColor;
};

export const ProposalList = () => {
  const theme = useTheme(); // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth();
  const { accountantApi } = useAccountant();
  const { getFinancialInstitutionsOptions, financialInstitutionsOptions } =
    useFinancialInstitution();
  const { getBrokersOptions, brokersOptions } = useBroker();
  const { productApi } = useProduct();
  const { getCity } = useCore();

  const [proposals, setProposals] = useState<Proposal[]>();
  const [proposalsError, setProposalsError] = useState<string>();

  const [filteredProposals, setFilteredProposals] = useState<
    Proposal[] | null | undefined
  >(proposals);

  const [cities, setCities] = useState<City[] | null | undefined>([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAF8QP6R3rJFZLZJcEsee3B3a1wz6Pul8E',
    libraries,
  });

  const [, setLoadingMap] = useState<boolean>(true);

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [citiesWithCoordinates, setCitiesWithCoordinates] = React.useState<
    CityWithCoordinates[]
  >([]);

  const [selectedCity, setSelectedCity] = React.useState<string>();
  const [selectedProposal, setSelectedProposal] = React.useState<string>();
  const [cityLoading, setCityLoading] = React.useState<boolean>(true);

  const getDistinctCities = async (items: Proposal[] | null | undefined) => {
    setCityLoading(true);
    const citiesIds = (items ?? []).reduce((acc: Array<string>, proposal) => {
      proposal?.proposalHistory.subProduto.map((subProduct) => {
        if (!acc.find((city) => city === subProduct.cidadeId)) {
          acc.push(subProduct.cidadeId);
        }
      });
      if (acc.find((city) => city === proposal.cidadeId)) {
        return acc;
      }
      acc.push(proposal.cidadeId);
      return acc;
    }, []);
    const cities: Array<City> = [];
    for (let i = 0; i < citiesIds.length; i++) {
      const city = await getCity(citiesIds[i]);
      if (city) {
        cities.push(city as City);
      }
    }
    setCities(cities ?? []);
    setCityLoading(false);
  };

  const onLoad = React.useCallback(function callback(
    map: google.maps.Map | null
  ) {
    setMap(map);
    setLoadingMap(false);
  },
    []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const proposalsCount = proposals?.length ?? -1;

  const getProposals = async () => {
    try {
      setProposalsError(undefined);
      setLoading(true);
      const data = await accountantApi.getMeAccountants();
      const auxData: Proposal[] = [];
      const historiesCache: Array<{
        produtoId: string;
        estadoId: string;
        cidadeId: string;
        data: ProposalHistory;
      }> = [];
      for (let i = 0; i < data.length; i += 1) {
        let history: ProposalHistory;
        const filteredHistory = historiesCache.filter((item) => {
          if (
            item.produtoId === data[i].produtoId &&
            item.estadoId === data[i].estadoId &&
            item.cidadeId === data[i].cidadeId
          ) {
            return true;
          }
          return false;
        });
        if (filteredHistory.length > 0) {
          history = filteredHistory[0].data;
        } else {
          history = await productApi.getProductHistory(
            data[i].produtoId,
            data[i].estadoId,
            data[i].cidadeId
          );
          historiesCache.push({
            produtoId: data[i].produtoId,
            estadoId: data[i].estadoId,
            cidadeId: data[i].cidadeId,
            data: history,
          });
        }
        auxData.push({
          ...data[i],
          proposalHistory: history as ProposalHistory,
        } as Proposal);
      }
      setProposals(auxData);
      getDistinctCities(auxData);
    } catch (e: unknown) {
      setProposalsError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };
  const labels = [`Label1`, `Label2`, `Label3`];

  const removeNoDataLabels = (row: Proposal) => {
    return labels
      .map((label, index) => {
        const auxIndex: number = index + 1;
        const subProduto = row?.proposalHistory?.subProduto[0] ?? null;
        const curStrike1 = parseFloat(
          subProduto[
          ('strike1Gatilho' + auxIndex) as keyof ProposalHistorySubProduct
          ] as string
        );

        const curStrike2 = parseFloat(
          subProduto[
          ('strike2Gatilho' + auxIndex) as keyof ProposalHistorySubProduct
          ] as string
        );

        const curStrike3 = parseFloat(
          subProduto[
          ('strike3Gatilho' + auxIndex) as keyof ProposalHistorySubProduct
          ] as string
        );

        if (curStrike1 != 0 || curStrike2 != 0 || curStrike3 != 0) return label;
      })
      .filter((item) => typeof item !== 'undefined')
      .map(() => `${row.indiceLabelGrafico}`);
  };

  const [filters, setFilters] = React.useState<AccountantFilter>({
    InstituicaoFinanceiraId: null,
    CorretoraId: null,
    CidadeId: null,
    DataFinal: null,
    DataInicio: null,
  });

  const filtersEnabled =
    Object.entries(filterValidKeys(filters ?? {})).length > 0;

  const handleFilter = (filter?: AccountantFilter) => {
    if (typeof filter !== 'undefined') setFilters(filter);
    const validFilters = filterValidKeys(
      filter ?? filters ?? {}
    ) as AccountantFilter;
    let auxProposals = proposals;
    for (const [key, value] of Object.entries(validFilters)) {
      switch (key) {
        case 'InstituicaoFinanceiraId':
          auxProposals = auxProposals?.filter((item: Proposal) => {
            const auxValue = value as SelectOption;
            return (
              item.instituicaoFinanceiraId.toString() === auxValue?.value ??
              null
            );
          });
          break;
        case 'CorretoraId':
          auxProposals = auxProposals?.filter((item: Proposal) => {
            const auxValue = value as SelectOption;
            return item.corretoraId.toString() === auxValue?.value ?? null;
          });
          break;
        case 'Estado':
          auxProposals = auxProposals?.filter((item: Proposal) => {
            const auxValue = value as string;
            return item.siglaEstado === auxValue ?? null;
          });
          break;
        case 'CidadeId':
          auxProposals = auxProposals?.filter((item: Proposal) => {
            const auxValue = value as SelectOption;
            if (auxValue === null) return false;
            return item.cidadeId == auxValue?.value;
          });
          break;
        case 'DataInicio':
          auxProposals = auxProposals?.filter((item) => {
            return dayjs(item.dataInicio, 'YYYY-MM-DD').isSameOrAfter(
              value as Dayjs
            );
          });
          break;
        case 'DataFinal':
          auxProposals = auxProposals?.filter((item) => {
            return dayjs(item.dataFinal, 'YYYY-MM-DD').isSameOrBefore(
              value as Dayjs
            );
          });
          break;
      }
    }
    setFilteredProposals(auxProposals);
  };

  const handleClearFilters = () => {
    setFilters({
      InstituicaoFinanceiraId: null,
      CorretoraId: null,
      DataInicio: null,
      DataFinal: null,
    });
    setFilteredProposals(proposals);
    setSelectedCity(undefined);
  };

  const fitMapToCity = (cidadeId: string, zoomParam?: number) => {
    setSelectedCity(cidadeId);
    const polygon = new google.maps.Polygon({
      paths: citiesWithCoordinates.find(
        (cityWithCoordinates) => cityWithCoordinates.cidadeId === cidadeId
      )?.coordenadaCidade,
    });
    const bounds = new google.maps.LatLngBounds();
    const paths = polygon.getPaths();
    let path;
    for (let i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (let ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    map?.fitBounds(bounds);
    let zoom = map?.getZoom() || 10;
    zoom = zoomParam || zoom;
    map?.setZoom(zoom > 10 ? 10 : zoom + 1);
  };

  useEffect(() => {
    handleFilter();
  }, [proposals]);

  const getSubProductColor = (subProduct: ProposalHistorySubProduct) => {
    let color = 'aqua';
    if (subProduct.somaGatilho3 != 0) {
      if (
        subProduct.strike3Gatilho3 != 0 &&
        subProduct.somaGatilho3 < subProduct.strike3Gatilho3
      ) {
        color = 'red';
      } else if (
        subProduct.strike2Gatilho3 != 0 &&
        subProduct.somaGatilho3 < subProduct.strike2Gatilho3
      ) {
        color = 'orange';
      } else if (
        subProduct.strike1Gatilho3 != 0 &&
        subProduct.somaGatilho3 < subProduct.strike1Gatilho3
      ) {
        color = 'orangeRed';
      }
    } else if (subProduct.somaGatilho2 != 0) {
      if (
        subProduct.strike3Gatilho2 != 0 &&
        subProduct.somaGatilho2 < subProduct.strike3Gatilho2
      ) {
        color = 'red';
      } else if (
        subProduct.strike2Gatilho2 != 0 &&
        subProduct.somaGatilho2 < subProduct.strike2Gatilho2
      ) {
        color = 'orange';
      } else if (
        subProduct.strike1Gatilho2 != 0 &&
        subProduct.somaGatilho2 < subProduct.strike1Gatilho2
      ) {
        color = 'orangeRed';
      }
    } else {
      if (
        subProduct.strike3Gatilho1 != 0 &&
        subProduct.somaGatilho1 < subProduct.strike3Gatilho1
      ) {
        color = 'red';
      } else if (
        subProduct.strike2Gatilho1 != 0 &&
        subProduct.somaGatilho1 < subProduct.strike2Gatilho1
      ) {
        color = 'orange';
      } else if (
        subProduct.strike1Gatilho1 != 0 &&
        subProduct.somaGatilho1 < subProduct.strike1Gatilho1
      ) {
        color = 'orangeRed';
      }
    }
    return color as PolygonFillColor;
  };

  useEffect(() => {
    const data =
      filteredProposals?.reduce((acc, cur) => {
        if ((cur.proposalHistory.subProduto?.length ?? 0) > 0) {
          const subProduct = cur.proposalHistory.subProduto.filter(
            (subProduct) => subProduct.cidadeId === cur.cidadeId
          )[0];
          if (!subProduct) {
            throw new Error('Major Error: Coordinates could not be found!');
          }
          if (
            (acc.filter((i) => i.cidadeId === subProduct.cidadeId).length ??
              0) <= 0
          ) {
            const coordinates = (
              (JSON.parse(subProduct.coordenadaCidade)[0] ?? [])[0] ?? []
            ).map((coordinate: Array<number>) => {
              return { lng: coordinate[0], lat: coordinate[1] };
            }) as Array<google.maps.LatLngLiteral>;

            acc.push({
              cidadeId: subProduct.cidadeId,
              coordenadaCidade: coordinates,
              cidadeNome: subProduct.cidadeNome,
              color: getSubProductColor(subProduct),
            });
          } else {
            const existingCityWithCoordinate = acc.find(
              (i) => i.cidadeId === subProduct.cidadeId
            ) as CityWithCoordinates;
            const newColor = getSubProductColor(subProduct);

            /* The logic bellow works as a hierarchy of colors
              1. Red
              2. Orange
              3. RedOrange
              4. Aqua

              If the new color is above the currentColor in this hierachy, then update it with the new color
            */

            if (
              newColor === 'red' &&
              existingCityWithCoordinate.color != 'red'
            ) {
              existingCityWithCoordinate.color = newColor;
            } else if (newColor === 'orange') {
              if (
                // if the current color doesnot equals to red or itself, then update it
                !['red', newColor].includes(existingCityWithCoordinate.color)
              ) {
                existingCityWithCoordinate.color = newColor;
              }
            } else if (
              newColor === 'orangeRed' &&
              existingCityWithCoordinate.color === 'aqua'
            ) {
              existingCityWithCoordinate.color = newColor;
            }
            acc = acc.map((i) => {
              if (i.cidadeId === subProduct.cidadeId) {
                return existingCityWithCoordinate;
              }
              return i;
            });
          }
        }
        return acc;
      }, [] as Array<CityWithCoordinates>) ?? [];
    setCitiesWithCoordinates(data);
  }, [filteredProposals]);

  useEffect(() => {
    getFinancialInstitutionsOptions();
    getBrokersOptions();
    getProposals();
  }, []);

  useEffect(() => {
    if (citiesWithCoordinates.length > 0) {
      const polygons = citiesWithCoordinates.map((cityWithCoordinate) => {
        return new google.maps.Polygon({
          paths: cityWithCoordinate.coordenadaCidade,
        });
      });
      const bounds = new google.maps.LatLngBounds();
      polygons.map((polygon) => {
        polygon.getPaths().forEach((path) => {
          path.forEach((latlng) => {
            bounds.extend(latlng);
          });
        });
      });
      map?.fitBounds(bounds);
      const zoom = getBoundsZoomLevel(bounds, {
        width: window.innerWidth / 2,
        height: window.innerHeight,
      });
      map?.setZoom(zoom);
    }
  }, [citiesWithCoordinates]);

  return (
    <>
      <Sidebar />
      <Box>
        {proposalsError ? (
          <ErrorMsg error={proposalsError} />
        ) : (
          <>
            {proposalsCount < 0 && <Loading />}
            {proposalsCount >= 0 && (
              <>
                {proposalsCount === 0 && (
                  <EmptyList
                    img={false}
                    justifyContent="flex-start"
                    title={
                      filtersEnabled
                        ? 'Nenhum registro com esse filtro'
                        : 'Nenhuma proposta cadastrada =('
                    }
                    content=""
                  />
                )}
                {proposalsCount > 0 && (
                  <Box
                    sx={{ position: 'relative', width: '100', display: 'flex' }}
                  >
                    <Box
                      sx={{
                        width: '50vw',
                        height: '100vh',
                        overflowY: 'scroll',
                      }}
                    >
                      <AppBar />

                      <Grid container spacing={3} padding={3}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="h4"
                            component="h2"
                            color="textSecondary"
                          >
                            Acompanhamento dos índices
                          </Typography>

                          <Box
                            sx={{
                              marginTop: theme.spacing(2),
                              marginBottom: theme.spacing(2),
                              display: 'flex',
                              gap: theme.spacing(2),
                              flexWrap: 'wrap',
                            }}
                          >
                            <Box sx={{ display: 'flex', minWidth: 200 }}>
                              <SearchField
                                extraAttrs={{
                                  value: filters.CidadeId,
                                }}
                                fullwidth
                                options={
                                  cities
                                    ?.filter((city) => {
                                      const citiesIds = filteredProposals?.map(
                                        (item) => item.cidadeId
                                      );
                                      if (citiesIds?.includes(city.cidadeId))
                                        return true;
                                      return false;
                                    })
                                    ?.map(
                                      (city) =>
                                      ({
                                        value: city.cidadeId,
                                        label: city.nome,
                                      } as SelectOption)
                                    ) ?? []
                                }
                                label={
                                  cityLoading ? 'Carregando Cidades' : 'Cidade'
                                }
                                loading={cityLoading}
                                handleChange={(value: SelectOption) => {
                                  handleFilter({
                                    ...filters,
                                    CidadeId: value,
                                  });
                                  if (value?.value)
                                    fitMapToCity(value?.value ?? '');
                                }}
                              />
                            </Box>

                            {['admin', 'corretora'].includes(user.role) && (
                              <>
                                <Box sx={{ minWidth: 200, maxWidth: '50%' }}>
                                  <SearchField
                                    extraAttrs={{
                                      value: filters.InstituicaoFinanceiraId,
                                    }}
                                    options={financialInstitutionsOptions}
                                    label="Filtrar por Segurado"
                                    loading={loading}
                                    handleChange={(value: SelectOption) => {
                                      handleFilter({
                                        ...filters,
                                        InstituicaoFinanceiraId: value,
                                      });
                                    }}
                                  />
                                </Box>
                                {user.role === 'admin' && (
                                  <Box sx={{ minWidth: 200, maxWidth: '50%' }}>
                                    <SearchField
                                      extraAttrs={{
                                        value: filters.CorretoraId,
                                      }}
                                      options={brokersOptions}
                                      label="Filtrar por Corretora"
                                      loading={loading}
                                      handleChange={(value: SelectOption) => {
                                        handleFilter({
                                          ...filters,
                                          CorretoraId: value,
                                        });
                                      }}
                                    />
                                  </Box>
                                )}
                              </>
                            )}

                            {filters?.Estado && (
                              <div
                                style={{
                                  marginTop: theme.spacing(2),
                                  marginBottom: theme.spacing(2),
                                }}
                              >
                                <Chip label={filters?.Estado ?? ''} />
                              </div>
                            )}

                            <Box sx={{ display: 'flex', minWidth: 200 }}>
                              <DesktopDatePicker
                                label="Data Inicio"
                                inputFormat="DD/MM/YYYY"
                                disabled={loading}
                                value={
                                  filters?.DataInicio
                                    ? filters?.DataInicio
                                    : null
                                }
                                onChange={(newValue) => {
                                  handleFilter({
                                    ...filters,
                                    DataInicio: newValue,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </Box>

                            <Box sx={{ display: 'flex', minWidth: 200 }}>
                              <DesktopDatePicker
                                label="Data Final"
                                inputFormat="DD/MM/YYYY"
                                disabled={loading}
                                value={
                                  filters?.DataFinal ? filters?.DataFinal : null
                                }
                                onChange={(newValue) => {
                                  handleFilter({
                                    ...filters,
                                    DataFinal: newValue,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </Box>

                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={handleClearFilters}
                              disabled={loading}
                            >
                              <Typography
                                variant="button"
                                color="primary"
                                style={{
                                  display: 'flex',
                                  gap: theme.spacing(1),
                                }}
                              >
                                Limpar Filtros
                              </Typography>
                            </Button>
                          </Box>

                          {filteredProposals?.map((row, index) => (
                            <div
                              key={row.cotadorId}
                              style={{
                                display: 'flex',
                                marginBottom: theme.spacing(2),
                              }}
                            >
                              <Accordion
                                onClick={() => {
                                  if (selectedProposal !== row.cotadorId) {
                                    setSelectedCity(row.cidadeId);
                                    const polygon = new google.maps.Polygon({
                                      paths: citiesWithCoordinates.find(
                                        (cityWithCoordinates) =>
                                          cityWithCoordinates.cidadeId ===
                                          row.cidadeId
                                      )?.coordenadaCidade,
                                    });
                                    const bounds =
                                      new google.maps.LatLngBounds();
                                    const paths = polygon.getPaths();
                                    let path;
                                    for (
                                      let i = 0;
                                      i < paths.getLength();
                                      i++
                                    ) {
                                      path = paths.getAt(i);
                                      for (
                                        let ii = 0;
                                        ii < path.getLength();
                                        ii++
                                      ) {
                                        bounds.extend(path.getAt(ii));
                                      }
                                    }
                                    map?.fitBounds(bounds);
                                    const zoom = getBoundsZoomLevel(bounds, {
                                      width: window.innerWidth / 2,
                                      height: window.innerHeight,
                                    });
                                    map?.setZoom(zoom);
                                    setSelectedProposal(row.cotadorId);
                                  } else {
                                    setSelectedProposal(undefined);
                                    setSelectedCity(undefined);
                                  }
                                }}
                                expanded={selectedProposal === row.cotadorId}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls={`proposal-${index}-content`}
                                  id={`proposal-${index}-id`}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      paddingRight: theme.spacing(2),
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        padding: theme.spacing(2),
                                        gap: theme.spacing(3),
                                        flexWrap: 'wrap',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        component="h3"
                                        sx={{
                                          fontWeight: 'bold',
                                          fontSize: theme.spacing(2),
                                        }}
                                        color="textSecondary"
                                      >
                                        {row.siglaEstado?.toUpperCase()}-
                                        {row.nomeCidade?.toUpperCase()}
                                      </Typography>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <LocationCityIcon />
                                        {row?.nomeCidade}
                                      </Box>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <PrivacyTipIcon />
                                        {row?.nomeInstituicaoFinanceira}
                                      </Box>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <LocalPoliceIcon />
                                        {row?.nomeCorretora}
                                      </Box>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <FlagIcon />
                                        {row?.nomeEstado}
                                      </Box>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <GrassIcon />
                                        {row?.nomeCultura}
                                      </Box>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <OpenWithIcon /> Área: {row.area}ha
                                      </Box>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <RequestQuoteIcon />
                                        Valor da Cobertura: R$
                                        {formatCurrency(
                                          parseFloat(row.valorDaCobertura)
                                            .toFixed(2)
                                            .replace('.', ',')
                                        )}
                                      </Box>
                                      <Box
                                        sx={{
                                          color: theme.palette.text.secondary,
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Person />
                                        Produtor Rural: {row.nomeCliente}
                                      </Box>
                                    </Box>

                                    <Box
                                      sx={{
                                        color: theme.palette.text.secondary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        gap: theme.spacing(1),
                                      }}
                                    >
                                      <DownloadLinkButton
                                        url={row.comprovanteKML}
                                      />
                                      <IconButton
                                        sx={{
                                          background: green[100],
                                          color: theme.palette.primary.main,
                                        }}
                                        aria-label="PDF Proposta"
                                        onClick={() => {
                                          window.open(
                                            `/proposals/detail/${row.cotadorId}/`
                                          );
                                        }}
                                      >
                                        <PublicIcon sx={{ fontSize: 14 }} />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box
                                    style={{
                                      minWidth: 200,
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Line
                                      options={{
                                        ...options,
                                        aspectRatio: 5 / 4,
                                        interaction: {
                                          intersect: false,
                                          mode: 'index',
                                        },
                                        plugins: {
                                          ...options.plugins,
                                          title: {
                                            display: true,
                                            text: 'Histórico Climático do Município',
                                          },
                                          tooltip: {
                                            callbacks: {
                                              // eslint-disable-next-line
                                              footer: (tooltipItems: any) => {
                                                let footer = '';
                                                const index =
                                                  tooltipItems[0].dataIndex;
                                                const deficitSurplus =
                                                  row?.proposalHistory
                                                    ?.acompanhamentosProduto[
                                                    index
                                                  ].valorDiferenca;
                                                if (deficitSurplus !== 0) {
                                                  footer += `${row.indiceLabelStrike
                                                    }: ${deficitSurplus.toFixed(
                                                      5
                                                    )}${row.indiceUnidadeMedida}`;
                                                }
                                                return footer;
                                              },
                                              // eslint-disable-next-line
                                              label: (item: any) => {
                                                return `${parseFloat(
                                                  item.raw as string
                                                ).toFixed(5)}`;
                                              },
                                            },
                                          },
                                        },
                                      }}
                                      data={{
                                        labels:
                                          row?.proposalHistory?.acompanhamentosProduto?.map(
                                            (item) =>
                                              dayjs(item.data)
                                                .format('DD/MM/YYYY')
                                                .toString()
                                          ),
                                        datasets: [
                                          {
                                            label: 'Histórico do Município',
                                            backgroundColor: '#636e72',
                                            borderColor: '#636e72',
                                            borderDash: [10, 5],
                                            fill: 'false',
                                            data: row?.proposalHistory?.acompanhamentosProduto?.map(
                                              (item) =>
                                                item.valorHistorico.toString()
                                            ),
                                          },
                                          {
                                            label: `${row.indiceNome}`,
                                            type: (row.tipoGrafico !== 'line'
                                              ? 'bar'
                                              : 'line') as unknown as undefined,
                                            backgroundColor: ({ dataIndex, parsed }: any) => {
                                              if (row.tipoGrafico === 'line') return '#dfe6e9';
                                              const lineValue = row?.proposalHistory?.acompanhamentosProduto?.map(
                                                (item) =>
                                                  item.valorHistorico
                                              )?.[dataIndex] ?? 0;

                                              if (row.indicePositivo) {
                                                if (lineValue < (parsed?.y ?? 0)) {
                                                  return '#e11d48'
                                                }
                                              } else {
                                                if (lineValue > (parsed?.y ?? 0)) {
                                                  return '#e11d48'
                                                }
                                              }
                                              return '#3b82f6'
                                            },
                                            fill:
                                              row.tipoGrafico !== 'line'
                                                ? undefined
                                                : {
                                                  above: 'aqua',
                                                  below: orange[500],
                                                  target: 'stack',
                                                },
                                            data: row?.proposalHistory?.acompanhamentosProduto
                                              .map((item) =>
                                                item.valorAtual.toString()
                                              ),
                                          },
                                        ],
                                      }}
                                    />
                                    <Chart
                                      type="bar"
                                      data={{
                                        labels: removeNoDataLabels(row),
                                        datasets: [
                                          {
                                            type: 'line' as const,
                                            label: 'Strike 1',
                                            backgroundColor:
                                              theme.palette.grey[700],
                                            borderColor:
                                              theme.palette.grey[700],
                                            showLine: false,
                                            pointStyle: 'line',
                                            pointBorderWidth: 2,
                                            pointHoverBorderWidth: 8,
                                            pointHitRadius: 32,
                                            pointRadius: 32,
                                            pointHoverRadius: 32,
                                            data: removeNoDataLabels(row)?.map(
                                              (_, index) => {
                                                const auxIndex: number =
                                                  index + 1;
                                                const subProduto =
                                                  row?.proposalHistory
                                                    ?.subProduto[0] ?? null;
                                                return subProduto[
                                                  ('strike1Gatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                ]
                                                  ? subProduto[
                                                  ('strike1Gatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                  ]
                                                  : null;
                                              }
                                            ),
                                          },
                                          {
                                            type: 'line' as const,
                                            label: 'Strike 2',
                                            backgroundColor:
                                              theme.palette.grey[600],
                                            borderColor:
                                              theme.palette.grey[600],
                                            showLine: false,
                                            pointStyle: 'line',
                                            pointBorderWidth: 2,
                                            pointHoverBorderWidth: 8,
                                            pointHitRadius: 32,
                                            pointRadius: 32,
                                            pointHoverRadius: 32,
                                            data: removeNoDataLabels(row)?.map(
                                              (_, index) => {
                                                const auxIndex: number =
                                                  index + 1;
                                                const subProduto =
                                                  row?.proposalHistory
                                                    ?.subProduto[0] ?? null;
                                                return subProduto[
                                                  ('strike2Gatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                ]
                                                  ? subProduto[
                                                  ('strike2Gatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                  ]
                                                  : null;
                                              }
                                            ),
                                          },
                                          {
                                            type: 'line' as const,
                                            label: 'Strike 3',
                                            backgroundColor:
                                              theme.palette.grey[500],
                                            borderColor:
                                              theme.palette.grey[500],
                                            showLine: false,
                                            pointStyle: 'line',
                                            pointBorderWidth: 2,
                                            pointHoverBorderWidth: 8,
                                            pointHitRadius: 32,
                                            pointRadius: 32,
                                            pointHoverRadius: 32,
                                            data: removeNoDataLabels(row)?.map(
                                              (_, index) => {
                                                const auxIndex: number =
                                                  index + 1;
                                                const subProduto =
                                                  row?.proposalHistory
                                                    ?.subProduto[0] ?? null;
                                                return subProduto[
                                                  ('strike3Gatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                ]
                                                  ? subProduto[
                                                  ('strike3Gatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                  ]
                                                  : null;
                                              }
                                            ),
                                          },
                                          {
                                            type: 'bar' as const,
                                            label: `${row.indiceLabelGrafico}`,
                                            barThickness: 64,
                                            backgroundColor: `${row.indicePositivo
                                              ? 'aqua'
                                              : orange[500]
                                              }`,
                                            borderColor: `${row.indicePositivo
                                              ? 'aqua'
                                              : orange[500]
                                              }`,
                                            data: removeNoDataLabels(row)?.map(
                                              (_, index) => {
                                                const auxIndex = index + 1;
                                                const subProduto =
                                                  row?.proposalHistory
                                                    ?.subProduto[0] ?? null;
                                                return subProduto[
                                                  ('somaGatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                ]
                                                  ? subProduto[
                                                  ('somaGatilho' +
                                                    auxIndex) as keyof ProposalHistorySubProduct
                                                  ]
                                                  : null;
                                              }
                                            ),
                                          },
                                        ],
                                      }}
                                      options={
                                        {
                                          ...options,
                                          scales: {
                                            x: {
                                              stacked: true,
                                            },
                                            y: {
                                              reverse: !!!row.indicePositivo,
                                            },
                                          },
                                          plugins: {
                                            ...options.plugins,
                                            tooltip: {
                                              callbacks: {
                                                // eslint-disable-next-line
                                                footer: (_: any) => {
                                                  let footer = '';
                                                  const subProduct = (row
                                                    ?.proposalHistory
                                                    ?.subProduto ?? [null])[0];
                                                  if (
                                                    parseFloat(
                                                      subProduct?.pagamentoLinearGatilho1 ??
                                                      '0'
                                                    ) > 0
                                                  ) {
                                                    footer += `\nPagamento Linear Gatilho 1: ${parseFloat(
                                                      subProduct?.pagamentoLinearGatilho1
                                                    ).toFixed(2)}%`;
                                                  }
                                                  if (
                                                    parseFloat(
                                                      subProduct?.pagamentoLinearGatilho2 ??
                                                      '0'
                                                    ) > 0
                                                  ) {
                                                    footer += `\nPagamento Linear Gatilho 2: ${parseFloat(
                                                      subProduct?.pagamentoLinearGatilho2
                                                    ).toFixed(2)}%`;
                                                  }
                                                  if (
                                                    parseFloat(
                                                      subProduct?.pagamentoLinearGatilho3 ??
                                                      '0'
                                                    ) > 0
                                                  ) {
                                                    footer += `\nPagamento Linear Gatilho 3: ${parseFloat(
                                                      subProduct?.pagamentoLinearGatilho3
                                                    ).toFixed(2)}%`;
                                                  }
                                                  return footer;
                                                },
                                              },
                                            },
                                          },
                                          interaction: {
                                            mode: 'index',
                                            axis: 'xy',
                                            intersect: true,
                                          },
                                        } as ChartOptions
                                      }
                                    />
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          ))}
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            fontSize={10}
                          >
                            *Estas informações são meramente uma representação
                            gráfica. O pagamento de sinistro será realizado com
                            base no relatório oficial feito no fechamento do
                            período de risco.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    {isLoaded ? (
                      <Box
                        sx={{
                          width: '50vw',
                          height: '100vh',
                        }}
                      >
                        <GoogleMap
                          mapContainerStyle={{
                            width: '100%',
                            height: '100vh',
                          }}
                          center={BrazilCoordinates}
                          zoom={4.5}
                          onLoad={onLoad}
                          mapTypeId="hybrid"
                          onUnmount={onUnmount}
                          onClick={() => {
                            setSelectedCity(undefined);
                            handleFilter({ ...filters, CidadeId: null });
                            const zoom = map?.getZoom() || 6;
                            map?.setZoom(zoom >= 10 ? zoom - 2 : 6);
                          }}
                        >
                          {citiesWithCoordinates.map(
                            (cityWithCoordinate: CityWithCoordinates) => (
                              <Polygon
                                key={cityWithCoordinate.cidadeId}
                                paths={
                                  cityWithCoordinate.coordenadaCidade as PolygonProps['paths']
                                }
                                onClick={() => {
                                  const polygon = new google.maps.Polygon({
                                    paths: cityWithCoordinate.coordenadaCidade,
                                  });
                                  const bounds = new google.maps.LatLngBounds();
                                  const paths = polygon.getPaths();
                                  let path;
                                  for (let i = 0; i < paths.getLength(); i++) {
                                    path = paths.getAt(i);
                                    for (
                                      let ii = 0;
                                      ii < path.getLength();
                                      ii++
                                    ) {
                                      bounds.extend(path.getAt(ii));
                                    }
                                  }
                                  map?.fitBounds(bounds);
                                  const zoom = map?.getZoom() || 10;
                                  map?.setZoom(zoom > 10 ? 10 : zoom + 1);

                                  handleFilter({
                                    ...filters,
                                    CidadeId: {
                                      value: cityWithCoordinate.cidadeId,
                                      label: cityWithCoordinate.cidadeNome,
                                    } as SelectOption,
                                  });
                                  setSelectedCity(cityWithCoordinate.cidadeId);
                                }}
                                options={{
                                  fillColor: cityWithCoordinate.color,
                                  fillOpacity:
                                    selectedCity === cityWithCoordinate.cidadeId
                                      ? 0.8
                                      : 0.4,
                                  strokeColor: 'white',
                                  strokeOpacity:
                                    selectedCity === cityWithCoordinate.cidadeId
                                      ? 1
                                      : 0.8,
                                  strokeWeight:
                                    selectedCity === cityWithCoordinate.cidadeId
                                      ? 2
                                      : 1,
                                  clickable: true,
                                  draggable: false,
                                  editable: false,
                                  geodesic: false,
                                  zIndex: 1,
                                }}
                              />
                            )
                          )}
                        </GoogleMap>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};
