import {
  ClimateHistory,
  ClimateHistoryPayload,
  PaginatedResponse,
  UploadClimateHistoryPayload,
} from '../types';
import { BaseClient } from './BaseService';

export interface ClimateHistoryApi {
  getClimateHistoryById(id: string): Promise<ClimateHistory>;
  getClimatesHistories(): Promise<Array<ClimateHistory>>;
  getPagedClimatesHistories(
    page: number
  ): Promise<PaginatedResponse<ClimateHistory>>;
  deleteClimateHistory(id: string): Promise<void>;
  editClimateHistory(payload: ClimateHistoryPayload): Promise<ClimateHistory>;
  addClimateHistory(payload: ClimateHistoryPayload): Promise<ClimateHistory>;
  uploadClimateHistory(payload: UploadClimateHistoryPayload): Promise<void>;
}

class ClimateHistoryService extends BaseClient implements ClimateHistoryApi {
  async getClimateHistoryById(id: string): Promise<ClimateHistory> {
    const { data } = await this.api.get(`/${id}/`);
    return data as ClimateHistory;
  }

  async getClimatesHistories(): Promise<Array<ClimateHistory>> {
    const { data } = await this.api.get(`/`);
    return data as Array<ClimateHistory>;
  }

  async getPagedClimatesHistories(
    page: number
  ): Promise<PaginatedResponse<ClimateHistory>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<ClimateHistory>;
  }

  async deleteClimateHistory(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editClimateHistory(
    payload: ClimateHistoryPayload
  ): Promise<ClimateHistory> {
    const { data } = await this.api.put(`/`, payload);
    return data as ClimateHistory;
  }

  async addClimateHistory(
    payload: ClimateHistoryPayload
  ): Promise<ClimateHistory> {
    const { data } = await this.api.post(`/`, payload);
    return data as ClimateHistory;
  }

  async uploadClimateHistory(
    payload: UploadClimateHistoryPayload
  ): Promise<void> {
    const { data } = await this.api.post(`/import/`, payload);
    return data;
  }
}

export default ClimateHistoryService;
