import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import PublicIcon from '@mui/icons-material/Public';
import { red, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';
import { EmptyList } from '../../components/EmptyList';
import { TableSkeleton } from '../../components/TableSkeleton';
import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { useProduct } from '../../contexts';
import { DeleteDialog } from '../../components/DeleteDialog';
import { handleErrorMessage, truncate } from '../../utils';

import { Product } from '../../types';

export const ProductList = () => {
  const theme = useTheme();
  const { products, setProducts, productApi } = useProduct();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [page] = useState<number>(1); // eslint-disable-line
  const [pageCount, setPageCount] = useState<number>(0);
  const [_, setPageSize] = useState<number>(10); // eslint-disable-line

  const productsCount = products?.length ?? -1;

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const getProducts = async () => {
    if (loading === false) {
      setError(undefined);
      try {
        setLoading(true);
        const data = await productApi.getPagedProducts(page);
        setPageSize(data.items.length);
        setPageCount(
          data.totalItems < data.items.length
            ? 1
            : Math.ceil(data.totalItems / data.items.length)
        );
        setProducts(data.items);
      } catch (e: unknown) {
        setError(handleErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePageChange = async (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setLoading(true);
      const data = await productApi.getPagedProducts(page);
      setProducts(data.items);
    } catch (e: unknown) {
      console.error(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handleDelete = async () => {
    await productApi.deleteProduct(deleteId as string);
    setProducts(
      (products ?? []).filter(
        (product: Product) => product.produtoId !== deleteId
      )
    );
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  return (
    <Layout>
      {error && <ErrorMsg error={error} />}
      {!error && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'flex-end',
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="h4" component="h2" color="textSecondary">
              Produtos
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              href="/products/create/"
            >
              <Typography
                variant="button"
                color="white"
                style={{
                  display: 'flex',
                  gap: theme.spacing(1),
                }}
              >
                <AddIcon />
                Cadastrar Produto
              </Typography>
            </Button>
          </Box>
          <div style={{ marginTop: theme.spacing(2) }}>
            {productsCount < 0 && !error && (
              <TableSkeleton
                columns={['Nome', 'Email', 'Telefone', 'UF', 'CNPJ', 'Ações']}
              />
            )}
            {productsCount === 0 && (
              <EmptyList to="/products/create/" label="Cadastrar Produto" />
            )}
            {productsCount > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableHeadCell component="th" scope="row">
                          Nome
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Descrição
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Nível de Cobertura
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Nível de Cobertura Variável
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Pagamento Linear
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Ativo
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Ações
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {products?.map((row) => (
                        <TableRow key={row.produtoId}>
                          <TableCell component="th" scope="row">
                            {row.nome}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {truncate({ value: row.descricao })}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.nivelDeCobertura}%
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.nivelDeCoberturaVariavel ? (
                              <Chip
                                label="✓"
                                color="primary"
                                variant="outlined"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="X"
                                color="secondary"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.pagamentoLinear ? (
                              <Chip
                                label="✓"
                                color="primary"
                                variant="outlined"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="X"
                                color="secondary"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.ativo ? (
                              <Chip
                                label="✓"
                                color="primary"
                                variant="outlined"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="X"
                                color="secondary"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <Box
                              sx={{
                                display: 'flex',
                                gap: theme.spacing(1),
                                justifyContent: 'center',
                              }}
                            >
                              <IconButton
                                sx={{
                                  background: green[100],
                                  color: theme.palette.primary.main,
                                }}
                                aria-label="Editar"
                                LinkComponent={Link}
                                href={`/products/edit/${row.produtoId}/`}
                              >
                                <EditIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                              <IconButton
                                sx={{
                                  background: green[100],
                                  color: theme.palette.primary.main,
                                }}
                                aria-label="Ver Produo"
                                LinkComponent={Link}
                                href={`/products/detail/${row.produtoId}/`}
                              >
                                <PublicIcon sx={{ fontSize: 14 }} />
                              </IconButton>

                              <IconButton
                                sx={{ background: red[100] }}
                                color="secondary"
                                aria-label="Excluir"
                                onClick={() => {
                                  setDeleteOpen(true);
                                  setDeleteId(row.produtoId);
                                  setDeleteName(row.nome);
                                }}
                              >
                                <CloseIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2} justifyContent="center" alignItems="center">
                  <Pagination
                    count={pageCount}
                    disabled={loading}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </Box>
            )}
          </div>
          <DeleteDialog
            open={deleteOpen}
            name={deleteName}
            setOpen={setDeleteOpen}
            handleDelete={handleDelete}
          />
        </>
      )}
    </Layout>
  );
};
