import { styled } from '@mui/material/styles';

import MaterialTableCell, { tableCellClasses } from '@mui/material/TableCell';
import MaterialTableRow from '@mui/material/TableRow';

export const TableHeadCell = styled(MaterialTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.root}`]: {
    padding: theme.spacing(2),
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableCell = styled(TableHeadCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    textAlign: 'center',
  },
}));

export const TableRow = styled(MaterialTableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));
