import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';
import { EmptyList } from '../../components/EmptyList';
import { TableSkeleton } from '../../components/TableSkeleton';
import { Layout } from '../../components/Layout';
import { useInsuranceCompany } from '../../contexts';
import IconButton from '@mui/material/IconButton';
import { DeleteDialog } from '../../components/DeleteDialog';
import { handleErrorMessage } from '../../utils';

import { InsuranceCompany } from '../../types';

export const InsuranceCompanyList = () => {
  const theme = useTheme();
  const { insuranceCompanies, setInsuranceCompanies, insuranceCompanyApi } =
    useInsuranceCompany();
  const [loading, setLoading] = useState<boolean>(false);
  const [page] = useState<number>(1); // eslint-disable-line
  const [pageCount, setPageCount] = useState<number>(0);
  const [_, setPageSize] = useState<number>(10); // eslint-disable-line

  const insuranceCompaniesCount = insuranceCompanies?.length ?? -1;

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const getInsuranceCompanies = async () => {
    if (loading === false) {
      try {
        setLoading(true);
        const data = await insuranceCompanyApi.getPagedInsuranceCompanies(page);
        setPageSize(data.items.length);
        setPageCount(
          data.totalItems < data.items.length
            ? 1
            : Math.ceil(data.totalItems / data.items.length)
        );
        setInsuranceCompanies(data.items);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePageChange = async (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setLoading(true);
      const data = await insuranceCompanyApi.getPagedInsuranceCompanies(page);
      setInsuranceCompanies(data.items);
    } catch (e: unknown) {
      console.error(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  const handleDelete = async () => {
    await insuranceCompanyApi.deleteInsuranceCompany(deleteId as string);
    setInsuranceCompanies(
      insuranceCompanies.filter(
        (insuranceCompany: InsuranceCompany) =>
          insuranceCompany.seguradoraId !== deleteId
      )
    );
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  return (
    <Layout>
      <Typography variant="h4" component="h2" color="textSecondary">
        Cadastro de Seguradoras
      </Typography>
      <Button
        style={{ marginTop: theme.spacing(2) }}
        variant="contained"
        color="primary"
        component={Link}
        href="/insurance-companies/create/"
      >
        <Typography
          variant="button"
          color="white"
          style={{
            display: 'flex',
            gap: theme.spacing(1),
          }}
        >
          <AddIcon />
          Cadastrar nova Seguradora
        </Typography>
      </Button>
      <div style={{ marginTop: theme.spacing(2) }}>
        {insuranceCompaniesCount < 0 && (
          <TableSkeleton
            columns={['Nome', 'Email', 'Telefone', 'UF', 'CNPJ', 'Ações']}
          />
        )}
        {insuranceCompaniesCount === 0 && (
          <EmptyList
            to="/insurance-companies/create/"
            label="Cadastrar Seguradora"
          />
        )}
        {insuranceCompaniesCount > 0 && (
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(2),
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableHeadCell component="th" scope="row">
                      Nome
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Email
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Telefone
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      UF
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Cidade
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      CNPJ
                    </TableHeadCell>
                    <TableHeadCell component="th" scope="row">
                      Ações
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insuranceCompanies?.map((row) => (
                    <TableRow key={row.seguradoraId}>
                      <TableCell component="th" scope="row">
                        {row.nome}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.telefone}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.uf}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cidade}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cnpj}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            gap: theme.spacing(1),
                            justifyContent: 'center',
                          }}
                        >
                          <IconButton
                            sx={{
                              background: green[100],
                              color: theme.palette.primary.main,
                            }}
                            aria-label="Editar"
                            LinkComponent={Link}
                            href={`/insurance-companies/edit/${row.seguradoraId}/`}
                          >
                            <EditIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                          <IconButton
                            sx={{ background: red[100] }}
                            color="secondary"
                            aria-label="Excluir"
                            onClick={() => {
                              setDeleteOpen(true);
                              setDeleteId(row.seguradoraId);
                              setDeleteName(row.nome);
                            }}
                          >
                            <CloseIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Pagination
                count={pageCount}
                disabled={loading}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          </Box>
        )}
      </div>
      <DeleteDialog
        open={deleteOpen}
        name={deleteName}
        setOpen={setDeleteOpen}
        handleDelete={handleDelete}
      />
    </Layout>
  );
};
