import { commonFormFields, initField } from '../../utils';
import {
  Broker,
  BrokerFormType,
  FileFieldType,
  TextFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: Broker }) => {
  const form: BrokerFormType = {
    ...commonFormFields(data),
    Nome: initField<TextFieldType>({
      name: 'Nome',
      value: data?.nome ? data.nome : '',
      label: 'Nome/Razão Social',
      required: true,
    }),
    Email: initField<TextFieldType>({
      name: 'Email',
      value: data?.email ? data.email : '',
      type: 'email',
      required: true,
    }),
    Senha: initField<TextFieldType>({
      name: 'Senha',
      value: '',
      type: 'password',
    }),
    ConfirmaSenha: initField<TextFieldType>({
      name: 'ConfirmaSenha',
      value: '',
      type: 'password',
      excludeFromPayload: true,
      label: 'Confirmar Senha',
    }),
    Endereco: initField<TextFieldType>({
      name: 'Endereco',
      value: '',
      label: 'Rua',
    }),
    NumeroEndereco: initField<TextFieldType>({
      name: 'NumeroEndereco',
      value: '',
      label: 'N°',
      type: 'number',
    }),
    Complemento: initField<TextFieldType>({ name: 'Complemento', value: '' }),
    Bairro: initField<TextFieldType>({ name: 'Bairro', value: '' }),
    Cidade: initField<TextFieldType>({
      name: 'Cidade',
      value: data?.cidade ?? '',
    }),
    ComprovanteResidencia: initField<FileFieldType>({
      name: 'ComprovanteResidencia',
      value: null,
    }),
    ContratoSocial: initField<FileFieldType>({
      name: 'ContratoSocial',
      value: null,
    }),
    CertificadoSusep: initField<FileFieldType>({
      name: 'CertificadoSusep',
      value: null,
    }),
    Logomarca: initField<FileFieldType>({ name: 'Logomarca', value: null }),
  };

  if (data?.corretoraId ?? false) {
    form.CorretoraId = initField<TextFieldType>({
      name: 'CorretoraId',
      value: data?.corretoraId as string,
    });
  }

  return form;
};
