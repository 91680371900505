import React from 'react';
import MaterialBox from '@mui/material/Box';
import { styled } from '@mui/system';

import { Sidebar } from '../../components/Sidebar';
import { AppBar } from '../../components/AppBar';
import { Page } from '../../components/Page';

const Box = styled(MaterialBox)(({ theme }) => ({
  height: '100%',
  overflowY: 'scroll',
  padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
}));

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Sidebar />
      <Page>
        <AppBar />
        <Box>{children}</Box>
      </Page>
    </>
  );
};
