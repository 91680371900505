import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import add from '../../assets/add.svg';

export const ActionInfoCard = ({
  text,
  type,
}: {
  text: string;
  type: 'add';
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: '#f1f1f1',
        padding: theme.spacing(3),
      }}
    >
      <Typography
        variant="body1"
        color="initial"
        sx={{ marginBottom: theme.spacing(2) }}
      >
        {text}
      </Typography>
      <img src={type === 'add' ? add : add} alt={text} width="100%" />
    </Box>
  );
};
