import {
  PaginatedResponse,
  ProductHistory,
  ProductHistoryPayload,
  UploadProductHistoryPayload,
} from '../types';
import { BaseClient } from './BaseService';

export interface ProductHistoryApi {
  getProductsHistories(): Promise<Array<ProductHistory>>;
  getPagedProductsHistories(
    page: number
  ): Promise<PaginatedResponse<ProductHistory>>;
  getProductHistoryById(productHistoryId: string): Promise<ProductHistory>;
  getProductHistoryByProductId(productId: string): Promise<ProductHistory[]>;
  addProductHistory(payload: ProductHistoryPayload): Promise<ProductHistory>;
  editProductHistory(payload: ProductHistoryPayload): Promise<ProductHistory>;
  deleteProductHistory(id: string): Promise<void>;
  uploadProductHistory(payload: UploadProductHistoryPayload): Promise<void>;
  getSubProductHistoryBySubProductId(
    subProductId: string
  ): Promise<ProductHistory[]>;
}

class ProductHistoryService extends BaseClient implements ProductHistoryApi {
  constructor({
    basePath,
    contentType,
    token,
  }: {
    basePath?: string;
    contentType?: string;
    token: string;
  }) {
    super({ basePath, contentType, token });
  }

  async getProductHistoryById(
    productHistoryId: string
  ): Promise<ProductHistory> {
    const { data } = await this.api.get(`/${productHistoryId}/`);
    return data as ProductHistory;
  }

  async getProductHistoryByProductId(
    productId: string
  ): Promise<Array<ProductHistory>> {
    const { data } = await this.api.get(`/produto/${productId}/`);
    return data as ProductHistory[];
  }

  async getProductsHistories(): Promise<Array<ProductHistory>> {
    const { data } = await this.api.get(`/`);
    return data as Array<ProductHistory>;
  }

  async getPagedProductsHistories(
    page: number
  ): Promise<PaginatedResponse<ProductHistory>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<ProductHistory>;
  }

  async deleteProductHistory(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editProductHistory(
    payload: ProductHistoryPayload
  ): Promise<ProductHistory> {
    const { data } = await this.api.put(`/`, payload);
    return data as ProductHistory;
  }

  async addProductHistory(
    payload: ProductHistoryPayload
  ): Promise<ProductHistory> {
    const { data } = await this.api.post(`/`, payload);
    return data as ProductHistory;
  }

  async uploadProductHistory(
    payload: UploadProductHistoryPayload
  ): Promise<void> {
    const { data } = await this.api.post(`/import/`, payload);
    return data;
  }

  async getSubProductHistoryBySubProductId(
    subProductId: string
  ): Promise<ProductHistory[]> {
    const { data } = await this.api.get(`/subproduto/${subProductId}/`);
    return data as ProductHistory[];
  }
}

export default ProductHistoryService;
