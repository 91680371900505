import React, { createContext, useState, useContext } from 'react';
import FinancialAgencyService, {
  FinancialAgencyApi,
} from '../services/FinancialAgencyService';

import { FinancialAgency } from '../types';
import { useAuth } from './AuthProvider';

export type FinancialAgencyContextType = {
  financialAgencyApi: FinancialAgencyApi;
  financialAgencies: Array<FinancialAgency>;
  setFinancialAgencies: React.Dispatch<
    React.SetStateAction<Array<FinancialAgency> | undefined>
  >;
  getFinancialAgency: (id: string) => Promise<FinancialAgency | undefined>;
};

export const FinancialAgencyContext = createContext<FinancialAgencyContextType>(
  {} as unknown as FinancialAgencyContextType
);

export const FinancialAgencyProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [financialAgencies, setFinancialAgencies] =
    useState<Array<FinancialAgency>>();

  const { token } = useAuth();

  const financialAgencyApi = new FinancialAgencyService({
    basePath: '/v1/agencia',
    token,
  });

  const getFinancialAgency = async (id: string) => {
    let data: FinancialAgency | undefined = undefined;
    try {
      data = await financialAgencyApi.getFinancialAgencyById(id);
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.log(`${e}`);
    }
    return data as FinancialAgency;
  };

  return (
    <FinancialAgencyContext.Provider
      value={
        {
          financialAgencyApi,
          financialAgencies,
          setFinancialAgencies,
          getFinancialAgency,
        } as FinancialAgencyContextType
      }
    >
      {children}
    </FinancialAgencyContext.Provider>
  );
};

export const useFinancialAgency = () => useContext(FinancialAgencyContext);
