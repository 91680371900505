import React, { createContext, useState, useContext } from 'react';
import BrokerService, { BrokerApi } from '../services/BrokerService';

import { Broker, SelectOption } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './AuthProvider';

export type BrokerContextType = {
  brokers: Array<Broker> | undefined;
  setBrokers: React.Dispatch<React.SetStateAction<Array<Broker> | undefined>>;
  broker: Broker | undefined;
  setBroker: React.Dispatch<React.SetStateAction<Broker | undefined>>;
  brokerError: string | undefined;
  setBrokerError: React.Dispatch<React.SetStateAction<string | undefined>>;
  brokerLoading: boolean;
  setBrokerLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getBroker(id: string): Promise<Broker>;
  brokersOptions: Array<SelectOption>;
  setBrokersOptions: React.Dispatch<
    React.SetStateAction<Array<SelectOption> | undefined>
  >;
  getBrokersOptions(): Promise<Array<SelectOption> | undefined>;
  brokerApi: BrokerApi;
};

export const BrokerContext = createContext<BrokerContextType>(
  {} as unknown as BrokerContextType
);

export const BrokerProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [brokers, setBrokers] = useState<Array<Broker>>();
  const [broker, setBroker] = useState<Broker>();
  const [brokerLoading, setBrokerLoading] = useState<boolean>(false);
  const [brokerError, setBrokerError] = useState<string>();

  const [brokersOptions, setBrokersOptions] = useState<Array<SelectOption>>();

  const { token } = useAuth();

  const brokerApi = new BrokerService({
    basePath: '/v1/corretora',
    contentType: 'multipart/form-data',
    token,
  });

  const getBroker = async (id: string) => {
    let data: Broker | undefined = undefined;
    setBrokerLoading(true);
    setBrokerError(undefined);
    try {
      data = await brokerApi.getBrokerById(id);
      setBroker(data);
    } catch (e: unknown) {
      setBrokerError(handleErrorMessage(e));
    } finally {
      setBrokerLoading(false);
    }
    return data as Broker;
  };

  const getBrokersOptions = async () => {
    try {
      setBrokerError(undefined);
      setBrokerLoading(true);
      const data = await brokerApi.getBrokers();
      const options = data.map(
        (broker) =>
          ({
            label: broker.nome,
            value: broker.corretoraId,
          } as SelectOption)
      );
      setBrokersOptions(options);
    } catch (error: unknown) {
      setBrokerError(handleErrorMessage(error));
    } finally {
      setBrokerLoading(false);
      return brokersOptions;
    }
  };

  return (
    <BrokerContext.Provider
      value={
        {
          brokerApi,
          brokers,
          setBrokers,
          broker,
          setBroker,
          brokerLoading,
          setBrokerLoading,
          brokerError,
          setBrokerError,
          getBroker,
          brokersOptions,
          setBrokersOptions,
          getBrokersOptions,
        } as BrokerContextType
      }
    >
      {children}
    </BrokerContext.Provider>
  );
};

export const useBroker = () => useContext(BrokerContext);
