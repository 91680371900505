import { initField } from '../../utils';
import {
  Index,
  IndexFormType,
  TextFieldType,
  BooleanFieldType,
  SelectFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: Index }) => {
  const form: IndexFormType = {
    Nome: initField<TextFieldType>({
      name: 'Nome',
      value: data?.nome ? data.nome : '',
      label: 'Nome',
      required: true,
    }),
    UnidadeMedida: initField<TextFieldType>({
      name: 'UnidadeMedida',
      label: 'Unidade de Medida',
      value: data?.unidadeMedida ? data.unidadeMedida : '',
      required: true,
    }),
    Positivo: initField<BooleanFieldType>({
      name: 'Positivo',
      value: data?.positivo ? data.positivo : false,
      required: false,
    }),
    LabelGrafico: initField<TextFieldType>({
      name: 'LabelGrafico',
      value: data?.labelGrafico ? data.labelGrafico : '',
      label: 'Label do Gráfico',
      required: true,
    }),
    LabelStrike: initField<TextFieldType>({
      name: 'LabelStrike',
      value: data?.labelStrike ? data.labelStrike : '',
      label: 'Label do Strike',
      required: true,
    }),
    Acumulado: initField<BooleanFieldType>({
      name: 'Acumulado',
      value: data?.acumulado ? data.acumulado : false,
      label: 'Acumulado?',
      required: true,
    }),
    GraficoId: initField<SelectFieldType>({
      name: 'graficoId',
      label: 'Tipo do Gráfico',
      required: true,
      value: null,
      extractValue: true,
    }),
  };

  if (data?.indiceId ?? false) {
    form.IndiceId = initField<TextFieldType>({
      name: 'IndiceId',
      value: data?.indiceId as string,
    });
  }

  return form;
};
