import { initField } from '../../utils';
import {
  BooleanFieldType,
  Crop,
  CropFormType,
  TextFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: Crop }) => {
  const form: CropFormType = {
    Nome: initField<TextFieldType>({
      name: 'Nome',
      value: data?.nome ? data.nome : '',
      required: true,
    }),
    Deleted: initField<BooleanFieldType>({
      name: 'Deleted',
      value: data?.deleted ? data.deleted : false,
      required: false,
    }),
  };

  if (data?.culturaId ?? false) {
    form.CulturaId = initField<TextFieldType>({
      name: 'CulturaId',
      value: data?.culturaId as string,
    });
  }

  return form;
};
