import {
  QueryClient,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { useAuth, useCustomer } from '../contexts';
import { queryClient } from '../App';
import { UserByCpfResponse } from '../services/CustomerService';

export const USER_QUERY_KEY = 'load-user-by-cpf';

type QueryParams = Omit<
  UseQueryOptions<UserByCpfResponse>,
  'queryFn' | 'queryKey'
> & {
  cpf?: string;
};

export function useUserByCpf({ cpf, ...rest }: QueryParams = {}) {
  const { customerApi } = useCustomer();
  const { ...query } = useQuery({
    queryKey: [USER_QUERY_KEY, cpf],
    queryFn: async () => {
      try {
        const data = await customerApi.getUserByCpf(cpf!);
        return data as UserByCpfResponse;
      } catch {
        throw Error(`Erro ao carregar dados do CPF: ${cpf}`);
      }
    },
    enabled: (cpf?.length ?? 0) > 10,
    retry: false,
    ...rest,
  });

  return { ...query };
}

useUserByCpf.invalidateQueries = () => {
  return queryClient.invalidateQueries({
    queryKey: [USER_QUERY_KEY],
    refetchType: 'active',
    type: 'all',
  });
};
