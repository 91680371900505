import React from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { SelectOption } from '../../types';
import { handleErrorMessage } from '../../utils';

export const SearchField = ({
  handleFilter,
  label = 'Pesquisa',
  id,
  options = [],
  placeholder,
  noOptionsText = 'Nenhum item encontrado',
  fetchingOptionsLoadingText = 'Pesquisando por registros...',
  icon,
  required = true,
  loading = false,
  disabled = false,
  fullwidth = true,
  extraAttrs,
  handleChange,
}: {
  handleFilter?: ({ filter }: { filter?: string }) => Promise<void>;
  label?: string;
  id?: string;
  options?: Array<SelectOption>;
  placeholder?: string;
  noOptionsText?: string;
  fetchingOptionsLoadingText?: string;
  icon?: React.ReactNode;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean | null;
  fullwidth?: boolean;
  extraAttrs?: Partial<AutocompleteProps<SelectOption, false, false, false>>;
  handleChange: (value: SelectOption) => void;
}) => {
  id = id ?? label.replaceAll(' ', '-');
  icon = icon ?? <ArrowDropDownIcon />;
  const [, setError] = React.useState<string>();
  const [findLoading, setFindLoading] = React.useState<boolean>(false);

  const [inputValue, setInputValue] = React.useState('');
  const [, setTyping] = React.useState<boolean>(false); // eslint-disable-next-line
  const [typingTimeout, setTypingTimeout] = React.useState<any>(0);

  const fetchOptions = async (filter: string) => {
    if (handleFilter ?? false) {
      handleFilter = handleFilter as ({
        filter,
      }: {
        filter?: string;
      }) => Promise<void>;
      try {
        await handleFilter({ filter });
      } catch (error: unknown) {
        setError(handleErrorMessage(error));
      }
    }
    setFindLoading(false);
  };

  // eslint-disable-next-line
  const handleUserInputs = (event: any, newInputValue: string) => {
    setFindLoading(true);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setInputValue(newInputValue.trimStart());
    setTyping(false);
    if ((newInputValue?.length ?? 0) > 2) {
      setTypingTimeout(
        setTimeout(function _fetchOptions() {
          fetchOptions(newInputValue);
        }, 1000)
      );
    } else {
      setFindLoading(false);
    }
  };

  return (
    <Autocomplete
      {...extraAttrs}
      onChange={(_, newValue: SelectOption) => {
        handleChange(newValue);
        if (newValue === null || typeof newValue === 'undefined') {
          setInputValue('');
        }
      }}
      inputValue={inputValue}
      getOptionLabel={(option: SelectOption) => {
        return option?.label ?? '';
      }}
      onInputChange={(event, newInputValue: string) => {
        handleUserInputs(event, newInputValue);
      }}
      noOptionsText={findLoading ? fetchingOptionsLoadingText : noOptionsText}
      id={id}
      isOptionEqualToValue={(option: SelectOption, value: SelectOption) => {
        return `${option?.value}` === `${value?.value}`;
      }}
      options={options}
      disabled={loading || (disabled ?? false)}
      fullWidth={fullwidth ? true : undefined}
      renderOption={(props, option: SelectOption) => {
        return (
          <li {...props} key={option?.value}>
            {option?.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder ? placeholder : label}
          variant="outlined"
          size="small"
          required={required ? true : false}
        />
      )}
      popupIcon={icon}
      openOnFocus={true}
    />
  );
};
