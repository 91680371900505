import { initField } from '../../utils';
import dayjs from 'dayjs';
import {
  ClimateHistory,
  ClimateHistoryFormType,
  SelectFieldType,
  TextFieldType,
  SelectOption,
  DateFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: ClimateHistory }) => {
  const form: ClimateHistoryFormType = {
    Valor: initField<TextFieldType>({
      name: 'Valor',
      value: typeof data?.valor !== 'undefined' ? data.valor : '',
      required: true,
    }),
    Data: initField<DateFieldType>({
      name: 'Data',
      value: data?.data ? dayjs(data?.data) : null,
      required: true,
    }),
    EstadoId: initField<SelectFieldType>({
      name: 'EstadoId',
      value: data?.estadoId
        ? ({ value: data.estadoId, label: '' } as SelectOption)
        : null,
      label: 'Estado',
      required: false,
      extractValue: true,
    }),
    CidadeId: initField<SelectFieldType>({
      name: 'CidadeId',
      value: data?.cidadeId
        ? ({ value: data.cidadeId, label: '' } as SelectOption)
        : null,
      label: 'Cidade',
      required: false,
      extractValue: true,
    }),
    ProdutoId: initField<SelectFieldType>({
      name: 'ProdutoId',
      value: null,
      label: 'Produto',
      required: true,
      extractValue: true,
    }),
  };

  if (data?.historicoClimaId ?? false) {
    form.HistoricoClimaId = initField<TextFieldType>({
      name: 'HistoricoClimaId',
      value: data?.historicoClimaId as string,
    });
  }

  return form;
};
