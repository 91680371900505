import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
/* import FormGroup from '@mui/material/FormGroup'; */
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Layout } from '../../components/Layout';
import { ImageField } from '../../components/ImageField';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { useBroker, useCore } from '../../contexts/contexts';
import {
  handleErrorMessage,
  initField,
  serializePayload,
  SUPORTED_DOC_FIELD_TYPES,
} from '../../utils';
import {
  FileFieldType,
  BrokerPayload,
  BrokerFormType,
  Broker,
} from '../../types';
import { Form } from './styled';
import Grid from '@mui/material/Grid';

import { SUPORTED_IMAGE_FIELD_TYPES } from '../../utils';
import { initForm } from './utils';
/* import {
  ServiceTermsCheckbox,
  AuthorizationCheckbox,
} from '../../components/FormCheckboxes'; */
import { Loading } from '../../components/Loading';

const HalfedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
}));

export const BrokerForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const {
    brokers,
    setBrokers,
    brokerError,
    brokerLoading,
    getBroker,
    brokerApi,
  } = useBroker();
  const { stateLoading, stateError, cityError, fetchCepData } = useCore();

  const fetchLoading = brokerLoading || stateLoading;
  const fetchError = brokerError || stateError || cityError;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string[] | string>();
  const [form, setForm] = useState<BrokerFormType>(
    initForm({}) as BrokerFormType
  );
  const [ComprovanteResidencia, setComprovanteResidencia] = useState(
    initField<FileFieldType>({
      name: 'ComprovanteResidencia',
      value: null,
      label: 'Comprovante de Residencia',
    })
  );
  const [ContratoSocial, setContratoSocial] = useState(
    initField<FileFieldType>({
      name: 'ContratoSocial',
      value: null,
      label: 'Contrato Social',
    })
  );
  const [CertificadoSusep, setCertificadoSusep] = useState(
    initField<FileFieldType>({
      name: 'CertificadoSusep',
      value: null,
      label: 'Certificado SUSEP',
    })
  );
  const [Logomarca, setLogomarca] = useState(
    initField<FileFieldType>({
      name: 'Logomarca',
      value: null,
      label: 'Logo',
    })
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof BrokerFormType];
    auxForm[e.target.name as keyof BrokerFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = {
        ...serializePayload<BrokerFormType, BrokerPayload>(form),
        ContratoSocial: ContratoSocial.value,
        ComprovanteResidencia: ComprovanteResidencia.value,
        CertificadoSusep: CertificadoSusep.value,
        Logomarca: Logomarca.value,
      } as BrokerPayload;
      if (payload?.CorretoraId ?? false) {
        const data = await brokerApi.editBroker(payload);
        if ((brokers?.length ?? -1) > 0) {
          const auxBrokers = [
            ...(brokers ?? []).map((broker: Broker) => {
              if (broker.corretoraId === data.corretoraId) {
                return data;
              }
              return broker;
            }),
          ];
          setBrokers(auxBrokers);
        }
      } else {
        const data = await brokerApi.addBroker(payload);
        if ((brokers?.length ?? -1) > 0)
          setBrokers((brokers ?? []).concat([data]));
      }
      navigate('/brokers');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const initFormData = async () => {
    if (id ?? false) {
      const initialData = await getBroker(id ?? '');
      const auxForm = initForm({ data: initialData }) as BrokerFormType;
      setForm(auxForm);
    }
  };

  useEffect(() => {
    initFormData();
  }, []);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        <Typography variant="h5" component="h2" color="textSecondary">
          {isCreation ? 'Cadastro de Corretora' : 'Edição de Corretora'}
        </Typography>
        {fetchLoading ? (
          <Loading />
        ) : (
          <>
            {fetchError ? (
              <ErrorMsg error={fetchError} />
            ) : (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  flexDirection: 'column',
                }}
              >
                <ErrorMsg error={error} />
                <TextField field={form.Email} extraAttrs={extraAttrs} />
                <TextField field={form.Nome} extraAttrs={extraAttrs} />
                <HalfedBox>
                  <TextField field={form.CNPJ} extraAttrs={extraAttrs} />
                  <TextField field={form.Telefone} extraAttrs={extraAttrs} />
                </HalfedBox>
                <HalfedBox>
                  <TextField field={form.Senha} extraAttrs={extraAttrs} />
                  <TextField
                    field={form.ConfirmaSenha}
                    extraAttrs={extraAttrs}
                  />
                </HalfedBox>
                <HalfedBox>
                  <Box
                    style={{
                      width: '100%',
                      gap: theme.spacing(2),
                      marginBottom: theme.spacing(3),
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        gap: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <TextField
                        field={form.CEP}
                        extraAttrs={{
                          ...extraAttrs,
                          onBlur: () => {
                            return fetchCepData(
                              form.CEP.value.replace('-', ''),
                              form,
                              setForm
                            );
                          },
                        }}
                      />
                      <TextField
                        field={form.UF}
                        extraAttrs={{ ...extraAttrs, style: { width: 100 } }}
                      />
                    </Box>
                    <HalfedBox>
                      <TextField
                        field={form.Complemento}
                        extraAttrs={extraAttrs}
                      />
                      <TextField field={form.Bairro} extraAttrs={extraAttrs} />
                    </HalfedBox>
                  </Box>
                  <HalfedBox>
                    <Box style={{ width: '100%' }}>
                      <Box
                        style={{
                          display: 'flex',
                          gap: theme.spacing(2),
                          width: '100%',
                          marginBottom: theme.spacing(2),
                        }}
                      >
                        <TextField
                          field={form.Endereco}
                          extraAttrs={extraAttrs}
                        />
                        <TextField
                          field={form.NumeroEndereco}
                          extraAttrs={{ ...extraAttrs, style: { width: 100 } }}
                        />
                      </Box>
                      <TextField
                        field={form.Cidade}
                        extraAttrs={{ ...extraAttrs }}
                      />
                    </Box>
                  </HalfedBox>
                </HalfedBox>
                <Grid
                  container
                  spacing={0}
                  gap={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="start"
                  alignContent="center"
                  wrap="wrap"
                >
                  <ImageField
                    setField={(field: FileFieldType) => {
                      setComprovanteResidencia(field);
                      setError(undefined);
                    }}
                    field={ComprovanteResidencia}
                    allowedFileTypes={SUPORTED_IMAGE_FIELD_TYPES.concat(
                      SUPORTED_DOC_FIELD_TYPES
                    )}
                  />
                  <ImageField
                    setField={(field: FileFieldType) => {
                      setContratoSocial(field);
                      setError(undefined);
                    }}
                    field={ContratoSocial}
                    allowedFileTypes={SUPORTED_DOC_FIELD_TYPES}
                  />
                  <ImageField
                    setField={(field: FileFieldType) => {
                      setCertificadoSusep(field);
                      setError(undefined);
                    }}
                    field={CertificadoSusep}
                    allowedFileTypes={SUPORTED_DOC_FIELD_TYPES}
                  />
                  <ImageField
                    setField={(field: FileFieldType) => {
                      setLogomarca(field);
                      setError(undefined);
                    }}
                    field={Logomarca}
                    allowedFileTypes={SUPORTED_IMAGE_FIELD_TYPES}
                  />
                </Grid>
                {/* {!isCreation && (
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    sx={{
                      marginBottom: theme.spacing(3),
                      marginTop: theme.spacing(2),
                    }}
                  >
                    <FormGroup style={{ display: 'flex' }}>
                      <ServiceTermsCheckbox />
                      <AuthorizationCheckbox />
                    </FormGroup>
                  </Grid>
                )} */}
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={loading}
                    disabled={error ? true : undefined}
                    endIcon={
                      <ArrowForwardIcon
                        sx={{ color: theme.palette.common.white }}
                      />
                    }
                  >
                    <Typography variant="button" color="white">
                      {isCreation ? 'Cadastrar' : 'Salvar'}
                    </Typography>
                  </Button>
                </Box>
              </Form>
            )}
          </>
        )}
      </Grid>
    </Layout>
  );
};
