import { commonFormFields, initField } from '../../utils';
import {
  InsuranceCompany,
  InsuranceCompanyFormType,
  TextFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: InsuranceCompany }) => {
  const form: InsuranceCompanyFormType = {
    ...commonFormFields(data),
    Nome: initField<TextFieldType>({
      name: 'Nome',
      value: data?.nome ? data.nome : '',
      label: 'Nome/Razão Social',
      required: true,
    }),
    Email: initField<TextFieldType>({
      name: 'Email',
      value: data?.email ? data.email : '',
      type: 'email',
      required: true,
    }),
    Endereco: initField<TextFieldType>({
      name: 'Endereco',
      value: '',
      label: 'Rua',
    }),
    Senha: initField<TextFieldType>({
      name: 'Senha',
      value: '',
      type: 'password',
    }),
    ConfirmaSenha: initField<TextFieldType>({
      name: 'ConfirmaSenha',
      value: '',
      type: 'password',
      excludeFromPayload: true,
      label: 'Confirmar Senha',
    }),
    NumeroEndereco: initField<TextFieldType>({
      name: 'NumeroEndereco',
      value: '',
      label: 'N°',
      type: 'number',
    }),
    Complemento: initField<TextFieldType>({ name: 'Complemento', value: '' }),
    Bairro: initField<TextFieldType>({ name: 'Bairro', value: '' }),
    Cidade: initField<TextFieldType>({
      name: 'Cidade',
      value: data?.cidade ? data.cidade : '',
    }),
  };

  if (data?.seguradoraId ?? false) {
    form.SeguradoraId = initField<TextFieldType>({
      name: 'SeguradoraId',
      value: data?.seguradoraId as string,
    });
  }

  return form;
};
