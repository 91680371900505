import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';

export const TableSkeleton = ({
  columns = ['●●●●●', '●●●●●', '●●●●●', '●●●●●', '●●●●●'],
  rows = 5,
}: {
  columns?: Array<string>;
  rows?: number;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableHeadCell key={index} component="th" scope="row">
                  {column}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array(rows)
              .fill(null)
              .map((item, index) => (
                <TableRow key={index}>
                  {columns.map((column, index) => (
                    <TableCell key={index} component="th" scope="row">
                      <Skeleton variant="text" height={25} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
