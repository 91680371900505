import { Accountant, AccountantPayload, PaginatedResponse } from '../types';
import { BaseClient } from './BaseService';

export interface AccountantApi {
  getAccountants(): Promise<Array<Accountant>>;
  getPagedAccountants(page: number): Promise<PaginatedResponse<Accountant>>;
  getAccountantById(id: string): Promise<Accountant>;
  addAccountant(payload: AccountantPayload): Promise<Accountant>;
  editAccountant(payload: AccountantPayload): Promise<Accountant>;
  deleteAccountant(id: string): Promise<void>;
  exportAccountants(ids: Array<string>): Promise<void>;
  acceptAccountants(ids: Array<string>): Promise<void>;
  acceptFinancialInstitutions(ids: Array<string>): Promise<void>;
  linkUser(userId: string, accountantId: string): Promise<void>;
  getMeAccountants(): Promise<Array<Accountant>>;
}

class AccountantService extends BaseClient implements AccountantApi {
  constructor({
    basePath,
    contentType,
    token,
  }: {
    basePath?: string;
    contentType?: string;
    token: string;
  }) {
    super({ basePath, contentType, token });
  }

  async getAccountantById(id: string): Promise<Accountant> {
    const { data } = await this.api.get(`/${id}/`);
    return data as Accountant;
  }

  async getAccountants(): Promise<Array<Accountant>> {
    const { data } = await this.api.get(`/`);
    return data as Array<Accountant>;
  }

  async getPagedAccountants(
    page: number
  ): Promise<PaginatedResponse<Accountant>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<Accountant>;
  }

  async getMeAccountants(): Promise<Array<Accountant>> {
    const { data } = await this.api.get(`/me/`);
    return data as Array<Accountant>;
  }

  async deleteAccountant(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editAccountant(payload: AccountantPayload): Promise<Accountant> {
    const { data } = await this.api.put(`/`, payload);
    return data.cotador as Accountant;
  }

  async addAccountant(payload: AccountantPayload): Promise<Accountant> {
    const { data } = await this.api.post(`/`, payload);
    return data.cotador as Accountant;
  }

  async exportAccountants(ids: Array<string>): Promise<void> {
    await this.api.put(`/proposta/`, ids);
  }

  async acceptFinancialInstitutions(ids: Array<string>): Promise<void> {
    await this.api.put(`/aceiteproposta/`, ids);
  }

  async acceptAccountants(ids: Array<string>): Promise<void> {
    await this.api.put(`/aceitecliente/`, ids);
  }

  async linkUser(userId: string, accountantId: string): Promise<void> {
    await this.api.put(`/cliente/${accountantId}/${userId}`);
  }
}

export default AccountantService;
