import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

export const AuthorizationCheckbox = () => (
  <FormGroup style={{ display: 'flex' }}>
    <FormControlLabel
      sx={{
        maxWidth: 618,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      control={
        <Checkbox
          required
          name="lgpd"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      }
      label={
        <Typography variant="caption" color="textSecondary">
          Declaro que solicitei a prévia autorização para utilização dos dados
          do titular conforme a base legal prevista no artigo 7°, inciso V, da
          LGPD
        </Typography>
      }
    />
  </FormGroup>
);
