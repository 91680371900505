import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Key from '@mui/icons-material/Key';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { ErrorMsg } from '../../components/ErrorMsg';

import { useAuth } from '../../contexts';
import { ChangePasswordPayload } from '../../types';

export const ChangePassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { changePassword } = useAuth();

  const {
    handleChangePassword,
    changePasswordLoading,
    changePasswordError,
    setChangePasswordError,
    changePasswordSuccess,
    setChangePasswordSuccess,
  } = changePassword;

  const [payload, setPayload] = useState<ChangePasswordPayload>({
    usuarioId: '',
    password: '',
    newPassword: '',
  } as ChangePasswordPayload);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false);

  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const auxPayload = { ...payload };
    auxPayload[e.target.name as keyof ChangePasswordPayload] = e.target.value;
    setPayload(auxPayload);
  };

  return (
    <main
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        gap: theme.spacing(2),
        paddingBottom: 14,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(2),
        }}
      >
        <Link href="/">Voltar</Link>
        <Typography variant="h6" color="primary">
          Alterar Senha
        </Typography>
      </div>
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          handleChangePassword(payload);
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          minWidth: '240px',
          maxWidth: '663px',
          padding: theme.spacing(5),
        }}
      >
        <ErrorMsg error={changePasswordError} />
        {changePasswordSuccess ? (
          <>
            <Typography variant="body1" color="primary" align="center">
              {changePasswordSuccess}
            </Typography>
            <Divider />
            <Button
              variant="contained"
              onClick={() => {
                setChangePasswordSuccess(undefined);
                setPayload({
                  usuarioId: '',
                  password: '',
                  newPassword: '',
                } as ChangePasswordPayload);
                navigate('/');
              }}
            >
              <Typography variant="button" color="white">
                Ir para o Inicio
              </Typography>
            </Button>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                position: 'relative',
              }}
            >
              <Key
                sx={{
                  color: 'action.active',
                  mr: 1,
                  my: 0.5,
                  position: 'absolute',
                  left: -30,
                }}
              />
              <FormControl variant="filled" sx={{ width: '100%' }}>
                <InputLabel htmlFor="passwrod-label">Senha Atual</InputLabel>
                <FilledInput
                  type={showPassword ? 'text' : 'password'}
                  id="password-id"
                  name="password"
                  required
                  size="small"
                  disabled={changePasswordLoading}
                  style={{ minWidth: '213px' }}
                  fullWidth
                  value={payload.password}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disabled={changePasswordLoading}
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.preventDefault();
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                position: 'relative',
              }}
            >
              <Key
                sx={{
                  color: 'action.active',
                  mr: 1,
                  my: 0.5,
                  position: 'absolute',
                  left: -30,
                }}
              />
              <FormControl variant="filled" sx={{ width: '100%' }}>
                <InputLabel htmlFor="passwrod-label">Nova Senha</InputLabel>
                <FilledInput
                  type={showNewPassword ? 'text' : 'password'}
                  id="new-password-id"
                  name="newPassword"
                  required
                  size="small"
                  disabled={changePasswordLoading}
                  style={{ minWidth: '213px' }}
                  fullWidth
                  value={payload.newPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value !== confirmPassword) {
                      setChangePasswordError('Senhas não conferem!');
                    } else {
                      setChangePasswordError(undefined);
                    }
                    handleChange(e);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disabled={changePasswordLoading}
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        onMouseDown={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.preventDefault();
                        }}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                position: 'relative',
              }}
            >
              <Key
                sx={{
                  color: 'action.active',
                  mr: 1,
                  my: 0.5,
                  position: 'absolute',
                  left: -30,
                }}
              />
              <FormControl variant="filled" sx={{ width: '100%' }}>
                <InputLabel htmlFor="passwrod-label">
                  Confirmar Senha
                </InputLabel>
                <FilledInput
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  id="password-id"
                  name="password"
                  required
                  size="small"
                  disabled={changePasswordLoading}
                  style={{ minWidth: '213px' }}
                  fullWidth
                  value={confirmPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value !== payload.newPassword) {
                      setChangePasswordError('Senhas não conferem!');
                    } else {
                      setChangePasswordError(undefined);
                    }
                    setConfirmPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        disabled={changePasswordLoading}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmNewPassword(!showConfirmNewPassword)
                        }
                        onMouseDown={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.preventDefault();
                        }}
                      >
                        {showConfirmNewPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ minWidth: '213px' }}
              disabled={changePasswordError ? true : undefined}
              loading={changePasswordLoading}
            >
              <Typography variant="button" color="white">
                Confirmar
              </Typography>
            </Button>
          </>
        )}
      </form>

      <div
        style={{
          position: 'fixed',
          zIndex: 8,
          height: 14,
          bottom: 0,
          width: '100%',
          backgroundColor: theme.palette.primary.main,
        }}
      ></div>
    </main>
  );
};
