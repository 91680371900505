import React, { createContext, useState, useContext } from 'react';
import FinancialInstitutionService, {
  FinancialInstitutionApi,
} from '../services/FinancialInstitutionService';
import { FinancialInstitution, SelectOption } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './contexts';

export type FinancialInstitutionContextType = {
  financialInstitutionApi: FinancialInstitutionApi;
  financialInstitutions: Array<FinancialInstitution>;
  setFinancialInstitutions: React.Dispatch<
    React.SetStateAction<Array<FinancialInstitution> | undefined>
  >;
  financialInstitutionsOptions: Array<SelectOption>;
  setFinancialInstitutionsOptions: React.Dispatch<
    React.SetStateAction<Array<SelectOption> | undefined>
  >;
  financialInstitutionsLoading: boolean;
  setFinancialInstitutionsLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  financialInstitutionsError: string;
  setFinancialInstitutionsError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getFinancialInstitutionsOptions: () => Promise<
    Array<SelectOption> | undefined
  >;
  getFinancialInstitution: (
    id: string
  ) => Promise<FinancialInstitution | undefined>;
};

export const FinancialInstitutionContext =
  createContext<FinancialInstitutionContextType>(
    {} as unknown as FinancialInstitutionContextType
  );

export const FinancialInstitutionProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [financialInstitutions, setFinancialInstitutions] =
    useState<Array<FinancialInstitution>>();
  const [financialInstitutionsLoading, setFinancialInstitutionsLoading] =
    useState<boolean>(false);
  const [financialInstitutionsError, setFinancialInstitutionsError] =
    useState<string>();
  const [financialInstitutionsOptions, setFinancialInstitutionsOptions] =
    useState<Array<SelectOption>>();

  const { token } = useAuth();

  const financialInstitutionApi = new FinancialInstitutionService({
    basePath: '/v1/instituicaofinanceira',
    contentType: 'multipart/form-data',
    token,
  });

  const getFinancialInstitutionsOptions = async () => {
    try {
      setFinancialInstitutionsError(undefined);
      setFinancialInstitutionsLoading(true);
      const data = await financialInstitutionApi.getFinancialInstitutions();
      const options = data.map(
        (financialInstitution) =>
          ({
            label: financialInstitution.nome,
            value: financialInstitution.instituicaoFinanceiraId,
          } as SelectOption)
      );
      setFinancialInstitutionsOptions(options);
    } catch (error: unknown) {
      setFinancialInstitutionsError(handleErrorMessage(error));
    } finally {
      setFinancialInstitutionsLoading(false);
      return financialInstitutionsOptions;
    }
  };

  const getFinancialInstitution = async (id: string) => {
    let data: FinancialInstitution | undefined = undefined;
    setFinancialInstitutionsError(undefined);
    try {
      data = await financialInstitutionApi.getFinancialInstitutionById(id);
    } catch (e: unknown) {
      setFinancialInstitutionsError(handleErrorMessage(e));
    } finally {
      setFinancialInstitutionsLoading(false);
    }
    return data as FinancialInstitution;
  };

  return (
    <FinancialInstitutionContext.Provider
      value={
        {
          financialInstitutionApi,
          financialInstitutions,
          setFinancialInstitutions,
          financialInstitutionsOptions,
          setFinancialInstitutionsOptions,
          financialInstitutionsLoading,
          setFinancialInstitutionsLoading,
          financialInstitutionsError,
          setFinancialInstitutionsError,
          getFinancialInstitutionsOptions,
          getFinancialInstitution,
        } as FinancialInstitutionContextType
      }
    >
      {children}
    </FinancialInstitutionContext.Provider>
  );
};

export const useFinancialInstitution = () =>
  useContext(FinancialInstitutionContext);
