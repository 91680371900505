import { initField } from '../../utils';
import {
  Product,
  ProductFormType,
  TextFieldType,
  SelectFieldType,
  BooleanFieldType,
  SelectOption,
  FileFieldType,
} from '../../types';

import { percentageFieldMask } from '../../utils';

export const initForm = ({ data }: { data?: Product }) => {
  const form: ProductFormType = {
    Nome: initField<TextFieldType>({
      name: 'Nome',
      label: 'Nome',
      value: data?.nome ? data.nome : '',
      required: true,
    }),
    Descricao: initField<TextFieldType>({
      name: 'Descricao',
      label: 'Descrição',
      value: data?.descricao ? data.descricao : '',
      required: true,
    }),
    SeguradoraId: initField<SelectFieldType>({
      name: 'SeguradoraId',
      value: data?.seguradoraId
        ? ({ value: data.seguradoraId, label: '' } as SelectOption)
        : null,
      label: 'Seguradora',
      required: true,
      extractValue: true,
    }),
    InstituicaoFinanceiraId: initField<SelectFieldType>({
      name: 'InstituicaoFinanceiraId',
      value: data?.instituicaoFinanceiraId
        ? ({ value: data.instituicaoFinanceiraId, label: '' } as SelectOption)
        : null,
      label: 'Instituição Financeira',
      extractValue: true,
    }),
    IndiceId: initField<SelectFieldType>({
      name: 'IndiceId',
      value: data?.indiceId
        ? ({ value: data.indiceId, label: '' } as SelectOption)
        : null,
      label: 'Indice',
      extractValue: true,
    }),
    NivelDeCobertura: initField<TextFieldType>({
      name: 'NivelDeCobertura',
      label: 'Nivel de Cobertura (%)',
      value: data?.nivelDeCobertura ? data.nivelDeCobertura : '',
      required: true,
      mask: percentageFieldMask,
    }),
    NivelDeCoberturaVariavel: initField<BooleanFieldType>({
      name: 'NivelDeCoberturaVariavel',
      label: 'Nivel De Cobertura Variavel?',
      value: data?.nivelDeCoberturaVariavel
        ? data.nivelDeCoberturaVariavel
        : false,
      required: true,
    }),
    PagamentoLinear: initField<BooleanFieldType>({
      name: 'PagamentoLinear',
      label: 'Pagamento Linear?',
      value: data?.pagamentoLinear ? data.pagamentoLinear : false,
      required: true,
    }),
    Ativo: initField<BooleanFieldType>({
      name: 'Ativo',
      label: 'Ativo?',
      value: data?.ativo ? data.ativo : false,
      required: true,
    }),
    SubProduto: initField<FileFieldType>({
      name: 'SubProduto',
      value: null,
      required: false,
    }),
    HistoricoProduto: initField<FileFieldType>({
      name: 'HistoricoProduto',
      value: null,
      required: false,
    }),
  };

  if (data?.produtoId ?? false) {
    form.ProdutoId = initField<TextFieldType>({
      name: 'ProdutoId',
      value: data?.produtoId as string,
    });
  }

  return form;
};
