import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
/* import FormGroup from '@mui/material/FormGroup'; */
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { Loading } from '../../components/Loading';
import { useInsuranceCompany, useCore } from '../../contexts/contexts';
import { handleErrorMessage, serializePayload } from '../../utils';
import {
  InsuranceCompanyPayload,
  InsuranceCompanyFormType,
  InsuranceCompany,
} from '../../types';
import { Form } from './styled';

import { initForm } from './utils';

const HalfedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
}));

export const InsuranceCompanyForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { fetchCepData } = useCore();
  const [error, setError] = useState<string[] | string>();
  const [loading, setLoading] = useState<boolean>(false); // eslint-disable-next-line
  const [_, setInsuranceCompany] = useState<InsuranceCompany | undefined>();
  const { insuranceCompanies, setInsuranceCompanies, insuranceCompanyApi } =
    useInsuranceCompany();
  const { id } = useParams();

  const [fetchingInsuranceCompany, setFetchingInsuranceCompany] =
    useState<boolean>(!isCreation);
  const [fetchingError, setFetchingError] = useState<string>();

  const [form, setForm] = useState<InsuranceCompanyFormType>(
    initForm({}) as InsuranceCompanyFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof InsuranceCompanyFormType];
    auxForm[e.target.name as keyof InsuranceCompanyFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = serializePayload<
        InsuranceCompanyFormType,
        InsuranceCompanyPayload
      >(form) as InsuranceCompanyPayload;
      if (payload?.SeguradoraId ?? false) {
        const data = await insuranceCompanyApi.editInsuranceCompany(payload);
        if ((insuranceCompanies?.length ?? -1) > 0) {
          const auxInsuranceCompanies = [
            ...insuranceCompanies?.map((insuranceCompany: InsuranceCompany) => {
              if (insuranceCompany.seguradoraId === data.seguradoraId) {
                return data;
              }
              return insuranceCompany;
            }),
          ];
          setInsuranceCompanies(auxInsuranceCompanies);
        }
      } else {
        const data = await insuranceCompanyApi.addInsuranceCompany(payload);
        if ((insuranceCompanies?.length ?? -1) > 0)
          setInsuranceCompanies(insuranceCompanies.concat([data]));
      }
      navigate('/insurance-companies');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const getInsuranceCompany = async () => {
    if (id ?? false) {
      setFetchingError(undefined);
      try {
        setFetchingInsuranceCompany(true);
        const data: InsuranceCompany =
          await insuranceCompanyApi.getInsuranceCompanyById(id as string);
        setForm(initForm({ data }) as InsuranceCompanyFormType);
        setInsuranceCompany(data);
      } catch (e: unknown) {
        setFetchingError(handleErrorMessage(e));
      } finally {
        setFetchingInsuranceCompany(false);
      }
    }
  };

  useEffect(() => {
    getInsuranceCompany();
  }, []);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {fetchingError && fetchingError}
        <Typography variant="h5" component="h2" color="textSecondary">
          {isCreation ? 'Cadastro de Seguradora' : 'Edição de Seguradora'}
        </Typography>
        {fetchingInsuranceCompany && <Loading />}
        {!fetchingInsuranceCompany && (
          <Form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              gap: theme.spacing(2),
              flexDirection: 'column',
            }}
          >
            <ErrorMsg error={error} />
            <TextField field={form.Email} extraAttrs={extraAttrs} />
            <HalfedBox>
              <TextField field={form.Senha} extraAttrs={extraAttrs} />
              <TextField field={form.ConfirmaSenha} extraAttrs={extraAttrs} />
            </HalfedBox>
            <TextField field={form.Nome} extraAttrs={extraAttrs} />
            <HalfedBox>
              <TextField field={form.CNPJ} extraAttrs={extraAttrs} />
              <TextField field={form.Telefone} extraAttrs={extraAttrs} />
            </HalfedBox>
            <HalfedBox>
              <Box
                style={{
                  width: '100%',
                  gap: theme.spacing(2),
                  marginBottom: theme.spacing(3),
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    gap: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <TextField
                    field={form.CEP}
                    extraAttrs={{
                      ...extraAttrs,
                      onBlur: () => fetchCepData(form.CEP.value, form, setForm),
                    }}
                  />
                  <TextField field={form.UF} extraAttrs={extraAttrs} />
                </Box>
                <HalfedBox>
                  <TextField field={form.Complemento} extraAttrs={extraAttrs} />
                  <TextField field={form.Bairro} extraAttrs={extraAttrs} />
                </HalfedBox>
              </Box>
              <HalfedBox>
                <Box style={{ width: '100%' }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: theme.spacing(2),
                      width: '100%',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <TextField field={form.Endereco} extraAttrs={extraAttrs} />
                    <TextField
                      field={form.NumeroEndereco}
                      extraAttrs={extraAttrs}
                    />
                  </Box>
                  <TextField field={form.Cidade} extraAttrs={extraAttrs} />
                </Box>
              </HalfedBox>
            </HalfedBox>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                loading={loading}
                disabled={error ? true : undefined}
                endIcon={
                  <ArrowForwardIcon
                    sx={{ color: theme.palette.common.white }}
                  />
                }
              >
                <Typography variant="button" color="white">
                  {isCreation ? 'Cadastrar' : 'Salvar'}
                </Typography>
              </Button>
            </Box>
          </Form>
        )}
      </Grid>
    </Layout>
  );
};
