import React from 'react';

import Chip from '@mui/material/Chip';

export const BooleanChip = ({ value }: { value: boolean }) => {
  return (
    <>
      {value ? (
        <Chip label="✓" color="primary" variant="outlined" size="small" />
      ) : (
        <Chip label="X" color="secondary" variant="outlined" size="small" />
      )}
    </>
  );
};
