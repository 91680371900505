import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { TableHeadCell, TableCell, TableRow } from '../../components/Table';
import { EmptyList } from '../../components/EmptyList';
import { TableSkeleton } from '../../components/TableSkeleton';
import { DeleteDialog } from '../../components/DeleteDialog';
import { Layout } from '../../components/Layout';
import { Breadcrumb } from '../../components/Breadcrumb';
import { ActionInfoCard } from '../../components/ActionInfoCard';
import { DownloadLinkButton } from '../../components/DownloadLinkButton';

import { useBroker } from '../../contexts';
import { handleErrorMessage } from '../../utils';
import { Broker } from '../../types';

export const BrokerList = () => {
  const theme = useTheme();
  const { brokers, setBrokers } = useBroker();
  const [loading, setLoading] = useState<boolean>(false);
  const [page] = useState<number>(1); // eslint-disable-line
  const [pageCount, setPageCount] = useState<number>(0);
  const [_, setPageSize] = useState<number>(10); // eslint-disable-line
  const { brokerApi } = useBroker();

  const brokersCount = brokers?.length ?? -1;

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>();
  const [deleteName, setDeleteName] = useState<string>();

  const getBrokers = async () => {
    if (loading === false) {
      try {
        setLoading(true);
        const data = await brokerApi.getPagedBrokers(page);
        setPageSize(data.items.length);
        setPageCount(
          data.totalItems < data.items.length
            ? 1
            : Math.ceil(data.totalItems / data.items.length)
        );
        setBrokers(data.items);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePageChange = async (
    _: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setLoading(true);
      const data = await brokerApi.getPagedBrokers(page);
      setBrokers(data.items);
    } catch (e: unknown) {
      console.error(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBrokers();
  }, []);

  const handleDelete = async () => {
    await brokerApi.deleteBroker(deleteId as string);
    setBrokers(
      (brokers ?? []).filter(
        (broker: Broker) => broker.corretoraId !== deleteId
      )
    );
    setDeleteName(undefined);
    setDeleteId(undefined);
  };

  return (
    <Layout>
      <Grid container>
        <Grid item md={0} lg={3} xl={2} />
        <Grid item md={12} lg={9} xl={10}>
          <Breadcrumb activeItem="Corretoras" previousRoute="/" />
          <Typography
            variant="h4"
            component="h2"
            color="textSecondary"
            marginBottom={2}
          >
            Corretoras
          </Typography>
        </Grid>
        <Grid item md={12} lg={3} xl={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
          >
            <Button
              variant="contained"
              color="primary"
              component={Link}
              startIcon={<AddIcon sx={{ color: theme.palette.common.white }} />}
              href="/brokers/create/"
            >
              <Typography variant="button" color="white">
                Cadastrar Corretora
              </Typography>
            </Button>
            <ActionInfoCard text="" type="add" />
          </Box>
        </Grid>
        <Grid item md={12} lg={9} xl={10}>
          <div>
            {brokersCount < 0 && (
              <TableSkeleton
                columns={['Nome', 'Email', 'Telefone', 'UF', 'CNPJ', 'Ações']}
              />
            )}
            {brokersCount === 0 && (
              <EmptyList to="/broker/create/" label="Cadastrar Corretora" />
            )}
            {brokersCount > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <TableHeadCell component="th" scope="row">
                          Nome
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Email
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Telefone
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          UF
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Cidade
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          CNPJ
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Comprovante de Residência
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Logo
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Certificado Susep
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Contrato Social
                        </TableHeadCell>
                        <TableHeadCell component="th" scope="row">
                          Ações
                        </TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {brokers?.map((row) => (
                        <TableRow key={row.corretoraId}>
                          <TableCell component="th" scope="row">
                            {row.nome}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.email}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.telefone}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.uf}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.cidade}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.cnpj}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DownloadLinkButton
                              url={row.comprovanteResidencia}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DownloadLinkButton url={row.logomarca} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DownloadLinkButton url={row.certificadoSusep} />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <DownloadLinkButton url={row.contratoSocial} />
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <Box
                              sx={{
                                display: 'flex',
                                gap: theme.spacing(1),
                                justifyContent: 'center',
                              }}
                            >
                              <IconButton
                                sx={{
                                  background: green[100],
                                  color: theme.palette.primary.main,
                                }}
                                aria-label="Editar"
                                LinkComponent={Link}
                                href={`/brokers/edit/${row.corretoraId}/`}
                              >
                                <EditIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                              <IconButton
                                sx={{ background: red[100] }}
                                color="secondary"
                                aria-label="Excluir"
                                onClick={() => {
                                  setDeleteOpen(true);
                                  setDeleteId(row.corretoraId);
                                  setDeleteName(row.nome);
                                }}
                              >
                                <CloseIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2} justifyContent="center" alignItems="center">
                  <Pagination
                    count={pageCount}
                    disabled={loading}
                    color="primary"
                    onChange={handlePageChange}
                  />
                </Stack>
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
      <DeleteDialog
        open={deleteOpen}
        name={deleteName}
        setOpen={setDeleteOpen}
        handleDelete={handleDelete}
      />
    </Layout>
  );
};
