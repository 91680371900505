import { ChartModel, Index, IndexPayload, PaginatedResponse } from '../types';
import { BaseClient } from './BaseService';

export interface IndexApi {
  getIndexById(id: string): Promise<Index>;
  getIndexes(): Promise<Array<Index>>;
  getPagedIndexes(page: number): Promise<PaginatedResponse<Index>>;
  deleteIndex(id: string): Promise<void>;
  editIndex(payload: IndexPayload): Promise<Index>;
  addIndex(payload: IndexPayload): Promise<Index>;
  getCharts(): Promise<Array<ChartModel>>;
}

class IndexService extends BaseClient implements IndexApi {
  async getIndexById(id: string): Promise<Index> {
    const { data } = await this.api.get(`/${id}/`);
    return data as Index;
  }

  async getPagedIndexes(page: number): Promise<PaginatedResponse<Index>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<Index>;
  }

  async getIndexes(): Promise<Array<Index>> {
    const { data } = await this.api.get(`/`);
    return data as Array<Index>;
  }

  async getCharts(): Promise<Array<ChartModel>> {
    const { data } = await this.api.get(`/graficos`);
    return data as Array<ChartModel>;
  }

  async deleteIndex(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editIndex(payload: IndexPayload): Promise<Index> {
    const { data } = await this.api.put(`/`, payload);
    return data.corretora as Index;
  }

  async addIndex(payload: IndexPayload): Promise<Index> {
    const { data } = await this.api.post(`/`, payload);
    return data.corretora as Index;
  }
}

export default IndexService;
