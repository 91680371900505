import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
/* import FormGroup from '@mui/material/FormGroup'; */
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Layout } from '../../components/Layout';
import { ImageField } from '../../components/ImageField';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { SearchField } from '../../components/SearchField';
import { Loading } from '../../components/Loading';
import { useFinancialInstitution } from '../../contexts/contexts';
import {
  handleErrorMessage,
  initField,
  serializePayload,
  SUPORTED_IMAGE_FIELD_TYPES,
} from '../../utils';
import {
  FileFieldType,
  FinancialInstitutionPayload,
  FinancialInstitutionFormType,
  FinancialInstitution,
  SelectOption,
} from '../../types';
import { Form } from './styled';

import { initForm } from './utils';

import { useAuth, useCore, useBroker } from '../../contexts/contexts';

const HalfedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
}));

export const FinancialInstitutionForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const { user } = useAuth();
  const { fetchCepData } = useCore();
  const { brokerApi } = useBroker();
  const { financialInstitutionApi } = useFinancialInstitution();
  const theme = useTheme();
  const navigate = useNavigate();
  const [error, setError] = useState<string[] | string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [financialInstitution, setFinancialInstitution] = useState<
    FinancialInstitution | undefined
  >();
  const { financialInstitutions, setFinancialInstitutions } =
    useFinancialInstitution();
  const [brokersOptions, setBrokersOptions] = useState<Array<SelectOption>>();
  const { id } = useParams();

  const [fetchingFinancialInstitution, setFetchingFinancialInstitution] =
    useState<boolean>(!isCreation);
  const [fetchingError, setFetchingError] = useState<string>();

  const [form, setForm] = useState<FinancialInstitutionFormType>(
    initForm({}) as FinancialInstitutionFormType
  );
  const [Logomarca, setLogomarca] = useState(
    initField<FileFieldType>({
      name: 'Logomarca',
      value: null,
      label: 'Logo',
    })
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof FinancialInstitutionFormType];
    auxForm[e.target.name as keyof FinancialInstitutionFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = {
        ...serializePayload<
          FinancialInstitutionFormType,
          FinancialInstitutionPayload
        >(form),
        Logomarca: Logomarca.value,
      } as FinancialInstitutionPayload;
      if (payload?.InstituicaoFinanceiraId ?? false) {
        const data = await financialInstitutionApi.editFinancialInstitution(
          payload
        );
        if ((financialInstitutions?.length ?? -1) > 0) {
          const auxFinancialInstitutions = [
            ...financialInstitutions?.map(
              (financialInstitution: FinancialInstitution) => {
                if (
                  financialInstitution.instituicaoFinanceiraId ===
                  data.instituicaoFinanceiraId
                ) {
                  return data;
                }
                return financialInstitution;
              }
            ),
          ];
          setFinancialInstitutions(auxFinancialInstitutions);
        }
      } else {
        const data = await financialInstitutionApi.addFinancialInstitution(
          payload
        );
        if ((financialInstitutions?.length ?? -1) > 0)
          setFinancialInstitutions(financialInstitutions.concat([data]));
      }
      navigate('/financial-institutions');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const getFinancialInstitution = async () => {
    if (id ?? false) {
      setFetchingError(undefined);
      try {
        setFetchingFinancialInstitution(true);
        const data: FinancialInstitution =
          await financialInstitutionApi.getFinancialInstitutionById(
            id as string
          );
        setForm(initForm({ data }) as FinancialInstitutionFormType);
        setFinancialInstitution(data);
      } catch (e: unknown) {
        setFetchingError(handleErrorMessage(e));
      } finally {
        setFetchingFinancialInstitution(false);
      }
    }
  };

  const getBrokersOptions = async () => {
    try {
      setLoading(true);
      const data = await brokerApi.getBrokers();
      setBrokersOptions(
        data.map(
          (broker) =>
            ({ label: broker.nome, value: broker.corretoraId } as SelectOption)
        )
      );
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFinancialInstitution();
    getBrokersOptions();
  }, []);

  useEffect(() => {
    if ((brokersOptions?.length ?? -1) > 0 && (financialInstitution ?? false)) {
      const broker = brokersOptions?.filter((option) => {
        return option?.value === financialInstitution?.corretoraId;
      });
      if ((broker?.length ?? -1) > 0) {
        setForm({
          ...form,
          CorretoraId: {
            ...form.CorretoraId,
            value: (broker ?? [null])[0] as SelectOption,
          },
        });
      }
    }
  }, [brokersOptions, financialInstitution]);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {fetchingError && fetchingError}
        <Typography variant="h5" component="h2" color="textSecondary">
          {isCreation ? 'Cadastro de Segurado' : 'Edição de Segurado'}
        </Typography>
        {fetchingFinancialInstitution && <Loading />}
        {!fetchingFinancialInstitution && (
          <Form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              gap: theme.spacing(2),
              flexDirection: 'column',
            }}
          >
            <ErrorMsg error={error} />
            <HalfedBox>
              <TextField field={form.Email} extraAttrs={extraAttrs} />
              {(isCreation || user.role === 'admin') && (
                <SearchField
                  extraAttrs={{ value: form.CorretoraId.value }}
                  options={brokersOptions}
                  label={form.CorretoraId.label}
                  required={form.CorretoraId.required ? true : false}
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      CorretoraId: { ...form.CorretoraId, value },
                    });
                  }}
                />
              )}
            </HalfedBox>
            <TextField field={form.Nome} extraAttrs={extraAttrs} />
            <HalfedBox>
              <TextField field={form.CNPJ} extraAttrs={extraAttrs} />
              <TextField field={form.Telefone} extraAttrs={extraAttrs} />
            </HalfedBox>
            <HalfedBox>
              <TextField field={form.Senha} extraAttrs={extraAttrs} />
              <TextField field={form.ConfirmaSenha} extraAttrs={extraAttrs} />
            </HalfedBox>

            <HalfedBox>
              <Box
                style={{
                  width: '100%',
                  gap: theme.spacing(2),
                  marginBottom: theme.spacing(3),
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    gap: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <TextField
                    field={form.CEP}
                    extraAttrs={{
                      ...extraAttrs,
                      onBlur: () => fetchCepData(form.CEP.value, form, setForm),
                    }}
                  />
                  <TextField field={form.UF} extraAttrs={extraAttrs} />
                </Box>
                <HalfedBox>
                  <TextField field={form.Complemento} extraAttrs={extraAttrs} />
                  <TextField field={form.Bairro} extraAttrs={extraAttrs} />
                </HalfedBox>
              </Box>
              <HalfedBox>
                <Box style={{ width: '100%' }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: theme.spacing(2),
                      width: '100%',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <TextField field={form.Endereco} extraAttrs={extraAttrs} />
                    <TextField
                      field={form.NumeroEndereco}
                      extraAttrs={extraAttrs}
                    />
                  </Box>
                  <TextField field={form.Cidade} extraAttrs={extraAttrs} />
                </Box>
              </HalfedBox>
            </HalfedBox>
            <Grid
              container
              spacing={0}
              gap={2}
              direction="row"
              justifyContent="center"
              alignItems="start"
              alignContent="center"
              wrap="wrap"
            >
              <ImageField
                setField={(field: FileFieldType) => {
                  setLogomarca(field);
                  setError(undefined);
                }}
                field={Logomarca}
                allowedFileTypes={SUPORTED_IMAGE_FIELD_TYPES}
              />
            </Grid>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                loading={loading}
                disabled={error ? true : undefined}
                endIcon={
                  <ArrowForwardIcon
                    sx={{ color: theme.palette.common.white }}
                  />
                }
              >
                <Typography variant="button" color="white">
                  {isCreation ? 'Cadastrar' : 'Salvar'}
                </Typography>
              </Button>
            </Box>
          </Form>
        )}
      </Grid>
    </Layout>
  );
};
