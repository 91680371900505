export const BrazilCoordinates = {
  lat: -16.6640359,
  lng: -50.64141,
};

export function getBoundsZoomLevel(
  bounds: google.maps.LatLngBounds,
  mapDim: { height: number; width: number }
) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export function parseCoordinates(
  latitudeInput: string,
  longitudeInput: string
) {
  const latDecimal = dmsToDecimal(latitudeInput);
  const lngDecimal = dmsToDecimal(longitudeInput);
  return { lat: latDecimal, lng: lngDecimal };
}

function dmsToDecimal(dms: string) {
  const parts = dms.split(/[°'"]+/);

  const degrees = parseFloat(parts[0]);
  const minutes = parseFloat(parts[1] || '0');
  const seconds = parseFloat(parts[2] || '0');

  const direction = parts[3].trim();

  let decimal = degrees + minutes / 60 + seconds / 3600;

  if (direction === 'S' || direction === 'W') {
    decimal = -decimal;
  }

  return decimal;
}

export function convertToDecimalCoordinate(input: string) {
  // Extracting degrees, minutes, seconds, and direction from the input string
  const regex = /^(\d+)°(\d+)'([\d.]+)"([NSEW])$/;
  const match = input.match(regex);

  if (!match) {
    console.error('Invalid input format');
    return null;
  }

  const degrees = parseInt(match[1]);
  const minutes = parseInt(match[2]);
  const seconds = parseFloat(match[3]);
  const direction = match[4];

  // Convert degrees, minutes, and seconds to decimal degrees
  let decimalCoordinate = degrees + minutes / 60 + seconds / 3600;

  // Adjust the sign based on the direction (N/S/E/W)
  if (direction === 'S' || direction === 'W') {
    decimalCoordinate = -decimalCoordinate;
  }

  return decimalCoordinate;
}
