import axios, { AxiosRequestHeaders } from 'axios';

export class BaseClient {
  protected api;

  constructor({
    basePath = '',
    contentType = 'application/json',
    token,
  }: {
    basePath?: string; // e.g. /account
    contentType?: string;
    token?: string;
  }) {
    const baseURL = `${process.env.REACT_APP_API_URL}${basePath}`;
    const headers: AxiosRequestHeaders = {
      'Content-Type': contentType,
    };
    if (token ?? false) {
      headers.Authorization = `Bearer ${token}`;
    }
    this.api = axios.create({
      baseURL,
      headers,
    });
  }
}
