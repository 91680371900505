import React from 'react';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BlockIcon from '@mui/icons-material/Block';
import { useTheme } from '@mui/material/styles';

import { downloadLink } from '../../utils';

export const DownloadLinkButton = ({ url }: { url: string }) => {
  const theme = useTheme();

  return url ? (
    <IconButton
      sx={{
        background: green[100],
        color: theme.palette.primary.main,
      }}
      onClick={() => {
        downloadLink(url);
      }}
    >
      <FileDownloadIcon sx={{ fontSize: 14 }} />
    </IconButton>
  ) : (
    <IconButton color="secondary" disabled>
      <BlockIcon sx={{ fontSize: 16 }} />
    </IconButton>
  );
};
