import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/lab/LoadingButton';

import { ErrorMsg } from '../ErrorMsg';
import { handleErrorMessage } from '../../utils';

export const DeleteDialog = ({
  open,
  setOpen,
  handleDelete,
  name = 'este item',
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: () => void;
  name?: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await handleDelete();
      setOpen(false);
    } catch (error: unknown) {
      setError(handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason == 'backdropClick' && loading) return;
        setOpen(false);
      }}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <Box marginTop={2}>
        <ErrorMsg error={error} />
      </Box>
      <DialogTitle id="delete-dialog-title">Confimar Exclusão</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Você tem certeza que deseja excluir {name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          loading={loading}
          onClick={handleSubmit}
          color="secondary"
          autoFocus
          variant="outlined"
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
