import React, { createContext, useState, useContext, useEffect } from 'react';

import AccountantService, {
  AccountantApi,
} from '../services/AccountantService';

import { Accountant } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './contexts';

export type AccountantContextType = {
  accountants: Array<Accountant> | undefined | null;
  setAccountants: React.Dispatch<
    React.SetStateAction<Array<Accountant> | undefined | null>
  >;
  accountant: Accountant | undefined;
  getAccountant(id: string): Promise<Accountant | null>;
  setAccountant: React.Dispatch<React.SetStateAction<Accountant | undefined>>;
  accountantLoading: boolean;
  setAccountantLoading: React.Dispatch<React.SetStateAction<boolean>>;
  accountantError: string | undefined;
  setAccountantError: React.Dispatch<React.SetStateAction<string | undefined>>;
  accountantApi: AccountantApi;
  getAccountants(): Promise<Array<Accountant> | undefined | null>;
  exportAccountants(ids: Array<string>): Promise<void>;
  acceptFinancialInstitutions(ids: Array<string>): Promise<void>;
  acceptAccountants(ids: Array<string>): Promise<void>;
  linkUser(userId: string, accountantId: string): Promise<void>;
  generateCSV(items: Array<Accountant>): Promise<string | null>;
};

export const AccountantContext = createContext<AccountantContextType>(
  {} as unknown as AccountantContextType
);

export const AccountantProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [accountants, setAccountants] = useState<Array<Accountant>>();
  const [accountant, setAccountant] = useState<Accountant>();
  const [accountantLoading, setAccountantLoading] = useState<boolean>(false);
  const [accountantError, setAccountantError] = useState<string>();

  const { token } = useAuth();

  const accountantApi = new AccountantService({
    basePath: '/v1/cotador',
    contentType: 'multipart/form-data',
    token,
  });

  const accountantsCount = accountants?.length ?? -1;

  const getAccountants = async () => {
    if (accountantsCount < 0 && accountantLoading === false) {
      try {
        setAccountantLoading(true);
        const data = await accountantApi.getAccountants();
        setAccountants(data);
      } catch (e: unknown) {
        console.error(handleErrorMessage(e));
      } finally {
        setAccountantLoading(false);
      }
    }
  };

  const getAccountant = async (id: string) => {
    if (accountantLoading === false) {
      try {
        setAccountantError(undefined);
        setAccountantLoading(true);
        const data = await accountantApi.getAccountantById(id);
        setAccountant(data);
        return data;
      } catch (e: unknown) {
        setAccountantError(handleErrorMessage(e));
      } finally {
        setAccountantLoading(false);
      }
    }
    return null;
  };

  useEffect(() => {
    if (accountants === null && accountantLoading === false) getAccountants();
  }, [accountants]);

  const exportAccountants = async (ids: Array<string>) => {
    try {
      setAccountantError(undefined);
      await accountantApi.exportAccountants(ids);
    } catch (e) {
      setAccountantError(handleErrorMessage(e));
    }
  };

  const acceptFinancialInstitutions = async (ids: Array<string>) => {
    try {
      setAccountantLoading(true);
      setAccountantError(undefined);
      await accountantApi.acceptFinancialInstitutions(ids);
    } catch (e) {
      setAccountantError(handleErrorMessage(e));
    } finally {
      setAccountantLoading(false);
    }
  };

  const acceptAccountants = async (ids: Array<string>) => {
    try {
      setAccountantLoading(true);
      setAccountantError(undefined);
      await accountantApi.acceptAccountants(ids);
    } catch (e) {
      setAccountantError(handleErrorMessage(e));
    } finally {
      setAccountantLoading(false);
    }
  };

  const linkUser = async (userId: string, accountantId: string) => {
    try {
      setAccountantLoading(true);
      setAccountantError(undefined);
      await accountantApi.linkUser(userId, accountantId);
    } catch (e) {
      setAccountantError(handleErrorMessage(e));
    } finally {
      setAccountantLoading(false);
    }
  };

  const generateCSV = async (items: Array<Accountant>) => {
    if (items.length > 0) {
      const fields = Object.keys(items[0]).filter(
        (field) => !field.endsWith('Id')
      ); // eslint-disable-next-line
      const replacer = function (_: string, value: any) {
        return value === null ? '' : value;
      };
      const csv = items.map(function (row: Accountant) {
        return fields
          .map(function (fieldName) {
            return JSON.stringify(row[fieldName as keyof Accountant], replacer);
          })
          .join(',');
      });
      csv.unshift(fields.join(','));
      return csv.join('\r\n');
    }
    return null;
  };

  return (
    <AccountantContext.Provider
      value={
        {
          accountantApi,
          accountants,
          setAccountants,
          accountant,
          getAccountant,
          setAccountant,
          getAccountants,
          accountantLoading,
          setAccountantLoading,
          accountantError,
          setAccountantError,
          exportAccountants,
          acceptFinancialInstitutions,
          acceptAccountants,
          linkUser,
          generateCSV,
        } as AccountantContextType
      }
    >
      {children}
    </AccountantContext.Provider>
  );
};

export const useAccountant = () => useContext(AccountantContext);
