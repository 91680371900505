import { AxiosError } from 'axios';

import { QueryOptions, useQuery } from '@tanstack/react-query';

import { useIndex } from '../contexts';
import { ChartModel } from '../types';

export const CHART_QUERY_KEY = 'load-charts';

type QueryParams = Omit<
  QueryOptions<ChartModel[], Error | AxiosError>,
  'queryFn'
>;

export function useChartList({ ...options }: QueryParams = {}) {
  const { indexApi } = useIndex();

  const { data, ...query } = useQuery({
    staleTime: 1 * 1000 * 60,
    refetchInterval: 1000 * 60,
    queryKey: [CHART_QUERY_KEY],
    queryFn: async () => {
      const data = await indexApi.getCharts();
      return data;
    },
    ...options,
  });

  return { data, ...query };
}
