import React, { createContext, useState, useContext } from 'react';

import { handleErrorMessage } from '../utils';

import ProductService, { ProductApi } from '../services/ProductService';

import { Product, SelectOption, SubProduct } from '../types';
import { useAuth } from './contexts';

export type ProductContextType = {
  productApi: ProductApi;
  products: Array<Product> | undefined;
  setProducts: React.Dispatch<React.SetStateAction<Array<Product> | undefined>>;
  product: Product;
  setProduct: React.Dispatch<React.SetStateAction<Product | undefined>>;
  productLoading: boolean;
  setProductLoading: React.Dispatch<React.SetStateAction<boolean>>;
  productError: string;
  setProductError: React.Dispatch<React.SetStateAction<string | undefined>>;
  getProduct: (id: string) => Promise<Product | undefined>;
  productsOptions: Array<SelectOption>;
  productOptionsWithoutDisabled: Array<SelectOption>;
  setProductsOptions: React.Dispatch<
    React.SetStateAction<Array<SelectOption> | undefined>
  >;
  getProductsOptions(): Promise<Array<SelectOption> | undefined>;
  getProductOptionsWithoutDisabled(): Promise<Array<SelectOption> | undefined>;
  subProductsOptions: Array<SelectOption> | undefined;
  setSubProductsOptions: React.Dispatch<
    React.SetStateAction<Array<SelectOption> | undefined>
  >;
  getSubProductsOptions(): Promise<Array<SelectOption> | undefined>;

  subProduct: SubProduct;
  setSubProduct: React.Dispatch<React.SetStateAction<SubProduct | undefined>>;
  getSubProductById(subProductId: string): Promise<void>;
};

export const ProductContext = createContext<ProductContextType>(
  {} as unknown as ProductContextType
);

export const ProductProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [products, setProducts] = useState<Array<Product>>();
  const [product, setProduct] = useState<Product>();
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [productError, setProductError] = useState<string>();
  const [productsOptions, setProductsOptions] = useState<Array<SelectOption>>();
  const [productOptionsWithoutDisabled, setProductOptionsWithoutDisabled] =
    useState<Array<SelectOption>>();
  const [subProductsOptions, setSubProductsOptions] =
    useState<Array<SelectOption>>();
  const [subProduct, setSubProduct] = useState<SubProduct>();

  const { token } = useAuth();

  const productApi = new ProductService({
    basePath: '/v1/produto',
    contentType: 'multipart/form-data',
    token,
  });

  const getProduct = async (id: string) => {
    let product: Product | undefined = undefined;
    if (productLoading === false) {
      setProductError(undefined);
      try {
        setProductLoading(true);
        const data = await productApi.getProductById(id ?? '');
        product = data;
        setProduct(data);
      } catch (e: unknown) {
        setProductError(handleErrorMessage(e));
      } finally {
        setProductLoading(false);
      }
    }
    return product;
  };

  const getProductsOptions = async () => {
    let options: Array<SelectOption> | undefined;
    try {
      setProductLoading(true);
      const auxData = await productApi.getProducts();
      options = auxData.map(
        (product) =>
          ({
            label: product.nome,
            value: product.produtoId,
          } as SelectOption)
      );
      setProductsOptions(options);
    } catch (error: unknown) {
      setProductError(handleErrorMessage(error));
    }
    setProductLoading(false);
    return options;
  };

  const getProductOptionsWithoutDisabled = async () => {
    let options: Array<SelectOption> | undefined;
    try {
      setProductLoading(true);
      const auxData = await productApi.getProductsWithoutDisabled();
      options = auxData.map(
        (product) =>
          ({
            label: product.nome,
            value: product.produtoId,
          } as SelectOption)
      );
      setProductOptionsWithoutDisabled(options);
    } catch (error: unknown) {
      setProductError(handleErrorMessage(error));
    }
    setProductLoading(false);
    return options;
  };

  const getSubProductsOptions = async () => {
    const auxOptions = product?.subProduto?.map((item) => {
      return {
        label: `${item.estado} - ${item.cidade}`,
        value: item.subProdutoId,
      } as SelectOption;
    });
    setSubProductsOptions(auxOptions);
    return auxOptions;
  };

  const getSubProductById = async (subProductId: string) => {
    try {
      const data = await productApi.getSubProductById(subProductId);
      setSubProduct(data);
    } catch {
      setSubProduct(undefined);
    }
  };

  return (
    <ProductContext.Provider
      value={
        {
          productApi,
          products,
          setProducts,
          product,
          setProduct,
          productLoading,
          setProductLoading,
          productError,
          setProductError,
          getProduct,
          productsOptions,
          productOptionsWithoutDisabled,
          setProductsOptions,
          getProductsOptions,
          getProductOptionsWithoutDisabled,
          getSubProductsOptions,
          subProductsOptions,
          setSubProductsOptions,
          subProduct,
          setSubProduct,
          getSubProductById,
        } as ProductContextType
      }
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => useContext(ProductContext);
