import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Button from '@mui/lab/LoadingButton';

import { ErrorMsg } from '../../components/ErrorMsg';
import { handleErrorMessage } from '../../utils';

import { useAuth } from '../../contexts';
import { ForgotPasswordPayload } from '../../types';

export const ForgotPassword = () => {
  const theme = useTheme();
  const { resetPassword } = useAuth();

  const [payload, setPayload] = useState<ForgotPasswordPayload>({
    email: '',
  } as ForgotPasswordPayload);

  const [successMessage, setSuccessMessage] = useState<string>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxPayload = { ...payload };
    auxPayload[e.target.name as keyof ForgotPasswordPayload] = e.target.value;
    setPayload(auxPayload);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      await resetPassword(payload);
      setSuccessMessage(payload.email);
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <main
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        gap: theme.spacing(2),
        paddingBottom: 14,
      }}
    >
      <Link href="/">Voltar</Link>
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          minWidth: '240px',
        }}
      >
        <Typography variant="h6" fontSize={24} color="primary" align="center">
          Esqueci Minha Senha
        </Typography>
        <ErrorMsg error={error} />
        {successMessage ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: theme.spacing(2),
              maxWidth: 500,
            }}
          >
            <Typography variant="body1" color="textSecondary" align="center">
              Uma nova senha de acesso foi enviada para o email{' '}
              <span style={{ fontWeight: 'bold' }}>{successMessage}</span>
            </Typography>
            <Divider />
            <Button variant="contained" href="/login" component={Link}>
              <Typography variant="button" color="white">
                Voltar ao Login
              </Typography>
            </Button>
          </div>
        ) : (
          <>
            <TextField
              id="email"
              name="email"
              type="email"
              required
              label="E-mail"
              size="small"
              fullWidth
              disabled={loading}
              style={{ minWidth: '213px' }}
              value={payload.email}
              onChange={handleChange}
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ minWidth: '213px' }}
              disabled={error ? true : undefined}
              loading={loading}
            >
              <Typography variant="button" color="white">
                Confirmar
              </Typography>
            </Button>
          </>
        )}
      </form>

      <div
        style={{
          position: 'fixed',
          zIndex: 8,
          height: 14,
          bottom: 0,
          width: '100%',
          backgroundColor: theme.palette.primary.main,
        }}
      ></div>
    </main>
  );
};
