import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ErrorMsg } from '../ErrorMsg';

import { FileFieldType } from '../../types';

interface ImageFieldProps {
  field: FileFieldType;
  allowedFileTypes: Array<string>;
  setField: (value: FileFieldType) => void;
}

export const ImageField = ({
  field,
  allowedFileTypes,
  setField,
}: ImageFieldProps) => {
  const theme = useTheme();

  const [fileName, setFileName] = useState<string>();

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      setField({ ...field, errors: [] });
      if (acceptedFiles.length === 1) {
        const fileName = acceptedFiles[0].name.trim();
        setFileName(fileName);
        if (
          allowedFileTypes.includes(
            (fileName.split('.').at(-1) ?? '').toLowerCase()
          )
        ) {
          const reader = new FileReader();
          reader.onload = function () {
            setField({ ...field, errors: [], value: acceptedFiles[0] });
          };
          reader.readAsDataURL(acceptedFiles[0]);
        } else {
          setField({ ...field, errors: ['Arquivo inválido'] });
          setFileName(undefined);
        }
      } else {
        setFileName(undefined);
        setField({ ...field, errors: ['Arquivo inválido'] });
      }
    },
    [field]
  );

  useEffect(() => {
    if (field.value !== null) {
      setFileName(field.value?.name);
    }
  }, [field]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      maxWidth={180}
    >
      <div
        {...getRootProps()}
        style={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: '4px',
          minWidth: 180,
          padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
          marginBottom: theme.spacing(1),
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" color="textSecondary">
          <CloudUploadIcon sx={{ color: theme.palette.common.white }} />
        </Typography>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography variant="caption" color="white">
            Arraste e solte o arquivo aqui!
          </Typography>
        ) : (
          <>
            <Typography variant="caption" color="white" align="center">
              {field.label}
            </Typography>
            {fileName && (
              <Typography variant="caption" align="center" color="white">
                {fileName.length > 16
                  ? `${fileName.slice(0, 13)}...${fileName.split('.').at(-1)}`
                  : fileName}
              </Typography>
            )}
          </>
        )}
      </div>
      <ErrorMsg error={field.errors} />
    </Box>
  );
};
