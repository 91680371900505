import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { styled, useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import MTextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { Loading } from '../../components/Loading';
import { SearchField } from '../../components/SearchField';
import { handleErrorMessage, serializePayload } from '../../utils';
import {
  SubProductFormType,
  Product,
  SelectOption,
  SubProductPayload,
} from '../../types';
import { useCore, useProduct } from '../../contexts/contexts';

import { Form } from './styled';

import { AuthorizationCheckbox } from '../../components/FormCheckboxes';

import { initForm } from './utils';
import { InputAdornment } from '@mui/material';

const HalfedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  width: '100%',
}));

export const SubProductForm = ({
  isCreation = false,
}: {
  isCreation?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { productId, id } = useParams();

  const [error, setError] = useState<string[] | string>();
  const [loading, setLoading] = useState<boolean>(false); // eslint-disable-next-line
  const [_, setProduct] = useState<Product | undefined>();
  const { productApi } = useProduct();

  const { stateOptions, cityOptions, fetchStateOptions, fetchCityOptions } =
    useCore();

  const [fetchingProduct, setFetchingProduct] = useState<boolean>(!isCreation);
  const [fetchingError, setFetchingError] = useState<string>();

  const [form, setForm] = useState<SubProductFormType>(
    initForm({}) as SubProductFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof SubProductFormType];
    auxForm[e.target.name as keyof SubProductFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleDateChange = (
    fieldName: keyof SubProductFormType,
    newValue: Dayjs | null
  ) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[fieldName as keyof SubProductFormType];
    auxForm[fieldName] = {
      ...field,
      value: newValue,
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = {
        ...serializePayload<SubProductFormType, SubProductPayload>(form),
        ProdutoId: productId,
      } as SubProductPayload;
      await productApi.addSubProduct(payload);
      if (searchParams.get('next')) {
        navigate(searchParams.get('next') ?? '');
      } else {
        navigate('/products');
      }
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const getSubProduct = async () => {
    if (productId ?? false) {
      setFetchingError(undefined);
      try {
        setFetchingProduct(true);
        const data: Product = await productApi.getProductById(
          productId as string
        );
        setForm(initForm({}) as SubProductFormType);
        setProduct(data);
      } catch (e: unknown) {
        setFetchingError(handleErrorMessage(e));
      } finally {
        setFetchingProduct(false);
      }
    }
  };

  const initFormData = async () => {
    if (id ?? false) {
      const initialData = await productApi.getSubProductById(productId ?? '');
      const auxForm = {
        ...initForm({}),
        EstadoId: {
          ...form.EstadoId,
          value:
            (await fetchStateOptions()).find((item: SelectOption) => {
              return item?.value === initialData.estadoId;
            }) ?? null,
        },
        CidadeId: {
          ...form.CidadeId,
          value:
            (await fetchCityOptions(initialData?.estadoId ?? '')).find(
              (item: SelectOption) => {
                return item?.value === initialData.cidadeId;
              }
            ) ?? null,
        },
      } as SubProductFormType;
      setForm(auxForm);
    }
  };

  useEffect(() => {
    initFormData();
    fetchStateOptions();
    getSubProduct();
  }, []);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {fetchingError && <ErrorMsg error={fetchingError} />}

        {fetchingProduct && <Loading />}
        {!fetchingError && !fetchingProduct && (
          <>
            <Typography variant="h5" component="h2" color="textSecondary">
              {isCreation && (
                <>
                  Cadastro de Sub Produto
                  <Button
                    LinkComponent={Link}
                    href={`/products/edit/${productId}/`}
                    variant="contained"
                    style={{
                      marginLeft: theme.spacing(1),
                      color: theme.palette.common.white,
                    }}
                    startIcon={
                      <EditIcon style={{ color: theme.palette.common.white }} />
                    }
                  >
                    Editar Produto
                  </Button>
                </>
              )}
            </Typography>
            <Form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                gap: theme.spacing(2),
                flexDirection: 'column',
              }}
            >
              <ErrorMsg error={error} />
              <HalfedBox></HalfedBox>
              <HalfedBox>
                <SearchField
                  extraAttrs={{ value: form.EstadoId.value }}
                  options={stateOptions}
                  label={form.EstadoId.label}
                  id={form.EstadoId.id}
                  noOptionsText="Selecione o Estado..."
                  required={form.EstadoId.required ? true : false}
                  loading={loading}
                  handleChange={(option: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      CidadeId: {
                        ...form.CidadeId,
                        value: null,
                      },
                      EstadoId: {
                        ...form.EstadoId,
                        value: option,
                      },
                    });
                    fetchCityOptions(option?.value ?? '');
                  }}
                />

                <SearchField
                  extraAttrs={{ value: form.CidadeId.value }}
                  options={cityOptions}
                  label={form.CidadeId.label}
                  id={form.CidadeId.id}
                  required={form.CidadeId.required ? true : false}
                  loading={loading}
                  handleChange={(option: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      CidadeId: {
                        ...form.CidadeId,
                        value: option,
                      },
                    });
                  }}
                />
              </HalfedBox>
              <HalfedBox>
              <TextField field={form.Taxa} extraAttrs={extraAttrs} />
                <TextField
                  field={form.CoberturaMaxima}
                  extraAttrs={{...extraAttrs,
                    InputProps: {
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }
                  }}
                />
              </HalfedBox>
              <HalfedBox>
                <DesktopDatePicker
                  label={form.DataInicioGatilho1.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataInicioGatilho1.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataInicioGatilho1.name as keyof SubProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
                <DesktopDatePicker
                  label={form.DataFinalGatilho1.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataFinalGatilho1.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataFinalGatilho1.name as keyof SubProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
              </HalfedBox>
              <HalfedBox>
                <DesktopDatePicker
                  label={form.DataInicioGatilho2.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataInicioGatilho2.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataInicioGatilho2.name as keyof SubProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
                <DesktopDatePicker
                  label={form.DataFinalGatilho2.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataFinalGatilho2.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataFinalGatilho2.name as keyof SubProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
              </HalfedBox>
              <HalfedBox>
                <DesktopDatePicker
                  label={form.DataInicioGatilho3.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataInicioGatilho3.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataInicioGatilho3.name as keyof SubProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
                <DesktopDatePicker
                  label={form.DataFinalGatilho3.label}
                  inputFormat="DD/MM/YYYY"
                  disabled={loading}
                  value={form.DataFinalGatilho3.value}
                  onChange={(newValue) => {
                    handleDateChange(
                      form.DataFinalGatilho3.name as keyof SubProductFormType,
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <MTextField {...params} size="small" fullWidth />
                  )}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.Strike1Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike2Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike3Gatilho1}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.Strike1Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike2Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike3Gatilho2}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.Strike1Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike2Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.Strike3Gatilho3}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.IndenizacaoStrike1Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike2Gatilho1}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike3Gatilho1}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>

              <HalfedBox>
                <TextField
                  field={form.IndenizacaoStrike1Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike2Gatilho2}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike3Gatilho2}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              <HalfedBox>
                <TextField
                  field={form.IndenizacaoStrike1Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike2Gatilho3}
                  extraAttrs={extraAttrs}
                />
                <TextField
                  field={form.IndenizacaoStrike3Gatilho3}
                  extraAttrs={extraAttrs}
                />
              </HalfedBox>
              {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FormGroup style={{ display: 'flex' }}>
                  <FormControlLabel
                    sx={{
                      maxWidth: 618,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.pagamentoLinear.name}
                        onChange={handleChange}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.pagamentoLinear.label}
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    display: 'flex',
                  }}
                >
                  <FormControlLabel
                    sx={{
                      maxWidth: 618,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.nivelDeCoberturaVariavel.label}
                        onChange={handleChange}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.nivelDeCoberturaVariavel.label}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box> */}
              {!isCreation && (
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="wrap"
                  sx={{
                    marginBottom: theme.spacing(3),
                    marginTop: theme.spacing(2),
                  }}
                >
                  <FormGroup style={{ display: 'flex' }}>
                    <AuthorizationCheckbox />
                  </FormGroup>
                </Grid>
              )}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={loading}
                  disabled={error ? true : undefined}
                  endIcon={
                    <ArrowForwardIcon
                      sx={{ color: theme.palette.common.white }}
                    />
                  }
                >
                  <Typography variant="button" color="white">
                    {isCreation ? 'Cadastrar' : 'Salvar'}
                  </Typography>
                </Button>
              </Box>
            </Form>
          </>
        )}
      </Grid>
    </Layout>
  );
};
