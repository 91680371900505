import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Layout } from '../../components/Layout';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { useIndex, useCore } from '../../contexts/contexts';
import { handleErrorMessage, serializePayload } from '../../utils';
import { IndexPayload, IndexFormType, SelectOption } from '../../types';
import { Form } from './styled';
import Grid from '@mui/material/Grid';

import { initForm } from './utils';
import { Loading } from '../../components/Loading';
import { SearchField } from '../../components/SearchField';
import { useChartList } from '../../queries/useChartList';
export const IndexForm = ({ isCreation = false }: { isCreation?: boolean }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const { indexError, indexLoading, getIndex, indexApi, index } = useIndex();
  const { stateLoading, stateError, cityError } = useCore();

  const chartListQuery = useChartList();

  const fetchLoading = indexLoading || stateLoading || chartListQuery.isLoading;
  const fetchError = indexError || stateError || cityError;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string[] | string>();
  const [form, setForm] = useState<IndexFormType>(
    initForm({}) as IndexFormType
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof IndexFormType];
    auxForm[e.target.name as keyof IndexFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = serializePayload<IndexFormType, IndexPayload>(form);
      if (payload?.IndiceId ?? false) {
        await indexApi.editIndex(payload);
      } else {
        await indexApi.addIndex(payload);
      }
      navigate('/indexes');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const initFormData = async () => {
    if (id ?? false) {
      const initialData = await getIndex(id ?? '');
      const auxForm = initForm({ data: initialData }) as IndexFormType;
      setForm(auxForm);
    }
  };

  useEffect(() => {
    initFormData();
  }, []);

  useEffect(() => {
    if (index && chartListQuery.data) {
      const auxForm = { ...form };
      const field = form.GraficoId;
      auxForm.GraficoId = {
        ...field,
        value: {
          value: index?.graficoId ?? '',
          label:
            chartListQuery.data?.find((i) => i.graficoId === index?.graficoId)
              ?.nome ?? '',
        },
      };
      setForm(auxForm);
    }
  }, [index, chartListQuery.data]);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        <Typography variant="h5" component="h2" color="textSecondary">
          {isCreation ? 'Cadastro de Índice' : 'Edição de Índice'}
        </Typography>
        {fetchLoading ? (
          <Loading />
        ) : (
          <>
            {fetchError ? (
              <ErrorMsg error={fetchError} />
            ) : (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  flexDirection: 'column',
                }}
              >
                <ErrorMsg error={error} />
                <TextField field={form.Nome} extraAttrs={extraAttrs} />
                <TextField field={form.UnidadeMedida} extraAttrs={extraAttrs} />
                <TextField field={form.LabelGrafico} extraAttrs={extraAttrs} />
                <TextField field={form.LabelStrike} extraAttrs={extraAttrs} />
                <SearchField
                  extraAttrs={{ value: form.GraficoId.value }}
                  options={(chartListQuery.data ?? []).map((chart) => ({
                    label: chart.nome,
                    value: chart.graficoId,
                  }))}
                  label={form.GraficoId.label}
                  required={form.GraficoId.required ? true : false}
                  loading={loading}
                  handleChange={(value: SelectOption) => {
                    setError(undefined);
                    setForm({
                      ...form,
                      GraficoId: {
                        ...form.GraficoId,
                        value,
                      },
                    });
                  }}
                />

                <FormGroup style={{ display: 'flex' }}>
                  <FormControlLabel
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.Positivo.name}
                        onChange={handleChange}
                        checked={form.Positivo.value}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.Positivo.label}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    control={
                      <Checkbox
                        name={form.Acumulado.name}
                        onChange={handleChange}
                        checked={form.Acumulado.value}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    }
                    label={
                      <Typography variant="caption" color="textSecondary">
                        {form.Acumulado.label}
                      </Typography>
                    }
                  />
                </FormGroup>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={loading}
                    disabled={error ? true : undefined}
                    endIcon={
                      <ArrowForwardIcon
                        sx={{ color: theme.palette.common.white }}
                      />
                    }
                  >
                    <Typography variant="button" color="white">
                      {isCreation ? 'Cadastrar' : 'Salvar'}
                    </Typography>
                  </Button>
                </Box>
              </Form>
            )}
          </>
        )}
      </Grid>
    </Layout>
  );
};
