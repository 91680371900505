import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { useFinancialAgency } from '../contexts';
import { queryClient } from '../App';
import { MeAgencyResponse } from '../services/FinancialAgencyService';

export const QUERY_KEY = 'load-agency-me';

type QueryParams = Omit<
  UseQueryOptions<MeAgencyResponse>,
  'queryFn' | 'queryKey'
>;

export function useSessionAgency(options: QueryParams = {}) {
  const { financialAgencyApi } = useFinancialAgency();
  const { ...query } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
      try {
        const data = await financialAgencyApi.getMeAgency();
        return data as MeAgencyResponse;
      } catch {
        throw Error(`Erro ao carregar Agência`);
      }
    },
    retry: false,
    ...options,
  });

  return { ...query };
}

useSessionAgency.invalidateQueries = () => {
  return queryClient.invalidateQueries({
    queryKey: [QUERY_KEY],
    refetchType: 'active',
    type: 'all',
  });
};
