import React, { createContext, useState, useContext } from 'react';
import CropService, { CropApi } from '../services/CropService';

import { Crop, SelectOption } from '../types';
import { handleErrorMessage } from '../utils';
import { useAuth } from './AuthProvider';

export type CropContextType = {
  cropApi: CropApi;
  crops: Array<Crop> | undefined;
  setCrops: React.Dispatch<React.SetStateAction<Array<Crop> | undefined>>;
  crop: Crop | undefined;
  setCrop: React.Dispatch<React.SetStateAction<Crop | undefined>>;
  cropError: string | undefined;
  setCropError: React.Dispatch<React.SetStateAction<string | undefined>>;
  cropLoading: boolean;
  setCropLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getCrop(id: string): Promise<Crop>;
  getCropsOptions(): Promise<Array<SelectOption>>;
  cropsOptions: Array<SelectOption>;
  setCropsOptions: React.Dispatch<React.SetStateAction<Array<SelectOption>>>;
};

export const CropContext = createContext<CropContextType>(
  {} as unknown as CropContextType
);

export const CropProvider = ({ children }: { children?: React.ReactNode }) => {
  const [crops, setCrops] = useState<Array<Crop>>();
  const [crop, setCrop] = useState<Crop>();
  const [cropLoading, setCropLoading] = useState<boolean>(false);
  const [cropError, setCropError] = useState<string>();
  const [cropsOptions, setCropsOptions] = useState<Array<SelectOption>>();

  const { token } = useAuth();

  const cropApi = new CropService({
    basePath: '/v1/cultura',
    token,
  });

  const getCrop = async (id: string) => {
    let data: Crop | undefined = undefined;
    setCropLoading(true);
    setCropError(undefined);
    try {
      data = await cropApi.getCropById(id);
      setCrop(data);
    } catch (e: unknown) {
      setCropError(handleErrorMessage(e));
    } finally {
      setCropLoading(false);
    }
    return data as Crop;
  };

  const getCropsOptions = async () => {
    try {
      setCropLoading(true);
      const data = await cropApi.getCrops();
      const options = data.map(
        (crop) =>
          ({
            label: crop.nome,
            value: crop.culturaId,
          } as SelectOption)
      );
      setCropsOptions(options);
    } catch (error: unknown) {
      setCropError(handleErrorMessage(error));
    } finally {
      setCropLoading(false);
      return cropsOptions;
    }
  };

  return (
    <CropContext.Provider
      value={
        {
          cropApi,
          crops,
          setCrops,
          crop,
          setCrop,
          cropLoading,
          setCropLoading,
          cropError,
          setCropError,
          getCrop,
          getCropsOptions,
          cropsOptions,
          setCropsOptions,
        } as CropContextType
      }
    >
      {children}
    </CropContext.Provider>
  );
};

export const useCrop = () => useContext(CropContext);
