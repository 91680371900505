import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/lab/LoadingButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Layout } from '../../components/Layout';
import { ErrorMsg } from '../../components/ErrorMsg';
import { TextField } from '../../components/TextField';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useCrop } from '../../contexts/contexts';
import { handleErrorMessage, serializePayload } from '../../utils';
import { CropPayload, CropFormType, Crop } from '../../types';
import { Form } from './styled';
import Grid from '@mui/material/Grid';

import { initForm } from './utils';
import { Loading } from '../../components/Loading';

export const CropForm = ({ isCreation = false }: { isCreation?: boolean }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { id } = useParams();
  const { crop, crops, setCrops, cropError, cropLoading, getCrop, cropApi } =
    useCrop();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string[] | string>();
  const [form, setForm] = useState<CropFormType>(initForm({}) as CropFormType);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    const auxForm = { ...form };
    const field = form[e.target.name as keyof CropFormType];
    auxForm[e.target.name as keyof CropFormType] = {
      ...field,
      value: field.mask({ e }),
    };
    setForm(auxForm);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const payload = serializePayload<CropFormType, CropPayload>(form);
      if (payload?.CulturaId ?? false) {
        const data = await cropApi.editCrop(payload);
        if ((crops?.length ?? -1) > 0) {
          const auxCrops = [
            ...(crops ?? []).map((crop: Crop) => {
              if ((crop?.culturaId ?? false) === data?.culturaId) {
                return data;
              }
              return crop;
            }),
          ];
          setCrops(auxCrops);
        }
      } else {
        const data = await cropApi.addCrop(payload);
        if ((crops?.length ?? -1) > 0) setCrops((crops ?? []).concat([data]));
      }
      navigate('/crops');
    } catch (e: unknown) {
      setError(handleErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  const initFormData = async () => {
    if (id ?? false) {
      const initialData = await getCrop(id ?? '');
      const auxForm = initForm({ data: initialData }) as CropFormType;
      setForm(auxForm);
    }
  };

  useEffect(() => {
    initFormData();
  }, []);

  const extraAttrs = {
    disabled: loading,
    onChange: handleChange,
  };

  return (
    <Layout>
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        wrap="wrap"
      >
        {cropLoading ? (
          <Loading />
        ) : (
          <>
            <Breadcrumb
              items={[{ href: '/crops/', name: 'Culturas' }]}
              activeItem={crop?.nome ?? ''}
              previousRoute="/crops/"
            />
            <Typography variant="h5" component="h2" color="textSecondary">
              {isCreation ? 'Cadastro de Cultura' : 'Edição de Cultura'}
            </Typography>
            {cropError ? (
              <ErrorMsg error={cropError} />
            ) : (
              <Form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  flexDirection: 'column',
                }}
              >
                <ErrorMsg error={error} />
                <TextField field={form.Nome} extraAttrs={extraAttrs} />
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={loading}
                    disabled={error ? true : undefined}
                    endIcon={
                      <ArrowForwardIcon
                        sx={{ color: theme.palette.common.white }}
                      />
                    }
                  >
                    <Typography variant="button" color="white">
                      {isCreation ? 'Cadastrar' : 'Salvar'}
                    </Typography>
                  </Button>
                </Box>
              </Form>
            )}
          </>
        )}
      </Grid>
    </Layout>
  );
};
