import React, { createContext, useState, useContext } from 'react';
import { SIDEBAR_WIDTH } from '../utils';

export type UiContextType = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarWidth: number;
  closeSidebar(): void;
};

export const UiContext = createContext<UiContextType>(
  {} as unknown as UiContextType
);

export const UiProvider = ({ children }: { children?: React.ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const sidebarWidth = sidebarOpen ? SIDEBAR_WIDTH : 0;

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <UiContext.Provider
      value={
        {
          sidebarOpen,
          setSidebarOpen,
          sidebarWidth,
          closeSidebar,
        } as UiContextType
      }
    >
      {children}
    </UiContext.Provider>
  );
};

export const useUi = () => useContext(UiContext);
