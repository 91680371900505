import { Customer, CustomerPayload, PaginatedResponse } from '../types';
import { BaseClient } from './BaseService';

export type UserByCpfResponse = {
  bairro?: null | string;
  cep?: null | string;
  cidade?: null | string;
  clienteId?: string | null;
  complemento?: null | string;
  comprovanteCNH?: null | string;
  comprovanteKML?: null | string;
  comprovanteResidencia?: null | string;
  cpf?: string | null;
  email?: string | null;
  endereco?: null | string;
  nome?: string | null;
  numeroEndereco?: null | string;
  telefone?: string | null;
  uf?: null | string;
};

export interface CustomerApi {
  getCustomerById(id: string): Promise<Customer>;
  getCustomers(): Promise<Array<Customer>>;
  getPagedCustomers(page: number): Promise<PaginatedResponse<Customer>>;
  deleteCustomer(id: string): Promise<void>;
  editCustomer(payload: CustomerPayload): Promise<Customer>;
  addCustomer(payload: CustomerPayload): Promise<Customer>;
  getUserByCpf: (cpf: string) => Promise<UserByCpfResponse>;
}

class CustomerService extends BaseClient implements CustomerApi {
  async getCustomerById(id: string): Promise<Customer> {
    const { data } = await this.api.get(`/${id}/`);
    return data as Customer;
  }

  async getCustomers(): Promise<Array<Customer>> {
    const { data } = await this.api.get(`/`);
    return data as Array<Customer>;
  }

  async getPagedCustomers(page: number): Promise<PaginatedResponse<Customer>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<Customer>;
  }

  async deleteCustomer(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editCustomer(payload: CustomerPayload): Promise<Customer> {
    const { data } = await this.api.put(`/`, payload);
    return data.cliente as Customer;
  }

  async addCustomer(payload: CustomerPayload): Promise<Customer> {
    const { data } = await this.api.post(`/`, payload);
    return data.cliente as Customer;
  }

  async getUserByCpf(cpf: string): Promise<UserByCpfResponse> {
    const { data } = await this.api.get(`/cpf/${cpf}`);
    return data as UserByCpfResponse;
  }
}

export default CustomerService;
