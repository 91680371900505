import { initField } from '../../utils';
import dayjs from 'dayjs';
import {
  ProductHistory,
  ProductHistoryFormType,
  SelectFieldType,
  TextFieldType,
  SelectOption,
  DateFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: ProductHistory }) => {
  const form: ProductHistoryFormType = {
    Valor: initField<TextFieldType>({
      name: 'Valor',
      value: typeof data?.valor !== 'undefined' ? data.valor : '',
      required: true,
    }),
    Data: initField<DateFieldType>({
      name: 'Data',
      value: data?.ano ? dayjs(data.ano) : null,
      required: true,
    }),
    EstadoId: initField<SelectFieldType>({
      name: 'EstadoId',
      value: data?.estadoId
        ? ({ value: data.estadoId, label: '' } as SelectOption)
        : null,
      label: 'Estado',
      required: false,
      extractValue: true,
    }),
    CidadeId: initField<SelectFieldType>({
      name: 'CidadeId',
      value: data?.cidadeId
        ? ({ value: data.cidadeId, label: '' } as SelectOption)
        : null,
      label: 'Cidade',
      required: false,
      extractValue: true,
    }),
    ProdutoId: initField<SelectFieldType>({
      name: 'ProdutoId',
      value: data?.produtoId
        ? ({ value: data.produtoId, label: '' } as SelectOption)
        : null,
      label: 'Produto',
      required: true,
      extractValue: true,
    }),
  };

  if (data?.historicoProdutoId ?? false) {
    form.historicoProdutoId = initField<TextFieldType>({
      name: 'historicoProdutoId',
      value: data?.historicoProdutoId as string,
    });
  }

  return form;
};
