import {
  FullProductPayload,
  PaginatedResponse,
  Product,
  ProductPayload,
  ProposalHistory,
  SubProduct,
  SubProductPayload,
} from '../types';
import { BaseClient } from './BaseService';

export interface ProductApi {
  getProducts(): Promise<Array<Product>>;
  getPagedProducts(page: number): Promise<PaginatedResponse<Product>>;
  getProductsWithoutDisabled(): Promise<Array<Product>>;
  getProductById(id: string): Promise<Product>;
  addProduct(payload: ProductPayload): Promise<Product>;
  editProduct(payload: ProductPayload): Promise<Product>;
  deleteProduct(id: string): Promise<void>;
  addFullProduct(payload: FullProductPayload): Promise<Product>;
  getSubProductById(id: string): Promise<SubProduct>;
  addSubProduct(payload: SubProductPayload): Promise<SubProduct>;
  deleteSubProduct(id: string): Promise<void>;
  getProductHistory(
    productId: string,
    stateId: string,
    cityId: string
  ): Promise<ProposalHistory>;
}

class ProductService extends BaseClient implements ProductApi {
  constructor({
    basePath,
    contentType,
    token,
  }: {
    basePath?: string;
    contentType?: string;
    token: string;
  }) {
    super({ basePath, contentType, token });
  }

  async getProductById(id: string): Promise<Product> {
    const { data } = await this.api.get(`/${id}/`);
    return data.produto as Product;
  }

  async getProducts(): Promise<Array<Product>> {
    const { data } = await this.api.get(`/`);
    return data.produtos as Array<Product>;
  }

  async getProductsWithoutDisabled(): Promise<Array<Product>> {
    const { data } = await this.api.get(`/no-disabled`);
    return data.produtos as Array<Product>;
  }

  async getPagedProducts(page: number): Promise<PaginatedResponse<Product>> {
    const { data } = await this.api.get(`/paged?pageNumber=${page}`);
    return data as PaginatedResponse<Product>;
  }

  async deleteProduct(id: string): Promise<void> {
    await this.api.delete(`/${id}/`);
  }

  async editProduct(payload: ProductPayload): Promise<Product> {
    const { data } = await this.api.put(`/`, payload);
    return data.produto as Product;
  }

  async addProduct(payload: ProductPayload): Promise<Product> {
    const { data } = await this.api.post(`/`, payload);
    return data.produto as Product;
  }

  async addFullProduct(payload: FullProductPayload): Promise<Product> {
    const { data } = await this.api.post(`/full/`, payload);
    return data.produto as Product;
  }

  async getSubProductById(id: string): Promise<SubProduct> {
    const { data } = await this.api.get(`/subproduto/${id}/`);
    return data as SubProduct;
  }

  async addSubProduct(payload: SubProductPayload): Promise<SubProduct> {
    const { data } = await this.api.post(`/subproduto/`, payload);
    return data.subProduto as SubProduct;
  }

  async editSubProduct(payload: SubProductPayload): Promise<SubProduct> {
    const { data } = await this.api.put(`/subproduto/`, payload);
    return data.subProduto as SubProduct;
  }

  async deleteSubProduct(id: string): Promise<void> {
    await this.api.delete(`/subproduto/${id}/`);
  }

  async getProductHistory(
    productId: string,
    stateId: string,
    cityId: string
  ): Promise<ProposalHistory> {
    const { data } = await this.api.get(
      `/historico/${productId}/${stateId}/${cityId}/`
    );
    return data as ProposalHistory;
  }
}

export default ProductService;
