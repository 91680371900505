import React, { createContext, useState, useEffect, useContext } from 'react';

import AuthService, { AuthApi } from '../services/AuthService';
import { AUTH_SESSION_DATA_KEY, handleErrorMessage } from '../utils';
import { useNavigate } from 'react-router-dom';

import {
  LoginPayload,
  LoginResponse,
  User,
  Role,
  Module,
  ForgotPasswordPayload,
  ChangePasswordPayload,
} from '../types';

export type AuthContextType = {
  authApi: AuthApi;
  authedApi: AuthApi;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  tokenExpiration: string;
  setTokenExpiration: React.Dispatch<React.SetStateAction<string | undefined>>;
  logout: () => void;
  login: (payload: LoginPayload) => void;
  resetPassword: (payload: ForgotPasswordPayload) => void;
  changePassword: {
    handleChangePassword: (payload: ChangePasswordPayload) => void;
    changePasswordError: string;
    setChangePasswordError: React.Dispatch<
      React.SetStateAction<string | undefined>
    >;
    changePasswordLoading: boolean;
    changePasswordSuccess: string;
    setChangePasswordSuccess: React.Dispatch<
      React.SetStateAction<string | undefined>
    >;
  };
  isAuthenticated: boolean | undefined;
  getAllowedModules(role: Role): Promise<string[]>;
};

export const AuthContext = createContext<AuthContextType>(
  {} as unknown as AuthContextType
);

export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string>();
  const [tokenExpiration, setTokenExpiration] = useState<string>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();

  const authApi = new AuthService({
    basePath: '/v1/account',
  });

  const authedApi = new AuthService({
    basePath: '/v1/account',
    token,
  });

  useEffect(() => {
    const auxData = localStorage.getItem(AUTH_SESSION_DATA_KEY);
    if (typeof auxData === 'string') {
      const data = JSON.parse(auxData) as LoginResponse;
      if (new Date(data.validTo) > new Date()) {
        setUser({ ...data.usuario });
        setToken(data.token);
        setTokenExpiration(data.validTo);
        setIsAuthenticated(true);
      } else {
        logout();
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  useEffect(() => {
    const auxData = localStorage.getItem(AUTH_SESSION_DATA_KEY);
    if (typeof auxData === 'string') {
      const data = JSON.parse(auxData) as LoginResponse;
      if (new Date(data.validTo) < new Date()) {
        logout();
      }
    }
  });

  const login = async (payload: LoginPayload) => {
    const data = await authApi.login(payload);
    const allowedModules = await getAllowedModules(data.usuario.role);
    data.usuario.allowedModules = allowedModules;
    localStorage.setItem(AUTH_SESSION_DATA_KEY, JSON.stringify(data));
    setUser({ ...data.usuario });
    setToken(data.token);
    setTokenExpiration(data.validTo);
    setIsAuthenticated(true);
    if (data.usuario.role === 'produtor') {
      navigate('/proposals/');
    } else {
      navigate('/');
    }
  };

  const resetPassword = async (payload: ForgotPasswordPayload) => {
    await authApi.resetPassword(payload);
  };

  const logout = () => {
    localStorage.removeItem(AUTH_SESSION_DATA_KEY);
    setUser(undefined);
    setToken(undefined);
    setTokenExpiration(undefined);
    setIsAuthenticated(false);
    navigate('/login');
  };

  const [changePasswordError, setChangePasswordError] = useState<string>();
  const [changePasswordLoading, setChangePasswordLoading] =
    useState<boolean>(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState<string>();

  const handleChangePassword = async (payload: ChangePasswordPayload) => {
    try {
      setChangePasswordError(undefined);
      setChangePasswordSuccess(undefined);
      setChangePasswordLoading(true);
      await authedApi.changePassword({
        ...payload,
        usuarioId: user?.usuarioId ?? '',
      });
      setChangePasswordSuccess('Senha alterada com Sucesso!');
    } catch (e) {
      setChangePasswordError(handleErrorMessage(e));
    } finally {
      setChangePasswordLoading(false);
    }
  };

  const getAllowedModules = async (role: Role) => {
    try {
      const data = await authedApi.getAllowedRoles(role);
      return data;
    } catch {
      console.error('Error fetching roles');
    }
    return [] as Module[];
  };

  return (
    <AuthContext.Provider
      value={
        {
          authApi,
          authedApi,
          user,
          setUser,
          token,
          setToken,
          tokenExpiration,
          setTokenExpiration,
          logout,
          login,
          resetPassword,
          changePassword: {
            handleChangePassword,
            changePasswordLoading,
            changePasswordError,
            setChangePasswordError,
            changePasswordSuccess,
            setChangePasswordSuccess,
          },
          isAuthenticated,
          getAllowedModules,
        } as AuthContextType
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
