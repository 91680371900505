import { initField } from '../../utils';
import dayjs from 'dayjs';
import {
  Climate,
  ClimateFormType,
  SelectFieldType,
  TextFieldType,
  SelectOption,
  DateFieldType,
} from '../../types';

export const initForm = ({ data }: { data?: Climate }) => {
  const form: ClimateFormType = {
    Valor: initField<TextFieldType>({
      name: 'Valor',
      value: typeof data?.valor !== 'undefined' ? data.valor : '',
      required: true,
    }),
    Data: initField<DateFieldType>({
      name: 'Data',
      value: data?.data ? dayjs(data.data) : null,
      required: true,
    }),
    EstadoId: initField<SelectFieldType>({
      name: 'EstadoId',
      value: null,
      label: 'Estado',
      required: false,
      extractValue: true,
    }),
    CidadeId: initField<SelectFieldType>({
      name: 'CidadeId',
      value: null,
      label: 'Cidade',
      required: false,
      extractValue: true,
    }),
    ProdutoId: initField<SelectFieldType>({
      name: 'ProdutoId',
      value: data?.produtoId
        ? ({ value: data.produtoId, label: '' } as SelectOption)
        : null,
      label: 'Produto',
      required: true,
      extractValue: true,
    }),
  };

  if (data?.dadoClimaticoId ?? false) {
    form.dadoClimaticoId = initField<TextFieldType>({
      name: 'dadoClimaticoId',
      value: data?.dadoClimaticoId as string,
    });
  }

  return form;
};
